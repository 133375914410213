import React from "react";

const LoadingButtonSpinner = ({
  className = " h-6 w-6 border-t-2 border-white",
}) => {
  return (
    <div className="flex justify-center items-center w-full ">
      <div className={`${className} animate-spin rounded-full`}></div>
    </div>
  );
};

export default LoadingButtonSpinner;
