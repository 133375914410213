import {
  STORE_ADDRESS_CREATE,
  STORE_ADDRESS_UPDATE,
  STORE_AGREEMENT_CREATE,
  STORE_DRUG_CREATE,
  STORE_FIRM_CREATE,
  STORE_PAYMENT_CREATE,
  STORE_PERSONAL_CREATE,
  STORE_TABLE,
  STORE_VIEW,
} from "../types";

export const StoreAddressCreateAction = (data, onSuccess, onError) => {
  return {
    type: STORE_ADDRESS_CREATE,
    data,
    onSuccess,
    onError,
  };
};

export const StoreAPersonalCreateAction = (data, onSuccess, onError) => {
  return {
    type: STORE_PERSONAL_CREATE,
    data,
    onSuccess,
    onError,
  };
};

export const StoreFirmCreateAction = (data, onSuccess, onError) => {
  return {
    type: STORE_FIRM_CREATE,
    data,
    onSuccess,
    onError,
  };
};

export const StoreAgreementCreateAction = (data, onSuccess, onError) => {
  return {
    type: STORE_AGREEMENT_CREATE,
    data,
    onSuccess,
    onError,
  };
};

export const StorePaymentCreateAction = (data, onSuccess, onError) => {
  return {
    type: STORE_PAYMENT_CREATE,
    data,
    onSuccess,
    onError,
  };
};

export const StoreDrugCreateAction = (data, onSuccess, onError) => {
  return {
    type: STORE_DRUG_CREATE,
    data,
    onSuccess,
    onError,
  };
};

export const StoreAddressUpdateAction = (data, onSuccess, onError) => {
  return {
    type: STORE_ADDRESS_UPDATE,
    data,
    onSuccess,
    onError,
  };
};

export const storeTableAction = (data, onSuccess, onError) => {
  return {
    type: STORE_TABLE,
    data,
    onSuccess,
    onError,
  };
};
export const storeViewAction = (data, onSuccess, onError) => {
  return {
    type: STORE_VIEW,
    data,
    onSuccess,
    onError,
  };
};
