import React, { useEffect, useState } from "react";
import { EditButton } from "../buttons/button";
import "./layout.css";
import PagRightIcon, {
  PagLeftIcon,
} from "../../assets/icons/arrow-pagination-icons";
import { useNavigate } from "react-router-dom";
import { list } from "@material-tailwind/react";
import { formatString } from "../helper-components/helper-function";

const InnerLayout = (props) => {
  const navigate = useNavigate();
  const defaultPermissionFn = () => {};
  const [currentDate, setCurrentDate] = useState(
    new Date().toLocaleString("en-US", {
      weekday: "short",
      month: "short",
      day: "2-digit",
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    })
  );
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDate(
        new Date().toLocaleString("en-US", {
          weekday: "short",
          month: "short",
          day: "2-digit",
          hour12: true,
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        })
      );
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const [heightOfFirstDiv, setHeightOfFirstDiv] = useState(0);
  useEffect(() => {
    const updateHeight = () => {
      const firstDivHeight = document.querySelector(".main-div")?.clientHeight;
      setHeightOfFirstDiv(firstDivHeight);
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  return (
    <div className="flex flex-col h-full w-full overflow-y-hidden">
      <div
        // style={{
        //   position: "fixed",
        //   top: "0",
        //   zIndex: "40",
        //   height: "3.7rem",
        // }}
        className="inner_layout_header main-div py-4 px-5 bg-white flex justify-between w-[-webkit-fill-available]"
      >
        <div className="flex items-center gap-2">
          {props?.backBtn && (
            <button
              onClick={() => {
                navigate(-1);
              }}
            >
              <PagLeftIcon width="25" height="25" fill="var(--heading-color)" />
            </button>
          )}
          <span className="text-000000 fs_18 fw_600">{props?.title}</span>
        </div>
        <div className="flex gap-3 fs_14 items-center">
          <span className="text-FFBA08">{props?.title1}</span>
          <div
            style={{
              width: "12rem",
            }}
            className="text-787878 fs_14 whitespace-nowrap"
          >
            {currentDate}
          </div>
          {/* {
            // (props?.permissionFn || defaultPermissionFn)(props?.module)
            //   ?.create &&
            props.list && <AddButton onclkFn={props?.onclkFn} text="New" />} */}
          {/* <PowerIcon
            onClick={() => {
              localStorage.clear("accessToken");
              navigate("/login");
              successToast("Logged Out Successfully");
            }}
            className="w-6"
          /> */}
          {props.view &&
            (props?.permissionFn || defaultPermissionFn)(props?.module)
              ?.update && (
              <EditButton onclkFn={props?.onclkEditFn} text="Edit" />
            )}
          Logged in User : {formatString(localStorage.getItem("user_name"))}
        </div>
      </div>
      <div
        style={{
          // maxHeight: `calc(100% - ${heightOfFirstDiv}px)`,
          height: `calc(100% - ${heightOfFirstDiv}px)`,
        }}
        className={`innerLayoutScroller ${
          props?.list ? "" : "overflow-y-auto"
        }`}
      >
        {props?.children}
      </div>
    </div>
  );
};

export default InnerLayout;
