import React from "react";

const ModalCloseIcon = ({ className, onClick }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        fill="none"
        viewBox="0 0 24 24"
        stroke="F51D00"
        width="24" // Adjust size as needed
        height="24" // Adjust size as needed
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
        />
    </svg>
);

export default ModalCloseIcon;
