import React from "react";
import EditPencilIcon from "../../assets/icons/edit-pencil-icon";
import ApproveIcon, {
  DeclineIcon,
} from "../../assets/icons/approve-decline-icon";
import LoadingButtonSpinner from "../helper-components/loading-button-spinner";

const AddButton = ({
  onclkFn,
  text = "",
  type = "button",
  prefixIcon = true,
  isDisabled = false,
  className = "bg-primary text-white px-8 py-2 rounded-md flex justify-center fw_500 fs_16 whitespace-nowrap hover:bg-blue hover:text-white transition duration-300 ease-in-out",
  loading = false,
}) => {
  return (
    <button
      autoFocus={false}
      disabled={isDisabled}
      type={type}
      className={`${className}  `}
      onClick={onclkFn}
      id={`${text.toLowerCase().split(" ").join("-")}-button`}
    >
      {loading ? (
        <LoadingButtonSpinner />
      ) : (
        <>
          {prefixIcon && "+ "} {text && text}
        </>
      )}
    </button>
  );
};

export default AddButton;

export const clearButton = ({
  onclkFn,
  text = "Add Invoice",
  className = "text-primary  fw_500 fs_16 underline",
}) => {
  return (
    <button className={className} onClick={onclkFn}>
      {" "}
      {text}
    </button>
  );
};
export const DraftButton = ({
  onclkFn,
  text = "Draft",
  type = "button",
  className = "text-primary border border-primary  border-solid px-10 py-2 items-center rounded-md flex justify-center fw_500 fs_14",
}) => {
  return (
    <button type={type} className={className} onClick={onclkFn} id="draft">
      {text}
    </button>
  );
};

export const DeleteButton = ({
  onclkFn,
  text = "Delete",
  type = "button",
  prefixIcon = true,
  isDisabled = false,
}) => {
  return (
    <button
      disabled={isDisabled}
      type={type}
      className="bg-F94B4B text-white px-10 py-2 flex rounded-md justify-center fw_500 fs_16"
      onClick={onclkFn}
      id="delete-button"
    >
      {prefixIcon && "+"} {text && text}
    </button>
  );
};

export const SignInButton = ({
  onclkFn,
  text = "Sign in",
  type = "submit",
  loading = false,
}) => {
  return (
    <button
      type={type}
      className="bg-secondary text-white border_0A111A px-7 py-2 rounded flex justify-center fw_500 fs_14"
      onClick={onclkFn}
    >
      {loading ? <LoadingButtonSpinner /> : text}
    </button>
  );
};

export const NavigateButton = ({
  onclkFn,
  text = "Forgot Password",
  type = "button",
}) => {
  return (
    <button type={type} className="text-344054 fw_700" onClick={onclkFn}>
      {text}
    </button>
  );
};

export const EditButton = ({
  onclkFn,
  text = "Edit",
  type = "button",
  isDisabled = false,
}) => {
  return (
    <button
      disabled={isDisabled}
      type={type}
      className="bg-transparent text-primary px-2 py-2 rounded-md flex justify-center fw_500 fs_16 gap-2 items-center"
      onClick={onclkFn}
    >
      {text && text}
      <EditPencilIcon />
    </button>
  );
};

export const ApproveButton = ({
  onclkFn,
  text = "Draft",
  type = "button",
  className = "text-027A48 border-51af49 border px-8 py-2 rounded flex items-center gap-1 justify-center fw_600 fs_14",
}) => {
  return (
    <button type={type} className={className} onClick={onclkFn}>
      <ApproveIcon />

      {text}
    </button>
  );
};
export const DeclineButton = ({
  onclkFn,
  text = "Draft",
  type = "button",
  className = "text-F51D00 border-f51d00 border px-8 py-2 rounded flex items-center gap-1  justify-center fw_600 fs_14",
}) => {
  return (
    <button type={type} className={className} onClick={onclkFn}>
      <DeclineIcon />
      {text}
    </button>
  );
};
