import React from "react";

const SidebarArrow = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.414 13L9.414 18L8 19.414L0.586001 12L8 4.586L9.414 6L4.414 11L17 11L17 13L4.414 13Z"
        fill="var(--primary)"
      />
      <path d="M21 1L23 1L23 23L21 23L21 1Z" fill="var(--primary)" />
    </svg>
  );
};

export default SidebarArrow;
