import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  catListAction,
  itemGroupListAction,
  itemTypeListAction,
} from "../../redux/actions/general-action";
import errorToast from "./error-toast";

// Custom Hook should start with "use"
const useItemHelper = () => {
  const dispatch = useDispatch();
  const [dropdownOptions, setDropdownOptions] = useState({
    itemType: [],
    itemGroup: [],
    catList: [],
  });

  // onSuccess handler for itemType
  const onSuccessItemType = (data) => {
    setDropdownOptions((prevOptions) => ({
      ...prevOptions,
      itemType:
        data?.data?.map((item) => ({
          label: item?.name,
          value: item?.id,
        })) || [],
    }));
  };

  // onSuccess handler for itemGroup
  const onSuccessItemGroup = (data) => {
    setDropdownOptions((prevOptions) => ({
      ...prevOptions,
      itemGroup:
        data?.data?.map((item) => ({
          label: item?.name,
          value: item?.id,
        })) || [],
    }));
  };

  // onSuccess handler for catList
  const onSuccessCatList = (data) => {
    setDropdownOptions((prevOptions) => ({
      ...prevOptions,
      catList:
        data?.data?.map((item) => ({
          label: item?.name,
          value: item?.id,
        })) || [],
    }));
  };

  // onError handler for all actions
  const onError = (error) => {
    errorToast(error?.message);
  };

  // Fetch all dropdown options
  const fetchDropdownOptions = () => {
    dispatch(itemTypeListAction(onSuccessItemType, onError));
    dispatch(itemGroupListAction(onSuccessItemGroup, onError));
    dispatch(catListAction(onSuccessCatList, onError));
  };

  useEffect(() => {
    fetchDropdownOptions();
  }, [dispatch]);

  return { dropdownOptions };
};

export default useItemHelper;
