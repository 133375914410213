import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import AddButton, { DraftButton } from "../../buttons/button";
import CloseIcon from "../../../assets/icons/close-icon";
import InputField from "../../forms/input-field";
import { useDispatch } from "react-redux";
import {
  disItemsFetchAction,
} from "../../../redux/actions/discount-action";


const DisItemModal = ({
  isModalOpen = false,
  setIsModalOpen,
  mastervalues,
  itemData,
  setFieldValue,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const initialValues = {
    item_from: "",
    item_to: "",
    discount_percentage: "",
  };

  const validationSchema = Yup.object().shape({
    item_from: Yup.string().required("Item from is required"),
    item_to: Yup.string().required("Item to is required"),
    discount_percentage: Yup.string().required("Item to is required"),
  });

  const innerOnSubmit = (values) => {
    setLoading(true);
    dispatch(
      disItemsFetchAction(
        {
          discount_type_id: mastervalues?.discount_type_id,
          customer_group_id: mastervalues?.customer_group_id,
          item_from: values?.item_from,
          item_to: values?.item_to,
        },
        (data) => {
          const newData = data?.data?.map((item) => ({
            item_code: item?.item_code,
            item_name: item?.item_name,
            item_id: item?.item_id,
            discount_percentage: values?.discount_percentage,
          }));
          setFieldValue("ItemsData", [...itemData?.ItemsData, ...newData]);
          setLoading(false);
          setIsModalOpen(false);
        },
        onError
      )
    );
  };

  const onError = (data) => {};
  return (
    <Transition show={isModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={() => setIsModalOpen(false)}
      >
        <div className="flex items-center justify-center min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
          </Transition.Child>

          {/* This is your modal container */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4"
            enterTo="opacity-100 translate-y-0"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-4"
          >
            <div className="mx-auto w-full  max-w-2xl my-8  text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={innerOnSubmit}
              >
                {({ handleSubmit, values }) => {
                  return (
                    <>
                      <div className="w-full">
                        <div className="flex justify-between px-6  py-5">
                          <h2 className="fw_500 ">Add Items</h2>
                          <button
                            className="close-button bg-transparent border-none p-0 hover:bg-787878"
                            onClick={() => setIsModalOpen(false)}
                          >
                            <CloseIcon />
                          </button>
                        </div>

                        <div className="flex flex-wrap mt-5">
                          <div className="w-[60%]  2xl:w-[60%] lg:w-[60%] px-5 xl:px-5">
                            <div className="flex mb-5">
                              <span
                                style={{
                                  width: "7rem",
                                }}
                              ></span>
                              <span>From</span>
                            </div>
                            <InputField
                              type="text"
                              name="vendor_from"
                              placeholder="xxx"
                              label="Vender Code"
                              isRequired={false}
                              labelWidth="7rem"
                            />
                            <InputField
                              type="text"
                              name="item_from"
                              placeholder="xxx"
                              label="Item No."
                              isRequired={true}
                              labelWidth="7rem"
                            />
                          </div>
                          <div className="w-[40%] 2xl:w-[40%] lg:w-[40%] px-5 xl:px-5 ">
                            <div className="mb-5">
                              <span>To</span>
                            </div>
                            <InputField
                              type="number"
                              name="vendor_to"
                              placeholder="xxx"
                              label=""
                              isRequired={false}
                            />
                            <InputField
                              type="number"
                              name="item_to"
                              placeholder="xxx"
                              label=""
                              isRequired={true}
                            />
                          </div>
                        </div>
                        <div className="w-[60%]  2xl:w-[60%] lg:w-[60%] px-5 xl:px-5">
                          <InputField
                            type="text"
                            name="discount_percentage"
                            placeholder="xxx"
                            label="Discount"
                            isRequired={true}
                            labelWidth="7rem"
                          />
                        </div>
                        <div className="px-6 flex justify-between gap-4 py-5">
                          <DraftButton
                            onclkFn={() => setIsModalOpen(false)}
                            text="Cancel"
                          />
                          <AddButton
                            loading={loading}
                            onclkFn={handleSubmit}
                            type="submit"
                            className="bg-primary text-white px-8 py-2 rounded flex justify-center fw_500 fs_16"
                            text="Done"
                            prefixIcon={false}
                          />
                        </div>
                      </div>
                    </>
                  );
                }}
              </Formik>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default DisItemModal;

