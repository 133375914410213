import { Field, useFormikContext } from "formik";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import DeleteIcon from "../../../assets/icons/delete-icon";
import SearchAddFeild from "../../forms/search-add-field";
import { purchaseProductListAction } from "../../../redux/actions/purchase-action";
import { customerListAction } from "../../../redux/actions/general-action";
import CloseIcon from "../../../assets/icons/close-icon";
import SelectField from "../../forms/select-field";

const BulkBillingTable = ({
  disabled = false,
  initialData = {},
  filterData = {},
}) => {
  const tableHeading = [
    { label: "S No.", value: "sl_no" },
    { label: "Product Name", value: "item_product_name" },
    { label: "Item Code", value: "item_code" },
    { label: "Pack Size", value: "item_code" },
    { label: "Min Qty.", value: "item_uom" },
    { label: "Max Qty.", value: "item_uom" },
    { label: "Quantity", value: "item_unit_price" },
    { label: "Vendor Code", value: "item_to_be_received" },
    { label: "Vendor Name", value: "item_quantity" },
    { label: "Unit Price", value: "item_total" },
    { label: "Total (₹)", value: "item_total" },
    { label: "", value: "" },
  ];
  const { setFieldValue, values } = useFormikContext();
  const inputRefs = useRef(null);
  const dispatch = useDispatch();
  const [localData, setLocalData] = useState([]);
  const removeRow = (rowIndex) => {
    const updatedData = [...values.data];
    updatedData.splice(rowIndex, 1);
    setFieldValue("data", updatedData);
  };
  const fetchData = async (inputValue, callback, field) => {
    const onSuccess = (data) => {
      const options =
        data?.data === null
          ? []
          : data.data
              .map((item) => ({
                value: item.item_id,
                label: item[field],
              }))
              .filter(
                (option) =>
                  !values?.data?.some((item) => item?.item_id === option.value)
              );
      setLocalData(data.data);
      callback(options);
    };
    const onError = (data) => {};
    if (inputValue?.length > 0) {
      dispatch(
        purchaseProductListAction(
          {
            field: field,
            value: inputValue,
          },
          onSuccess,
          onError
        )
      );
    }
  };
  const fetchVendorData = async (inputValue, callback, field) => {
    const onSuccess = (data) => {
      const options =
        data?.data === null
          ? []
          : data.data.map((item) => ({
              value: item.cmr_id,
              label: item[field],
              cmr_first_name: item.cmr_first_name,
              cmr_code: item?.cmr_code,
            }));

      callback(options);
    };
    const onError = (data) => {};
    if (inputValue?.length > 0) {
      dispatch(
        customerListAction(
          {
            field: field,
            value: inputValue,
            isVendor: true,
          },
          onSuccess,
          onError
        )
      );
    }
  };

  const handleQuantityChange = async (event, rowIndex) => {
    const quantity = parseInt(event.target.value);
    const unitPrice = values.data[rowIndex].item_unit_price;
    const total = quantity * unitPrice;
    setFieldValue(`data[${rowIndex}].item_quantity`, quantity);
    setFieldValue(`data[${rowIndex}].item_total_amount`, total);
  };
  const handlePriceChange = async (event, rowIndex) => {
    const unitPrice = parseFloat(event.target.value);
    const quantity = values.data[rowIndex].item_quantity;
    const total = quantity * unitPrice;
    setFieldValue(`data[${rowIndex}].item_total_amount`, total);
  };
  console.log(BulkOrderfilterFn(values?.data, filterData), "sfdsfdsfdsfds");
  return (
    <table className="my-table m-0">
      <thead className="sticky top-0 z-10">
        <tr className="bg-F2F4F5">
          {tableHeading.map((heading, index) => (
            <th
              key={index}
              className={`${
                index === tableHeading?.length - 1
                  ? "last-column w-[2.5rem]"
                  : "table-header"
              } whitespace-nowrap fs_14 fw_500 text-475467  text-start
${index === 0 ? "w-[2rem]" : ""}
${index === 1 ? "min-w-[15rem]" : ""}
${index === 2 ? "w-[4rem]" : ""}
${index === 3 ? "min-w-[8.5rem]" : ""}
${index === 4 || index === 5 || index === 6 ? "w-[3rem]" : ""}
${index === 7 ? "w-[4rem]" : ""}
${index === 8 ? "w-[15rem]" : ""}
${index === 9 ? "w-[6rem]" : ""}
${index === 10 ? "w-[6rem]" : ""}
`}
            >
              {heading?.label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {BulkOrderfilterFn(values?.data, filterData)?.map(
          (rowData, rowIndex) => (
            <tr key={rowData?.formikIndex}>
              {Object?.values(rowData)?.map((cellData, colIndex) => {
                if (colIndex < 11) {
                  return (
                    <td
                      key={colIndex}
                      className={`table-cell fs_14 fw_400 text-secondary`}
                    >
                      {colIndex === 0 ? (
                        <div>
                          <input
                            type="radio"
                            name="SerialNumberRadioGroup"
                            disabled={disabled}
                            value={rowData?.formikIndex}
                            className="peer hidden"
                            id={rowData?.formikIndex}
                          />
                          <label
                            htmlFor={rowData?.formikIndex}
                            className="block cursor-pointer select-none rounded-xl p-3 text-center hover:bg-slate-100 peer-checked:bg-blue-100 peer-checked:font-bold peer-checked:outline-none peer-checked:ring-2 peer-checked:ring-blue-500"
                          >
                            {rowData?.formikIndex + 1}
                          </label>
                        </div>
                      ) : colIndex === 1 || colIndex === 2 ? (
                        <div>
                          <SearchAddFeild
                            //   searchRef={colIndex === 1 ? searchRef : {}}
                            isDisabled={
                              values?.data?.length - 1 !==
                                rowData?.formikIndex || disabled
                                ? true
                                : false
                            }
                            errorClass="hidden"
                            isRequired={false}
                            value={{
                              label:
                                values?.data[rowData?.formikIndex][
                                  Object.keys(rowData)[colIndex]
                                ],
                              value: "",
                            }}
                            name={`data[${rowData?.formikIndex}].${
                              Object.keys(rowData)[colIndex]
                            }`}
                            field={Object.keys(rowData)[colIndex]}
                            isLabelReq={false}
                            defaultSelectClass=""
                            placeholder=""
                            fetchData={fetchData}
                            onChange={async (selectedOption) => {
                              const filteredData = localData
                                ?.filter(
                                  (item) =>
                                    item?.item_id === selectedOption?.value
                                )
                                .map((item) => ({
                                  sl_no: 1,
                                  item_name: item?.item_name,
                                  item_code: item?.item_code,
                                  item_uom: item?.item_pur_purchasing_uom_name,
                                  item_minimun_purchasing: 0,
                                  item_maximum_purchasing: 0,
                                  item_quantity: 0,
                                  cmr_code: "",
                                  cmr_first_name: "",
                                  item_unit_price: item?.item_unit_price,
                                  item_total_amount: 0,
                                  item_id: item?.item_id,
                                  item_generic_name: item?.item_generic_name,
                                  item_manufacturer: item?.item_manufacturer,
                                  item_group: item?.item_group,
                                  item_type: item?.item_type,
                                  item_categeory: item?.item_categeory,
                                  item_uom_id: item?.item_pur_purchasing_uom_name_id,
                                  uom_dropdown: item?.uom_dropdown
                                    ? item?.uom_dropdown.map((uom) => ({
                                      item_uom_id: uom?.item_id,
                                      label: uom?.alt_uom,
                                        value: uom?.alt_uom,
                                      }))
                                    : [],
                                }));
                              const oldData = [...values?.data];
                              oldData.pop();

                              setFieldValue("data", [
                                ...oldData,
                                ...filteredData,
                                initialData,
                              ]);
                              inputRefs.current?.focus();
                            }}
                          />
                        </div>
                      ) : colIndex === 7 || colIndex === 8 ? (
                        <div className="relative">
                          <SearchAddFeild
                            //   searchRef={colIndex === 1 ? searchRef : {}}
                            isDisabled={
                              values?.data?.length - 1 ===
                                rowData?.formikIndex || disabled
                                ? true
                                : false
                            }
                            errorClass="hidden"
                            isRequired={false}
                            value={{
                              label:
                                values?.data[rowData?.formikIndex][
                                  Object.keys(rowData)[colIndex]
                                ],
                              value: "",
                            }}
                            // onFocus={}
                            name={`data[${rowData?.formikIndex}].${
                              Object.keys(rowData)[colIndex]
                            }`}
                            field={Object.keys(rowData)[colIndex]}
                            isLabelReq={false}
                            defaultSelectClass=""
                            placeholder=""
                            fetchData={fetchVendorData}
                            onChange={async (selectedOption) => {
                              console.log(selectedOption, "dfsfsdfdsfsdf");
                              setFieldValue(
                                `data[${rowData?.formikIndex}].cmr_first_name`,
                                selectedOption?.cmr_first_name
                              );
                              setFieldValue(
                                `data[${rowData?.formikIndex}].cmr_id`,
                                selectedOption?.value
                              );
                              setFieldValue(
                                `data[${rowData?.formikIndex}].cmr_code`,
                                selectedOption?.cmr_code
                              );
                            }}
                          />
                          {values?.data?.length - 1 !== rowData?.formikIndex &&
                            values?.data[rowData?.formikIndex][
                              Object.keys(rowData)[colIndex]
                            ] && (
                              <div
                                onClick={() => {
                                  setFieldValue(
                                    `data[${rowData?.formikIndex}].cmr_first_name`,
                                    ""
                                  );
                                  setFieldValue(
                                    `data[${rowData?.formikIndex}].cmr_id`,
                                    ""
                                  );
                                  setFieldValue(
                                    `data[${rowData?.formikIndex}].cmr_code`,
                                    ""
                                  );
                                }}
                                className="absolute cursor-pointer z-15 right-0 top-1/2 transform -translate-y-1/2"
                              >
                                <CloseIcon width={10} height={10} />
                              </div>
                            )}
                        </div>
                      ) : colIndex === 6 ? (
                        <div>
                          <Field
                            name={`data[${rowData?.formikIndex}].item_quantity`}
                          >
                            {({ field }) => (
                              <input
                                min={0}
                                ref={inputRefs}
                                {...field}
                                type="number"
                                step="1"
                                placeholder=""
                                readOnly={
                                  values?.data?.length - 1 ===
                                    rowData?.formikIndex ||
                                  // hasDuplicateItemId(rowData.item_id, rowData?.formikIndex) ||
                                  disabled
                                }
                                onKeyDown={(event) => {
                                  if (
                                    event.key === "-" ||
                                    event.key === "e" ||
                                    event.key === "."
                                  ) {
                                    event.preventDefault();
                                  }
                                  if (
                                    event.key === "Tab" ||
                                    event.key === "Enter"
                                  ) {
                                    handleQuantityChange(
                                      event,
                                      rowData?.formikIndex
                                    );
                                    setFieldValue(
                                      `data[${rowData?.formikIndex}].item_quantity`,
                                      parseInt(event.target.value)
                                    );
                                  }
                                }}
                                onBlur={(event) => {
                                  handleQuantityChange(
                                    event,
                                    rowData?.formikIndex
                                  );
                                  // if (searchRef && searchRef.current) {
                                  //   setTimeout(() => {
                                  //     searchRef.current.focus();
                                  //   }, 0);
                                  // }
                                }}
                                className="focus-within:ring-2 text-right min-w-[5rem]   px-2 w-full py-3 rounded focus-within:outline-none focus-within:shadow-[0px_0px_0px_4px_#EBF2FF input-field-no-spin-buttons"
                              />
                            )}
                          </Field>
                        </div>
                      ) : colIndex === 9 ? (
                        <div>
                          <Field
                            name={`data[${rowData?.formikIndex}].item_unit_price`}
                          >
                            {({ field }) => (
                              <input
                                // ref={inputRefs}
                                {...field}
                                type="number"
                                step="1"
                                placeholder=""
                                readOnly={
                                  values?.data?.length - 1 ===
                                    rowData?.formikIndex ||
                                  // hasDuplicateItemId(rowData.item_id, rowData?.formikIndex) ||
                                  disabled
                                }
                                onKeyDown={(event) => {
                                  if (
                                    event.key === "Tab" ||
                                    event.key === "Enter"
                                  ) {
                                    handlePriceChange(
                                      event,
                                      rowData?.formikIndex
                                    );
                                    setFieldValue(
                                      `data[${rowData?.formikIndex}].item_unit_price`,
                                      parseFloat(event.target.value)
                                    );
                                  }
                                }}
                                onBlur={(event) => {
                                  handlePriceChange(
                                    event,
                                    rowData?.formikIndex
                                  );
                                  // if (searchRef && searchRef.current) {
                                  //   setTimeout(() => {
                                  //     searchRef.current.focus();
                                  //   }, 0);
                                  // }
                                }}
                                className="focus-within:ring-2 text-right min-w-[5rem]   px-2 w-full py-3 rounded focus-within:outline-none focus-within:shadow-[0px_0px_0px_4px_#EBF2FF input-field-no-spin-buttons"
                              />
                            )}
                          </Field>
                        </div>
                      ) : colIndex === 3 &&
                        values?.data?.length - 1 !== rowIndex ? (
                        <>
                          <SelectField
                                   handleSelected={(sel) => {
                                    setFieldValue(
                                      `data[${rowIndex}].item_uom_id`,
                                      sel?.item_uom_id
                                    );
                                  }}
                            borderclass=""
                            defaultSelectClass="border-0"
                            selectClass="w-full"
                            defaultValue={
                              values.data[rowIndex]?.item_uom
                                ? {
                                    label:
                                      values.data[rowIndex]?.item_uom ?? "",
                                  }
                                : null
                            }
                            isDisabled={disabled}
                            errMsgContainerClass="hidden"
                            id={`data[${rowIndex}].item_uom`}
                            isContainer={false}
                            options={values.data[rowIndex]?.uom_dropdown}
                            name={`data[${rowIndex}].item_uom`}
                            placeholder={"Select UOM"}
                          />
                        </>
                      ) : (
                        cellData
                      )}
                    </td>
                  );
                }
                return null;
              })}
              {!disabled && (
                <td className="last-column w-[2.5rem]">
                  {rowData?.formikIndex !== values?.data?.length - 1 && (
                    <DeleteIcon
                      onClickFn={() => removeRow(rowData?.formikIndex)}
                    />
                  )}
                </td>
              )}
            </tr>
          )
        )}
      </tbody>
    </table>
  );
};

export default BulkBillingTable;

function groupBy(items, key) {
  return items.reduce((result, item) => {
    (result[item[key]] = result[item[key]] || []).push(item);
    return result;
  }, {});
}

export function BulkOrderfilterFn(items, filterData) {
  let filteredItems = items
    ?.map((item, index) => ({ ...item, formikIndex: index }))
    ?.filter(
      (item) =>
        (filterData?.vendor ? item?.cmr_id === filterData?.vendor : true) &&
        (filterData?.manufacturer
          ? item?.item_manufacturer === filterData?.manufacturer
          : true) &&
        (filterData?.item_group
          ? item?.item_group === filterData?.item_group
          : true) &&
        (filterData?.item_type
          ? item?.item_type === filterData?.item_type
          : true) &&
        (filterData?.item_category
          ? item?.item_category === filterData?.item_category
          : true)
    );

  if (filterData.group_by) {
    const groupedItems = groupBy(filteredItems, filterData.group_by);
    filteredItems = Object.values(groupedItems).flat();
  }

  return filteredItems;
}
