import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import AddButton from "../../buttons/button";
import InputField from "../../forms/input-field";
import { useDispatch } from "react-redux";
import { StoreAgreementCreateAction } from "../../../redux/actions/store-action";
import successToast from "../../helper-components/success-toast";
import errorToast from "../../helper-components/error-toast";
import PDFViewer from "../../helper-components/pdf-viewer";

const StoreAgreementTab = ({
  id,
  setStoreData,
  storeData,
  initialValue,
  disabled = false,
}) => {
  const dispatch = useDispatch();
  const initialValues = {
    store_id: id ?? null,
    agreement_witness: initialValue?.agreement_witness ?? "",
    store_agreement_remarks: initialValue?.store_agreement_remarks ?? "",
    store_agreement_is_approve:
      initialValue?.store_agreement_is_approve ?? false,
  };

  const validationSchema = Yup.object().shape({});
  const onSubmit = (values, actions) => {
    dispatch(StoreAgreementCreateAction(values, onSuccess, onError));
  };

  const onSuccess = (data) => {
    setStoreData({
      ...storeData,
      agreement: data?.data,
    });
    successToast(data?.data?.message);
  };
  const onError = (data) => {
    errorToast(data?.data?.message);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, isSubmitting }) => {
        return (
          <Form style={{ height: "calc(100% - 5rem)" }} className="pt-5">
            <div className="flex flex-col justify-between h-full ">
              <div>
                <div className="w-full 2xl:w-[35%] lg:w-[50%] px-5 xl:px-10">
                  <InputField
                    isDisabled={disabled}
                    type="text"
                    name="agreement_witness"
                    placeholder=""
                    label="Agreement Witness"
                    labelWidth="10.5rem"
                  />

                  <InputField
                    isDisabled={disabled}
                    type="text"
                    name="store_agreement_remarks"
                    placeholder=""
                    label="Remarks"
                    labelWidth="10.5rem"
                  />
                </div>
                <PDFViewer />
              </div>

              {!disabled && (
                <div
                  style={{
                    bottom: "0",
                  }}
                  className="flex justify-end px-10 mb-5x sticky bg-white py-4 invoice-bottom-container"
                >
                  <AddButton prefixIcon={false} text="Save" type="submit" />
                </div>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default StoreAgreementTab;


