import React from "react";

const AltItemIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 668 518"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M467.333 450.667H544C581.337 450.667 600.007 450.667 614.267 443.4C626.81 437.01 637.01 426.81 643.4 414.267C650.667 400.007 650.667 381.337 650.667 344V124C650.667 86.6629 650.667 67.9946 643.4 53.7339C637.01 41.1896 626.81 30.9909 614.267 24.5996C600.007 17.3332 581.337 17.3333 544 17.3333H124C86.6632 17.3333 67.9946 17.3332 53.7339 24.5996C41.1896 30.9909 30.9909 41.1896 24.5996 53.7339C17.3332 67.9946 17.3333 86.6629 17.3333 124V344C17.3333 381.337 17.3332 400.007 24.5996 414.267C30.9909 426.81 41.1896 437.01 53.7339 443.4C67.9946 450.667 86.6629 450.667 124 450.667H200.667"
        stroke="white"
        strokeWidth="33.3333"
        strokeLinecap="round"
      />
      <path
        d="M150.667 150.667L304.71 304.71C323.463 323.463 334 348.9 334 375.42V434M150.667 150.667H217.333M150.667 150.667V217.333"
        stroke="white"
        strokeWidth="33.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M517.333 150.667L363.29 304.71C344.537 323.463 334 348.9 334 375.42V500.667M517.333 150.667H450.667M517.333 150.667V217.333"
        stroke="white"
        strokeWidth="33.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AltItemIcon;
