import React from "react";

const PlusIcon = ({ onClickFn = () => {}, fill="white" }) => {
  return (
  
      <svg
      onClick={onClickFn}
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.49992 1.33325V10.6666M0.833252 5.99992H10.1666"
        stroke={fill}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PlusIcon;
