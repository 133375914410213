import { Field } from "formik";
import React, { Fragment, useEffect, useRef, useState } from "react";
import EyeCloseIcon from "../../assets/icons/eye-close-icon";
import EyeOpenIcon from "../../assets/icons/eye-open-icon";
import StarIcon from "../../assets/icons/req-star";
import ErrorMsg from "./error-msg";
import "./form.css";
export default function InputField({
  isDisabled = false,
  name = "",
  containerClass = "mb-2 position-relative",
  labelClass = "whitespace-nowrap flex fs_14 fw_400 text-secondary",
  placeholder = "Enter your email address",
  label = "Email Address",
  id,
  type = "text",
  errMsgContainerClass = "",
  isContainer = true,
  defaultInputClass = "inputField focus_outline__none",
  unitContainerClass = "flex unit_input__container",
  unitContainerErrorClass = "flex unit_input_container__error",
  isUnit = false,
  unitType = localStorage?.getItem("currency") ?? "₹",
  unitPosition = "left",
  isPasswordEye = false,
  passwordEyeClass = "position-absolute  end-0 translate-middle  cursor_pointer",
  passwordEyeStyle = { top: "44%" },
  autoComplete = "",
  unitClassName = "",
  children,
  isRequired = false,
  labelWidth = "10rem",
  prefixicon = "",
  handleChange = () => {},
  autoFocus = false,
  maxLength = 1000,
}) {
  const finalInputClass = defaultInputClass; // replaceInputCSS true means inputClass(prop) will completely replace default class else it will attach with default
  const Container = isContainer ? "div" : Fragment; // isContainer true means a container div will be there
  const UnitContainer = isUnit ? "div" : Fragment; // isContainer true means a container div will be there
  const PasswordEyeContainer = isPasswordEye ? "div" : Fragment;
  const inputRef = useRef(null);
  const [passInputType, setPassInputType] = useState("password");
  useEffect(() => {
    if (autoFocus) {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, []);

  return (
    <Container
      {...(isContainer && containerClass ? { className: containerClass } : {})}
    >
      {" "}
      <Field className="no-arrows" name={name}>
        {({ field, meta, form }) => (
          <div className="flex items-center m-0 p-0 w-full">
            {label && (
              <label
                style={{
                  minWidth: labelWidth,
                }}
                {...(id ? { htmlFor: id } : {})}
                className={`${labelClass} flex  flex-nowrap`}
              >
                <span className="flex items-center gap-2">
                  {prefixicon}
                  {label}
                </span>
                {isRequired && (
                  <span className="ml-2">
                    <StarIcon />
                  </span>
                )}
              </label>
            )}
            <UnitContainer
              {...(unitContainerClass && isUnit
                ? {
                    className: `  ${
                      meta.touched && meta.error
                        ? unitContainerErrorClass
                        : unitContainerClass
                    } ${
                      unitPosition === "right" ? "flex-row-reverse" : ""
                    } w-full`,
                  }
                : {})}
            >
              {isUnit && (
                <div
                  className={`d-flex justify-content-center align-items-center font_poppins mt-1 ${
                    unitPosition === "right" ? "flex-row-reverse" : ""
                  }`}
                >
                  <span
                    className={` fw_500 fs_14 align-middle primary_color ${unitClassName} ${
                      unitPosition === "right" ? "ms-1" : "me-1"
                    }`}
                  >
                    {unitType}
                  </span>{" "}
                  {unitPosition !== "image" && (
                    <span className={`input-vr mx-2`}></span>
                  )}
                </div>
              )}
              <PasswordEyeContainer
                {...(isPasswordEye
                  ? {
                      className: `position-relative `,
                    }
                  : {})}
              >
                <input
                  onWheel={(e) => e.target.blur()}
                  ref={inputRef}
                  {...(isDisabled ? { disabled: true } : {})}
                  {...(autoComplete ? { autoComplete: autoComplete } : {})}
                  type={type === "password" ? passInputType : type}
                  {...field}
                  placeholder={placeholder}
                  {...(id ? { id: id } : {})}
                  className={
                    isUnit
                      ? `inputField border-0 focus_outline__none w-100`
                      : `${
                          meta.touched && meta.error
                            ? `${finalInputClass} inputField_border__error`
                            : `${finalInputClass} inputField_border`
                        } input-field-no-spin-buttons w-full ${
                          autoFocus
                            ? "focus:border-primary border"
                            : "no-arrows"
                        }`
                  }
                  onChange={(e) => {
                    const value = e.target.value;

                    // Check if the input is a number and has a maximum length of 10
                    if (value.length <= maxLength) {
                      field.onChange(e); // Update Formik's field state
                      handleChange(e); // Call the custom handleChange prop
                    }
                  }}
                  onKeyDown={(event) => {
                    // Allow letters, numbers, space, and control keys (e.g., backspace)
                    const validKey = /^[a-zA-Z0-9\s]+$/;
                    if (
                      !validKey.test(event.key) &&
                      event.key !== "Backspace"
                    ) {
                      event.preventDefault();
                    }
                  }}
                />
                {isPasswordEye &&
                  (passInputType === "password" ? (
                    <button
                      style={passwordEyeStyle}
                      type="button"
                      className={`${passwordEyeClass} `}
                    >
                      <EyeCloseIcon onClick={() => setPassInputType("text")} />
                    </button>
                  ) : (
                    <button
                      type="button"
                      style={passwordEyeStyle}
                      className={`${passwordEyeClass} border-0`}
                    >
                      {" "}
                      <EyeOpenIcon
                        onClick={() => setPassInputType("password")}
                      />
                    </button>
                  ))}
              </PasswordEyeContainer>
            </UnitContainer>
          </div>
        )}
      </Field>
      <ErrorMsg containerClass={errMsgContainerClass} name={name} />
      {children}
    </Container>
  );
}
