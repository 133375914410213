import { Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { productRemarksCreateAction } from "../../redux/actions/product-action";
import AddButton from "../buttons/button";
import TextAreaField from "../forms/textarea-field";
import successToast from "../helper-components/success-toast";
import { useNavigate } from "react-router-dom";
import { url } from "../../redux/config";
import { api } from "../../redux/apis/api";

const RemarksTab = ({
  productId,
  setFormValues,
  formValues,
  setCurrentTab,
  product,
  disabled = false,
  itemGeneralData = {},
  itemText = "",
  itemIndex,
  suggestedProducts = [],
  uomGroupData = [],
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialValues = {
    item_id: product?.item_id ?? productId,
    item_remarks: product?.item_remarks ?? "",
  };

  const validationSchema = Yup.object().shape({});
  const innerOnSubmit = (values) => {
    setFormValues({
      ...formValues,
      remarks: values,
    });
    dispatch(
      productRemarksCreateAction(
        {
          itemGeneralData: itemGeneralData,
          itemRemarksData: values,
        },
        onSuccess,
        onError
      )
    );
  };

  const handleQuantityChange = async (
    event,
    itemIndex,
    suggestedProductData
  ) => {
    const totalAmount =
      parseFloat(suggestedProductData?.item_unit_price) * parseFloat(event);

    if (parseFloat(event) > 0) {
      try {
        const response = await api.get(
          `${url}/discount/get?totalAmountOfItem=${totalAmount}&item_quantity=${event}&item_id=${itemGeneralData?.item_id}`,
          JSON.stringify(totalAmount)
        );

        console.log(response, "dfgdtgfdg");

        const tax =
          response.data?.code === 200 ? response.data.data.item_tax_amount : 0;
        const updatedDataRow = {
          item_gst:
            response.data?.code === 200
              ? response.data.data.item_total_tax_percentage
              : 0,
          item_sgst:
            response.data?.code === 200
              ? (
                  parseInt(event) *
                  parseFloat(suggestedProductData?.item_unit_price) *
                  (response.data.data.sgstRate / 100)
                )?.toFixed(2)
              : 0,
          item_cgst:
            response.data?.code === 200
              ? (
                  parseInt(event) *
                  parseFloat(suggestedProductData?.item_unit_price) *
                  (response.data.data.cgstRate / 100)
                )?.toFixed(2)
              : 0,
          item_igst:
            response.data?.code === 200
              ? (
                  parseInt(event) *
                  parseFloat(suggestedProductData?.item_unit_price) *
                  (response.data.data.igstRate / 100)
                )?.toFixed(2)
              : 0,
          item_total_amount:
            parseFloat(suggestedProductData?.item_unit_price) *
            parseFloat(parseInt(event)),
          item_tax_amount: parseFloat(tax),
          item_price_wiithout_tax: (
            parseFloat(suggestedProductData?.item_unit_price) *
              parseFloat(parseInt(event)) -
              // parseFloat(discount) -
              parseFloat(tax) || 0
          ).toFixed(2),

        };


        const updatedItem = {
          ...suggestedProducts[itemIndex],
          item_id: itemGeneralData?.item_id,
          item_code: itemGeneralData?.item_code,
          item_name_suggested: itemGeneralData?.item_name,
          item_sheet_uom: suggestedProductData?.item_sheet_uom,
          item_sheet_uom_id: suggestedProductData?.item_sheet_uom_id,
          item_tax_amount: updatedDataRow?.item_tax_amount,
          item_total_amount: updatedDataRow?.item_total_amount,
          item_sgst: updatedDataRow?.item_sgst,
          item_cgst: updatedDataRow?.item_cgst,
          uom_dropdown: uomGroupData?.find(
            (grp) => grp?.group_id === itemGeneralData?.item_uom
          )?.group_items,
          moreSuggestedItemsArray: [
            ...(suggestedProducts[itemIndex]?.moreSuggestedItemsArray?.length > 0
              ? suggestedProducts[itemIndex]?.moreSuggestedItemsArray
              : []),
            {
              item_id: itemGeneralData?.item_id,
              item_name: itemGeneralData?.item_name,
              item_code: itemGeneralData?.item_code,
              item_cgst: updatedDataRow?.item_cgst,
              item_quantity: suggestedProductData?.item_quantity,
              item_sgst: updatedDataRow?.item_sgst,
              item_sheet_uom: suggestedProductData?.item_sheet_uom,
              item_sheet_uom_id: suggestedProductData?.item_sheet_uom_id,
              item_tax_amount: updatedDataRow?.item_tax_amount,
              item_total_amount: updatedDataRow?.item_total_amount,
              item_unit_price: suggestedProductData?.item_unit_price,
              uom_dropdown:
                uomGroupData?.length > 0
                  ? uomGroupData?.find(
                      (grp) => grp?.group_id === itemGeneralData?.item_uom
                    )?.group_items
                  : [],
            },
          ],
        };
        const updatedProducts = [...suggestedProducts];
        updatedProducts[itemIndex] = updatedItem;
        navigate("/purchase-order-csv", {
          state: { suggestedProducts: updatedProducts },
        });

      } catch (error) {}
    }
  };

  const onSuccess = (data) => {
    const suggestedProductData = suggestedProducts[itemIndex];
    if (itemText) {
      handleQuantityChange(
        suggestedProductData?.item_quantity,
        itemIndex,
        suggestedProductData
      );

      // const updatedItem = {
      //   ...suggestedProducts[itemIndex],
      //   item_id: itemGeneralData?.item_id,
      //   item_code: itemGeneralData?.item_code,
      //   item_name_suggested: itemGeneralData?.item_name,
      //   item_sheet_uom_id: "",
      //   item_sheet_uom: "",
      //   uom_dropdown: uomGroupData?.find(
      //     (grp) => grp?.group_id === itemGeneralData?.item_uom
      //   )?.group_items,
      //   moreSuggestedItemsArray: [
      //     ...(suggestedProducts[itemIndex]?.moreSuggestedItemsArray?.length > 0
      //       ? suggestedProducts[itemIndex]?.moreSuggestedItemsArray
      //       : []),
      //     {
      //       item_id: itemGeneralData?.item_id,
      //       item_name: itemGeneralData?.item_name,
      //       item_code: itemGeneralData?.item_code,
      //       item_cgst: suggestedProductData?.item_cgst,
      //       item_quantity: suggestedProductData?.item_quantity,
      //       item_sgst: suggestedProductData?.item_sgst,
      //       item_sheet_uom: suggestedProductData?.item_sheet_uom,
      //       item_sheet_uom_id: suggestedProductData?.item_sheet_uom_id,
      //       item_tax_amount: suggestedProductData?.item_tax_amount,
      //       item_total_amount: suggestedProductData?.item_total_amount,
      //       item_unit_price: suggestedProductData?.item_unit_price,
      //       uom_dropdown:
      //         uomGroupData?.length > 0
      //           ? uomGroupData?.find(
      //               (grp) => grp?.group_id === itemGeneralData?.item_uom
      //             )?.group_items
      //           : [],
      //     },
      //   ],
      // };
      // const updatedProducts = [...suggestedProducts];
      // updatedProducts[itemIndex] = updatedItem;
      // navigate("/purchase-order-csv", {
      //   state: { suggestedProducts: updatedProducts },
      // });
    } else {
      navigate("/product");
      successToast(data?.message);
      setCurrentTab("Remarks");
    }
  };
  const onError = (data) => {};

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={formValues?.remarks ?? initialValues}
        validationSchema={validationSchema}
        onSubmit={innerOnSubmit}
      >
        {({ handleSubmit }) => {
          return (
            <>
              <div cla="w-full lg:w-[50%] xl:w-[45%] px-5 xl:px-10 pt-0 mt-5">
                <TextAreaField
                  isDisabled={disabled}
                  label="Remarks"
                  placeholder="your remarks here..."
                  name="item_remarks"
                  id={"item_remarks"}
                  isRequired={false}
                  labelWidth="7rem"
                  feildHeight="9rem"
                />
              </div>

              {!disabled && (
                <div
                  style={{
                    bottom: "0",
                  }}
                  className="flex justify-end px-10 mb-5x sticky bg-white py-4 invoice-bottom-container"
                >
                  <AddButton
                    onclkFn={handleSubmit}
                    prefixIcon={false}
                    text="Save"
                    type="submit"
                  />
                </div>
              )}
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default RemarksTab;
