import { all } from "redux-saga/effects";
import {
  storeAddressCreateWatcher,
  storeAddressUpdateWatcher,
  storeAgreementCreateWatcher,
  storeDrugCreateWatcher,
  storeFirmCreateWatcher,
  storePaymentCreateWatcher,
  storePersonalCreateWatcher,
  storeTableWatcher,
} from "../store-saga";

export default function* storeRootSaga() {
  yield all([
    storeAddressCreateWatcher(),
    storePersonalCreateWatcher(),
    storeFirmCreateWatcher(),
    storeAgreementCreateWatcher(),
    storePaymentCreateWatcher(),
    storeDrugCreateWatcher(),
    storeAddressUpdateWatcher(),
    storeTableWatcher(),
  ]);
}
