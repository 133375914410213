import React from "react";

const ShippingSetupIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 5V8.878C2 11.763 2 13.207 2.912 14.103C3.822 15 5.289 15 8.222 15H9.778C12.711 15 14.178 15 15.089 14.103C16 13.207 16 11.764 16 8.878V5M2 5H16M2 5L2.673 3.462C3.195 2.268 3.456 1.672 3.985 1.336C4.515 1 5.193 1 6.55 1H11.45C12.807 1 13.486 1 14.015 1.336C14.544 1.672 14.805 2.268 15.327 3.462L16 5M7.5 8H10.5M1 15H16C16.7956 15 17.5587 15.3161 18.1213 15.8787C18.6839 16.4413 19 17.2044 19 18C19 18.7956 18.6839 19.5587 18.1213 20.1213C17.5587 20.6839 16.7956 21 16 21H1M15 18H15.009M9 18H9.009M3 18H3.009"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ShippingSetupIcon;
