import React from "react";

const AuthorizationIcon = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.83341 1.41663H2.25008C1.96276 1.41663 1.68721 1.53076 1.48405 1.73393C1.28088 1.93709 1.16675 2.21264 1.16675 2.49996V16.5833C1.16675 16.8706 1.28088 17.1462 1.48405 17.3493C1.68721 17.5525 1.96276 17.6666 2.25008 17.6666H21.7501C22.0374 17.6666 22.3129 17.5525 22.5161 17.3493C22.7193 17.1462 22.8334 16.8706 22.8334 16.5833V15.2291" stroke="white" strokeWidth="2.16667" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.41675 8.45837H8.75008M4.41675 12.7917H17.4167" stroke="white" strokeWidth="2.16667" strokeLinecap="round" />
            <path d="M17.4167 7.91663C19.2117 7.91663 20.6667 6.46155 20.6667 4.66663C20.6667 2.8717 19.2117 1.41663 17.4167 1.41663C15.6218 1.41663 14.1667 2.8717 14.1667 4.66663C14.1667 6.46155 15.6218 7.91663 17.4167 7.91663Z" stroke="white" strokeWidth="2.16667" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M22.8332 11.3936C21.7754 9.32604 19.5832 7.91663 17.4166 7.91663C15.2499 7.91663 14.1704 8.53033 13.0562 9.54163" stroke="white" strokeWidth="2.16667" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default AuthorizationIcon