import { all } from "redux-saga/effects";
import invoiceRootSaga from "./invoice-root-saga";
import generalRootSaga from "./general-root-saga";
import customerRootSaga from "./customer-root-saga";
import productRootSaga from "./product-root-saga";
import storeRootSaga from "./store-root-saga";
import { storeViewWatcher } from "../store-saga";
import authRootSaga from "./auth-saga";
import userRootSaga from "./user-root-saga";
import discountRootSaga from "./discount-root-saga";
import inventoryRootSaga from "./inventory-root-saga";
import salesRootSaga from "./sales-root-saga";
import purchaseRootSaga from "./purchase-root-saga";
import financialRootSaga from "./financial-root-saga";
import settingsRootSaga from "./settings-root-saga";
import doctorRootSaga from "./doctor-root-saga";
import reportRootSaga from "./report-root-saga";
import dashboardRootSaga from "./dashboard-root-saga";

export default function* rootSaga() {
  yield all([
    invoiceRootSaga(),
    generalRootSaga(),
    customerRootSaga(),
    productRootSaga(),
    storeRootSaga(),
    storeViewWatcher(),
    authRootSaga(),
    userRootSaga(),
    discountRootSaga(),
    inventoryRootSaga(),
    salesRootSaga(),
    purchaseRootSaga(),
    financialRootSaga(),
    settingsRootSaga(),
    doctorRootSaga(),
    reportRootSaga(),
    dashboardRootSaga(),
  ]);
}
