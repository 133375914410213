import { Field, useFormikContext } from "formik";
import React from "react";
import "./sales.css";
import TextAreaField from "../forms/textarea-field";
import AddButton from "../buttons/button";

const SalesPurchaseFinalBillTable = ({
  name = "",
  disabled = false,
  page = "",
  printFn = () => {},
  EditFn = () => {},
  status = true,
}) => {
  const { values, setFieldValue } = useFormikContext();

  console.log(values, "dfgfdgdfg");
  return (
    <>
      <div className="table-container flex">
        <div
          style={{
            minWidth: `calc(100% - 20rem)`,
          }}
          className="bg-F2F4F5  flex "
        >
          <div className="w-[70%] border-r-EAECF0 border-r-2 px-4 py-2">
            <span className="text-000000 fw_600 fs_14">Remarks</span>
            <TextAreaField
              errMsgContainerClass="hidden"
              isDisabled={page === "view"}
              containerClass="mt-2"
              placeholder="Enter Remarks"
              name="remarks"
              id={"remarks"}
              isRequired={false}
              labelWidth="7rem"
              feildHeight="6.5rem"
            />
          </div>
          <div className="flex flex-col justify-between xl:px-10 w-[30%] fs_14 border-r-EAECF0 border-r-2 px-4 py-5 fw_500 text-secondary whitespace-nowrap">
            <span className="text-000000 fw_600 fs_14">GST Information</span>
            <div className="w-full flex justify-between ">
              <span>CGST (₹)</span>{" "}
              <span className="text-000000">{(calculateTotalTax(values?.data) / 2).toFixed(2)}</span>
            </div>
            <div className="w-full flex justify-between ">
              <span>SGST (₹)</span>{" "}
              <span className="text-000000">{(calculateTotalTax(values?.data) / 2).toFixed(2)}</span>
            </div>
            <div className="w-full flex justify-between ">
              <span>IGST (₹)</span>{" "}
              <span className="text-000000">{calculateTotalIGST(values?.data).toFixed(2)}</span>
            </div>
          </div>
        </div>
        <div
          className="bg-F2F4F5 fs_14 fw_500 text-secondary"
          style={{
            minWidth: "20rem",
          }}
        >
          <div className="pt-2 pb-1 px-4 flex justify-between items-center">
            <span>Sub Total (₹)</span>
            <span>
              {" "}
              <input
                disabled={true}
                className="text-end w-[8rem] px-2 py-1 border border-D0D5DD rounded-md text-000000"
                type="text"
                value={`${calculateSubtotal(values?.data).toFixed(2)}`}
              />
            </span>
          </div>
          <div className="py-1 px-4 flex justify-between items-center">
            <span>Tax (₹)</span>
            <span>
              <input
                disabled={true}
                className="text-end w-[8rem] px-2 py-1 border border-D0D5DD rounded-md text-000000"
                type="text"
                value={`${calculateTotalTax(values?.data).toFixed(2)}`}
              />
            </span>
          </div>
          <div className="py-1 px-4 flex justify-between items-center">
            <span>Discount (₹)</span>
            <span>
              <Field name={name}>
                {({ field, form }) => (
                  <input
                    disabled={disabled}
                    placeholder="₹ 0.00"
                    {...field}
                    className="text-end w-[8rem] px-2 py-1 rounded-md border focus:placeholder-transparent text-000000 focus:outline-none border-D0D5DD input-field-no-spin-buttons"
                    type="number"
                    onBlur={(e) => {
                      const inputValue = e.target.value;
                      if (
                        !inputValue.includes(".") &&
                        !isNaN(parseInt(inputValue))
                      ) {
                        form.setFieldValue(
                          field.name,
                          parseFloat(inputValue).toFixed(2)
                        );
                      }
                    }}
                  />
                )}
              </Field>
            </span>
          </div>
          <div className="py-1 px-4 flex justify-between fw_700 fs_15 items-center">
            <span>Total (₹)</span>
            <span>
              <input
                disabled={true}
                className="text-end w-[8rem] px-2 py-1 border rounded-md border-D0D5DD text-000000"
                type="text"
                value={`${(
                  values.data?.reduce(
                    (total, item) =>
                      total + parseFloat(item.item_total_amount || 0),
                    0
                  ) - parseFloat(values[name] ? values[name] : 0)
                ).toFixed(2)}`}
              />
            </span>
          </div>
        </div>       
      </div>
      <div className="flex justify-between invoice-bottom-container w-full py-4 px-4 items-center">
        <div className="flex gap-3 fw_600"></div>
        {page !== "view" && (
          <div className="flex gap-3">
            <AddButton
              prefixIcon={false}
              text="Save"
              type="submit"
              onclkFn={() => setFieldValue("submittedButton", "save")}
            />
            <AddButton
              onclkFn={() => setFieldValue("submittedButton", "print")}
              prefixIcon={false}
              text="Save and Print"
              type="submit"
            />
          </div>
        )}
        {page === "view" && (
          <div className="flex gap-3">
            {(name === "sott_total_discount" ||
              name === "pot_total_discount") &&
              status && (
                <AddButton
                  prefixIcon={false}
                  text="Edit"
                  type="button"
                  onclkFn={EditFn}
                />
              )}
            <AddButton
              prefixIcon={false}
              text="Share"
              type="button"
              onclkFn={() => {}}
            />

            <AddButton
              prefixIcon={false}
              text="Print"
              type="button"
              onclkFn={printFn}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default SalesPurchaseFinalBillTable;

export const calculateTotal = (data) => {
  let overallTotal = 0;

  data.forEach((item) => {
    const unitPrice = parseFloat(item.item_unit_price) || 0;
    const quantity = parseInt(item.quantity, 10) || 0;
    const dis = parseFloat(item.item_discount_amount) || 0;
    const total = unitPrice * quantity - dis;
    overallTotal += total;
  });

  return overallTotal;
};
export const calculateTotalTax = (data) => {
  let totalTax = 0;

  data?.forEach((rowData) => {
    totalTax += parseFloat(rowData.item_tax_amount) || 0;
  });

  return totalTax;
};

// const calculateSubtotal = (data) => {
//   return data.reduce((subtotal, item) => {
//     const unitPrice = parseFloat(item.item_unit_price || 0);
//     const quantity = parseInt(item.quantity, 10) || 0;
//     return subtotal + unitPrice * quantity;
//   }, 0);
// };

export const calculateSubtotal = (data) => {
  console.log(data, "fgdgdfgddgdfgdfgdfg");
  return data.reduce((subtotal, item) => {
    return (
      subtotal +
      (parseFloat(item.item_unit_price) * parseInt(item?.quantity) -
        parseFloat(item?.item_tax_amount))
    );
  }, 0);
};

export const calculateTotalCGST = (data) => {
  let totalCGST = 0;
  data?.forEach((rowData) => {
    totalCGST += parseFloat(rowData.item_cgst) || 0;
  });
  return totalCGST;
};

export const calculateTotalSGST = (data) => {
  let totalSGST = 0;
  data?.forEach((rowData) => {
    totalSGST += parseFloat(rowData.item_sgst) || 0;
  });
  return totalSGST;
};
export const calculateTotalIGST = (data) => {
  let totalIGST = 0;
  data?.forEach((rowData) => {
    totalIGST += parseFloat(rowData.item_igst) || 0;
  });
  return totalIGST;
};
