import React, { useEffect, useRef, useState } from "react";
import InnerLayout from "../../components/layouts/inner-layout";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useHotkeys } from "react-hotkeys-hook";
import {
  AlternativeIcon,
  CustomerIcon,
  PastOrderIcon,
  StoriesIcon,
} from "../../assets/icons/invoice-header-icons";
import ArrowDownIcon from "../../assets/icons/arrow-down";
import CheckBox from "../../components/forms/check-box-field";
import InvoiceBillingTable from "../../components/invoice-section/invoice-billing-table";
import {
  doctorListAction,
  paymentMethodListAction,
  phoneListAction,
  storeIdAction,
} from "../../redux/actions/general-action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  invoiceCreateAction,
  invoiceDraftCreateAction,
  invoiceRowAction,
  invoiceRowClearAction,
  repeatOrderAction,
  storeDetailsAction,
} from "../../redux/actions/invoices-action";
import AddButton, { DraftButton } from "../../components/buttons/button";
import successToast from "../../components/helper-components/success-toast";
import AsyncCreateSelect from "../../components/forms/async-create-select-field";
import LoadingSpinner from "../../components/helper-components/loading-spinner";
import errorToast from "../../components/helper-components/error-toast";
import AlternativesPopover from "../../components/invoice-section/alternatives-popover";
import BillingFinalTable, {
  calculateTotal,
  calculateSubtotal,
  calculateTotalPayment,
  validatePaymentmethod,
} from "../../components/invoice-section/billing-final-table";
import { handlePrint } from "../../components/invoice-section/invoice-thermal-printer";

const AddInvoice = () => {
  const [searchParams] = useSearchParams();
  const [isbarcodeScanning, setIsbarcodeScanning] = useState(false);
  const phoneNumber = searchParams.get("phoneNumber") || "";
  const id = searchParams.get("id") || "";
  const rowData = useSelector((state) => state.invoiceRowReducer);
  const searchRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [heightOfFirstDiv, setHeightOfFirstDiv] = useState(0);
  const [heightOfSecDiv, setHeightOfSecDiv] = useState(0);
  useEffect(() => {
    const updateHeight = () => {
      const firstDivHeight =
        document?.querySelector(".invoice-head")?.clientHeight;
      const secDivHeight = document?.querySelector(".second-div")?.clientHeight;
      setHeightOfFirstDiv(firstDivHeight);
      setHeightOfSecDiv(secDivHeight);
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, [loading]);

  console.log(rowData, "dfgsdgsdsgsdgdsgdsg");

  const initialData = {
    sl_no: 1,
    item_code: "",
    item_generic_name: "",
    item_name: "",
    total_sellable_quantity: "",
    quantity: "",
    item_uom: "",
    item_batch_number: "",
    item_exp_date: "",
    item_mfg_date: "",
    item_rack_location: "",
    item_schedule: "",
    item_unit_price: 0,
    item_price_wiithout_tax: 0,
    item_discount_amount: 0.0,
    item_discount_percentage: 0,
    item_tax_amount: 0,
    item_total_amount: 0,
    item_id: "",
    to_bin_id: "",
    instock: true,
    item_gst: 0,
    item_sgst: 0,
    item_cgst: 0,
  };
  const initialFormValues =
    rowData?.data?.length >= 1
      ? {
          ...rowData,
          data: [...rowData.data, initialData],
        }
      : {
          phone: phoneNumber ? { label: phoneNumber, value: id } : null,
          cmr_first_name: "",
          cmr_last_name: "",
          cmr_acquired_source: "",
          gst_tax: "",
          RepeatLastOrder: false,
          data: [initialData],
          doctor: "",
          payment_method: "Cash",
          remarks: "Thank you for choosing us 🙏🏼",
          paymentData: [
            {
              payment_mode_name: "",
              payment_amount: null,
              transaction: "",
            },
            {
              payment_mode_name: "",
              payment_amount: null,
              transaction: "",
            },
            {
              payment_mode_name: "",
              payment_amount: null,
              transaction: "",
            },
          ],
        };

  const [initialValue, setInitialValue] = useState(initialFormValues);
  const [reload, setReload] = useState(false);
  const [docList, setDocList] = useState([]);
  const [payModesList, setPayModesList] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [localData, setLocalData] = useState([]);
  const [storeId, setStoreId] = useState("");
  const [storeDetails, setStoreDetails] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [invoiceType, setInvoiceType] = useState("");
  const validationSchema = Yup.object().shape({
    phone: Yup.object()
      .required("Phone number is required")
      .test(
        "is-ten-digits",
        "Please enter valid phone number",
        (value) => value && value.label && /^\d{10}$/.test(value.label)
      ),
    remarks: Yup.string().max(250, "Remarks must be at most 250 characters"),
  });
  const invoiceDetails = [
    {
      image: <CustomerIcon />,
      title: "View Customer Details",
    },
    {
      image: <AlternativeIcon />,
      title: "Alternatives",
    },
    {
      image: <StoriesIcon />,
      title: "Other Stores",
    },
  ];

  useEffect(() => {
    dispatch(doctorListAction(onSuccessDoc, onErrorDoc));
    dispatch(storeIdAction(onSuccessStore, onErrorStore));
    dispatch(paymentMethodListAction(onSuccessPayList, onErrorPayList));
    dispatch(storeDetailsAction(onSuccessStoreDetails, onErrorStoreDetails));
    return () => {
      dispatch(invoiceRowClearAction());
    };
  }, []);

  const [alternativeID, setAlternativeID] = useState({
    itemid: "",
    index: null,
  });

  const onSuccessStore = (data) => {
    setLoading(false);
    setStoreId(data?.data?.store_id);
    setReload(!reload);
  };
  const onErrorStore = () => {
    setLoading(false);
  };
  const onSuccessStoreDetails = (data) => {
    setStoreDetails(data?.data);
  };
  const onErrorStoreDetails = () => {};

  const onSuccessDoc = (data) => {
    setDocList(
      data?.data
        ?.filter((item) => item?.doctor_name != null)
        .map((item) => {
          return { label: item?.doctor_name, value: item?.doctor_name };
        })
    );
  };
  const onErrorPayList = () => {};
  const onSuccessPayList = (data) => {
    setPayModesList(
      data?.data?.paymentModes.map((item) => {
        return { label: item?.paymentType, value: item?.paymentType };
      })
    );
  };
  const onErrorDoc = () => {};

  const loadOptions = (inputValue) => {
    return new Promise((resolve) => {
      const onSuccess = (data) => {
        const options = [
          ...data.data.map((item) => ({
            value: item.cmr_id,
            label: item?.cmr_phone_number,
          })),
          {
            value: { text: "new_customer", number: inputValue },
            label: <span className="text-primary">+ New Customer</span>,
          },
        ];
        setLocalData(data.data);
        resolve(options);
      };

      const onError = (error) => {
        resolve([
          {
            value: { text: "new_customer", number: inputValue },
            label: <span className="text-primary">+ New Customer</span>,
          },
        ]);
      };

      if (inputValue?.length > 0) {
        dispatch(
          phoneListAction(
            {
              phone: inputValue,
            },
            onSuccess,
            onError
          )
        );
      } else {
        resolve([]);
      }
    });
  };

  useEffect(() => {
    if (formValues?.RepeatLastOrder) {
      dispatch(
        repeatOrderAction(
          {
            phone: formValues?.phone?.label ? formValues?.phone?.label : "",
          },
          onSuccess,
          onError
        )
      );
    }
  }, [formValues?.RepeatLastOrder]);

  const onSuccess = (data) => {
    setInitialValue({
      ...formValues,
      RepeatLastOrder: true,
      paymentData: [],
      data: [
        ...data?.data?.map((item, index) => ({
          sl_no: index + 1,
          item_code: item.item_code,
          item_generic_name: item?.item_generic_name,
          item_name: item?.item_name,
          total_sellable_quantity: item?.total_sellable_quantity,
          quantity: 0,
          item_uom: item.item_uom,
          item_batch_number: "",
          item_exp_date: "",
          item_mfg_date: "",
          item_rack_location: "",
          item_schedule: item?.item_schedule,
          item_unit_price: item?.item_unit_price,
          item_price_wiithout_tax: 0,
          item_discount_amount: 0,
          item_discount_percentage: 0,
          item_tax_amount: 0,
          item_total_amount: 0,
          item_id: item?.item_id,
          item_gst: 0,
          item_sgst: 0,
          item_cgst: 0,
        })),
        initialData,
      ],
      doctor: "",
      payment_method: "",
    });
  };
  const onError = () => {};

  // const initPayment = (data, resetForm, amount) => {
  //
  //   const options = {
  //     key: "rzp_test_0tpemkHKm5K1Bc",
  //     currency: data.currency,
  //     amount: data.amount,
  //     name: "ZOTA POS",
  //     handler: async function (response) {
  //       response.order_id = data.id;
  //       dispatch(
  //         invoiceCreateAction(
  //           {
  //             orderData: {
  //               store_id: storeId,
  //               cmr_phone_number: parseInt(formValues?.phone?.label),
  //               sot_total_discount: formValues?.data
  //                 ?.slice(0, -1)
  //                 .reduce(
  //                   (total, item) =>
  //                     total + parseFloat(item?.item_discount_amount),
  //                   0
  //                 ),
  //               sot_total_gst: parseFloat(
  //                 formValues?.data
  //                   ?.slice(0, -1)
  //                   .reduce(
  //                     (total, item) =>
  //                       total + parseFloat(item?.item_tax_amount),
  //                     0
  //                   )
  //               ),
  //               sot_payment_status: "paid",
  //               sot_transaction_id: response?.razorpay_payment_id,
  //               sot_payment_method: formValues?.payment_method,
  //               sot_billing_address: "",
  //               sot_total_amount: parseFloat(amount),
  //               sot_sub_total: parseFloat(calculateSubtotal(formValues?.data)),
  //               doctor_name: formValues?.doctor?.value,
  //             },
  //             itemData: formValues?.data?.slice(0, -1).map((item, index) => ({
  //               item_code: item?.item_code,
  //               item_generic_name: item?.item_generic_name,
  //               item_name: item?.item_name,
  //               item_quantity: parseInt(item?.quantity),
  //               item_uom: item?.item_uom,
  //               item_batch_number: item?.item_batch_number,
  //               item_exp_date: item?.item_exp_date,
  //               item_mfg_date: item?.item_mfg_date,
  //               item_rack_location: item?.item_rack_location,
  //               item_schedule: item?.item_schedule,
  //               item_unit_price: parseFloat(item?.item_unit_price),
  //               item_discount_amount: parseFloat(item?.item_discount_amount),
  //               item_discount_percentage: parseFloat(
  //                 item?.item_discount_percentage
  //               ),
  //               item_tax_amount: parseFloat(item?.item_tax_amount),
  //               item_total_amount:
  //                 parseFloat(item.item_unit_price) * parseInt(item.quantity) -
  //                 parseFloat(item.item_discount_amount) +
  //                 parseFloat(item.item_tax_amount),
  //               item_id: item?.item_id,
  //               item_gst: parseFloat(item?.item_gst),
  //               item_sgst: parseFloat(item?.item_sgst),
  //               item_cgst: parseFloat(item?.item_cgst),
  //             })),
  //           },
  //           onSuccessOrder,
  //           onErrorOrder
  //         )
  //       );
  //       resetForm();
  //     },
  //     prefill: {
  //       name: "Rajat",
  //       email: "rajat@rajat.com",
  //       phone_number: "9899999999",
  //     },
  //   };
  //   const rzp1 = new window.Razorpay(options);
  //   rzp1.open();
  // };

  // const handlePayment = async (amount, resetForm) => {
  //   try {
  //     const orderUrl = `${url}/order/payment`;
  //     const response = await api.post(orderUrl, {
  //       amount: amount,
  //     });
  //     initPayment(response?.data?.order, resetForm, amount);
  //   } catch (error) {}
  // };

  const handleSubmit = (values, actions) => {
    dispatch(invoiceRowClearAction());
    let actionType = invoiceCreateAction;
    if (values.submittedButton === "draft") {
      actionType = invoiceDraftCreateAction;
    }
    if (
      values?.data
        ?.slice(0, -1)
        .some((item) => parseInt(item.quantity) === 0 || item.quantity === "")
    ) {
      errorToast("Quantity cannot be zero");
    } else {
      if (values?.data?.length > 1) {
        if (
          calculateTotalPayment(values) !== calculateTotal(values) &&
          values.submittedButton !== "draft"
        ) {
          errorToast("Amount is not matching");
        }
        // else if (
        //   values.submittedButton !== "draft" &&
        //   validatePaymentData(values?.paymentData)
        // ) {
        //   errorToast("Please make payment");
        // }
        else if (
          values.submittedButton !== "draft" &&
          validatePaymentmethod(values?.paymentData)
        ) {
          errorToast("Please select payment");
        } else {
          if (values.submittedButton !== "draft") {
            // setIsbarcodeScanning(true);
          }
          dispatch(
            actionType(
              {
                orderData: {
                  is_draft_order:
                    values.submittedButton === "draft" ? true : false,
                  store_id: storeId,
                  cmr_phone_number: parseInt(values?.phone?.label),
                  sot_total_gst: Math.round(
                    formValues?.data
                      ?.slice(0, -1)
                      .reduce(
                        (total, item) =>
                          total + parseFloat(item?.item_tax_amount),
                        0
                      )
                  ),
                  sot_total_discount: values?.data
                    ?.slice(0, -1)
                    .reduce(
                      (total, item) =>
                        total + parseFloat(item?.item_discount_amount),
                      0
                    ),
                  sot_payment_status:
                    values.submittedButton === "draft" ? "pending" : "paid",
                  sot_transaction_id: "",
                  // sot_payment_method: values?.payment_method ,
                  sot_payment_method: "cash",
                  sot_billing_address: "",
                  sot_remarks: values?.remarks,
                  sot_sub_total: parseFloat(calculateSubtotal(values?.data)),
                  sot_total_amount: parseFloat(calculateTotal(values)),
                  doctor_name: values?.doctor?.value,
                },
                paymentData: values?.paymentData
                  ?.filter((item) => parseFloat(item?.payment_amount) > 0)
                  ?.map((item) => ({
                    payment_mode_name: item?.payment_mode_name,
                    payment_amount: parseFloat(item?.payment_amount),
                  })),
                itemData: values?.data?.slice(0, -1).map((item, index) => ({
                  item_code: item?.item_code,
                  item_generic_name: item?.item_generic_name,
                  item_name: item?.item_name,
                  item_quantity: parseInt(item?.quantity),
                  item_uom: item?.item_uom,
                  item_batch_number: item?.item_batch_number,
                  item_exp_date: item?.item_exp_date,
                  item_rack_location: item?.item_rack_location,
                  item_schedule: item?.item_schedule,
                  item_unit_price: parseFloat(item?.item_unit_price),
                  item_price_wiithout_tax: parseFloat(
                    item?.item_price_wiithout_tax
                  ),
                  item_discount_amount: parseFloat(item?.item_discount_amount),
                  item_discount_percentage: parseFloat(
                    item?.item_discount_percentage
                  ),
                  item_tax_amount: parseFloat(item?.item_tax_amount),
                  item_total_amount: parseFloat(
                    parseFloat(item.item_unit_price) * parseInt(item.quantity) -
                      parseFloat(item.item_discount_amount)
                  ),
                  item_id: item?.item_id,
                  item_gst: parseFloat(item?.item_gst),
                  item_sgst: parseFloat(item?.item_sgst),
                  item_cgst: parseFloat(item?.item_cgst),
                  item_total_tax_percentage: parseFloat(
                    item?.item_total_tax_percentage
                  ),
                })),
              },
              onSuccessOrder,
              onErrorOrder
            )
          );
        }

        // } else {
        //   handlePayment(totalAmount, actions.resetForm);
        // }
      }
    }
  };
  // const downloadPdf = async (data) => {
  //   const fileName = "test.pdf";

  //   try {
  //     const blob = await pdf(
  //       <InvoicePdfContent data={data} storeDetails={storeDetails} />
  //     ).toBlob();
  //     saveAs(blob, fileName);
  //   } catch (error) {}
  // };

  const onSuccessOrder = (data) => {
    // downloadPdf(data?.data)
    setIsbarcodeScanning(false);

    if (formValues.submittedButton !== "draft") {
      handlePrint(data?.data, storeDetails);
    }
    setInitialValue({
      phone: "",
      cmr_first_name: "",
      cmr_last_name: "",
      cmr_acquired_source: "",
      gst_tax: "",
      RepeatLastOrder: false,
      data: [initialData],
      remarks: "Thank you for choosing us 🙏🏼",
      paymentData: [
        {
          payment_mode_name: "",
          payment_amount: null,
          // transaction: "",
        },
        {
          payment_mode_name: "",
          payment_amount: null,
          // transaction: "",
        },
        {
          payment_mode_name: "",
          payment_amount: null,
          // transaction: "",
        },
      ],
      doctor: "",
      payment_method: "",
    });
    successToast(data?.message);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 50);
  };
  const onErrorOrder = (data) => {
    setLoading(false);
    setIsbarcodeScanning(false);
    errorToast(data?.data?.message);
  };
  useHotkeys("escape", () => {
    setInvoiceType("");
  });

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <InnerLayout list={true} title="Add Billing">
          {/* <img
            src={`https://unwraphappiness.in/cdn/shop/files/Group_1171275741_280x.png?v=1720181874`}
            alt="Store Logo"
            style={{ width: "100px", height: "auto" }}
          /> */}
          <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, setFieldError, errors }) => {
              setFormValues(values);

              console.log(values, "dfgsdgsdsgsdgdsgdsg");
              return (
                <Form
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                  className="h-full"
                >
                  <div className="flex flex-col h-full ">
                    <div className="invoice-head">
                      <>
                        <div className="bg-F2F4F5 flex flex-col lg:flex-row justify-start lg:justify-between lg:items-center px-3 border-EAECF0 border py-1 whitespace-wrap">
                          <div className="flex space-x-5 h-full items-center">
                            {invoiceDetails?.map((item, index) => (
                              <button
                                type="button"
                                key={index}
                                onClick={() =>
                                  setInvoiceType(
                                    item?.title === invoiceType
                                      ? ""
                                      : item?.title
                                  )
                                }
                                id={item?.title
                                  .toLowerCase()
                                  .split(" ")
                                  .join("-")}
                                disabled={
                                  (index === 0 && !values?.cmr_first_name) ||
                                  (index === 1 && alternativeID?.itemid === "")
                                }
                                className={`flex items-center space-x-3 fs_14 text-secondary cursor-pointer h-full ${
                                  index === 0 &&
                                  !values?.cmr_first_name &&
                                  ` cursor-not-allowed`
                                }
                                  
                                  ${
                                    index === 1 &&
                                    alternativeID?.itemid === "" &&
                                    `cursor-not-allowed`
                                  }
                                  
                                  `}
                              >
                                {item?.image}{" "}
                                <span className="select-none">
                                  {item?.title}
                                </span>{" "}
                                <ArrowDownIcon
                                  invoiceType={item?.title === invoiceType}
                                />
                                {index < invoiceDetails.length - 1 && (
                                  <span className="ml-2 h-6 bg-EAECF0 w-0.5 inline-block" />
                                )}
                              </button>
                            ))}
                          </div>
                          {values?.phone?.label && (
                            <div className="flex items-center mt-3 lg:mt-0">
                              <CheckBox
                                onChange={(name, check) => {
                                  console.log(check, "dfgsdgsdsgsfgdfdgdsgdsg");

                                  if (check === false) {
                                    setInitialValue({
                                      ...initialValue,
                                      RepeatLastOrder: check,
                                      data: [initialData],
                                    });
                                  }
                                }}
                                items={items}
                              />
                              <div
                                onClick={() => {
                                  navigate(
                                    `/past-orders/${values?.phone?.label}`
                                  );
                                  dispatch(invoiceRowClearAction());
                                  setFieldValue("RepeatLastOrder", false);
                                  const updatedData = values?.data.slice(0, -1);
                                  if (!values?.RepeatLastOrder) {
                                    dispatch(
                                      invoiceRowAction({
                                        ...values,
                                        data: updatedData,
                                      })
                                    );
                                  }
                                }}
                                id="view-past-orders"
                                className="border-EAECF0 border px-3 py-1 flex items-center space-x-2 bg-white rounded-md cursor-pointer"
                              >
                                <PastOrderIcon />
                                <span className="fs_14 text-secondary whitespace-no-wrap">
                                  View past orders
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                        {invoiceType === "View Customer Details" && (
                          <div className="border-EAECF0 border flex gap-5 py-4 flex-wrap px-5">
                            {values?.cmr_first_name && (
                              <div className="fw_400 fs_14">
                                Name:{" "}
                                <span className="fw_500 fs_14">
                                  {values?.cmr_first_name +
                                    " " +
                                    values?.cmr_last_name}
                                </span>
                              </div>
                            )}
                            {values?.phone?.label && (
                              <div className="fw_400 fs_14">
                                Mobile:{" "}
                                <span className="fw_500 fs_14">
                                  {values?.phone?.label}
                                </span>
                              </div>
                            )}
                            {values?.cmr_acquired_source && (
                              <div className="fw_400 fs_14">
                                Total Rewards:{" "}
                                <span className="fw_500 fs_14">
                                  {values?.cmr_acquired_source}
                                </span>
                              </div>
                            )}
                          </div>
                        )}

                        {invoiceType === "Alternatives" && (
                          <AlternativesPopover
                            alternativeID={alternativeID}
                            setInvoiceType={setInvoiceType}
                          />
                        )}
                      </>
                      <div className="mt-5 px-3 flex justify-between md:gap-0 gap-5">
                        <div
                          style={{
                            maxWidth: "32rem",
                          }}
                          className="w-full"
                        >
                          <AsyncCreateSelect
                            labelClass="whitespace-nowrap flex fs_14 gap-2 text-secondary"
                            maxlength={10}
                            menuPortalTarget={document.body}
                            className="z-1 selectField_border"
                            marginRight="26px"
                            isValidNewOption={true}
                            autoFocus={true}
                            name={"phone"}
                            placeholder="Enter customer Phone"
                            onCreateOption={() => {}}
                            loadOptions={loadOptions}
                            onChange={(selectedOption) => {
                              if (
                                selectedOption === "new_customer" ||
                                selectedOption?.value?.text === "new_customer"
                              ) {
                                navigate(
                                  `/add-customer?phoneNumber=${selectedOption?.value?.number}`
                                );
                              } else {
                                setFieldValue("phone", selectedOption);
                                const customerData = localData?.find(
                                  (item) =>
                                    item?.cmr_id === selectedOption?.value
                                );

                                setFieldValue(
                                  "cmr_first_name",
                                  customerData?.cmr_first_name
                                );
                                setFieldValue(
                                  "cmr_last_name",
                                  customerData?.cmr_last_name
                                );
                                setFieldValue(
                                  "cmr_acquired_source",
                                  customerData?.cmr_acquired_source
                                );
                                if (
                                  searchRef &&
                                  searchRef.current &&
                                  selectedOption?.value
                                ) {
                                  searchRef.current.focus();
                                  setFieldError("phone", "");
                                }
                              }
                            }}
                          />
                        </div>
                        <button
                          type="button"
                          onClick={() => {
                            setFieldValue("data", [initialData]);
                          }}
                          className="text-primary underline fs_14 fw_400 whitespace-nowrap mb-5"
                          tabIndex="-1"
                        >
                          Clear all
                        </button>
                      </div>
                    </div>
                    {heightOfFirstDiv && (
                      <div
                        style={{
                          minHeight: `calc(100% - ${heightOfFirstDiv}px)`,
                          // height: `calc(100% - ${heightOfFirstDiv}px)`,
                        }}
                        className="flex flex-col "
                      >
                        <InvoiceBillingTable
                          isbarcodeScanning={isbarcodeScanning}
                          setIsbarcodeScanning={setIsbarcodeScanning}
                          heightOfSecDiv={heightOfSecDiv}
                          searchRef={searchRef}
                          initialData={initialData}
                          setAlternativeID={setAlternativeID}
                        />
                        <div className="mt-auto second-div">
                          <BillingFinalTable payModesList={payModesList} />
                          <div className="flex justify-between w-full invoice-bottom-container py-4 px-4 items-center">
                            {/* NEEDED */}
                            {/* <div
                              style={{
                                width: "calc((100% - 20rem)/2)",
                              }}
                              className="flex gap-3 fw_600  justify-between pe-4"
                            >
                              <div
                                style={{
                                  width: "18rem",
                                  minWidth: "18rem",
                                }}
                                className="doctorSelect"
                              >
                                <CreateSelect
                                  options={docList}
                                  name="doctor"
                                  placeholder="Select a doctor or add new"
                                />
                              </div>

                              <div
                                style={{
                                  maxWidth: "20rem",
                                }}
                                className="border border-primary fs_14 fw_500 border-solid cursor-pointer rounded-md text-primary flex whitespace-nowrap gap-3 items-center py-2 justify-center px-[45px]"
                              >
                                <UploadIcon />
                                Upload Prescription
                              </div>
                            </div> */}

                            <div></div>
                            <div></div>
                            <div className="flex gap-3 ">
                              <DraftButton
                                isDisabled={isbarcodeScanning}
                                loading={isbarcodeScanning}
                                type="submit"
                                onclkFn={() =>
                                  setFieldValue("submittedButton", "draft")
                                }
                              />
                              <AddButton
                                isDisabled={isbarcodeScanning}
                                loading={isbarcodeScanning}
                                onclkFn={() =>
                                  setFieldValue("submittedButton", "save")
                                }
                                prefixIcon={false}
                                text="Save and Print"
                                type="submit"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </Form>
              );
            }}
          </Formik>
        </InnerLayout>
      )}
    </>
  );
};
export default AddInvoice;

const items = [
  { label: "Repeat last order", name: "RepeatLastOrder", isRequired: false },
];
