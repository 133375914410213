import { call, takeLatest } from "redux-saga/effects";
import { bounceReportApi, branchWiseSalesReportApi, customerPurchaseBehaviorReportApi, customerPurchaseFreqReportApi, dailySalesReportApi, discountAnalysisReportApi, doctorWiseSalesReportApi, expiryLossReportApi, gstR1ReportApi, inventoryReportApi, inventorySalesReportApi, overallSalesReportApi, partyWiseProfitReportApi, partyWiseSalesReportApi, paymentModeWiseSalesReportApi, productWiseSalesReportApi, salesReturnReportApi, scheduledHDrugRegReportApi, slowAndFastMovingProductsReportApi } from "../apis/report-api";
import { BOUNCE_REPORT, BRANCH_WISE_SALES_REPORT, DAILY_SALES_REPORT, DISCOUNT_ANALYSIS_REPORT, DOCTOR_WISE_SALES_REPORT, EXPIRY_LOSS_REPORT, GSTR1_REPORT, INVENTORY_REPORT, INVENTORY_SALES_REPORT, OVERALL_SALES_REPORT, PARTY_WISE_PROFIT_REPORT, PARTY_WISE_SALES_REPORT, PAYMENT_MODE_WISE_SALES_REPORT, PRODUCT_WISE_SALES_REPORT, PURCHASE_BEHAVIOR_REPORT, PURCHASE_FREQ_REPORT, SALES_RETURN_REPORT, SCHEDULED_HDRUG_REG_REPORT, SLOW_FAST_MOVING_PRODUCTS } from "../types";

/**Sales Reports */
export function* overallSalesReportWorker(action) {
    try {
        const res = yield call(overallSalesReportApi, action.data);
        if (res.status === 200) {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res?.data);
        }
    } catch (error) {
        yield action.onError(error?.response);
    }
}

export function* dailySalesReportWorker(action) {
    try {
        const res = yield call(dailySalesReportApi, action.data);
        if (res.status === 200) {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res?.data);
        }
    } catch (error) {
        yield action.onError(error?.response);
    }
}

export function* branchWiseSalesReportWorker(action) {
    try {
        const res = yield call(branchWiseSalesReportApi, action.data);
        if (res.status === 200) {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res?.data);
        }
    } catch (error) {
        yield action.onError(error?.response);
    }
}

export function* productWiseSalesReportWorker(action) {
    try {
        const res = yield call(productWiseSalesReportApi, action.data);
        if (res.status === 200) {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res?.data);
        }
    } catch (error) {
        yield action.onError(error?.response);
    }
}

export function* doctorWiseSalesReportWorker(action) {
    try {
        const res = yield call(doctorWiseSalesReportApi, action.data);
        if (res.status === 200) {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res?.data);
        }
    } catch (error) {
        yield action.onError(error?.response);
    }
}

export function* partyWiseSalesReportWorker(action) {
    try {
        const res = yield call(partyWiseSalesReportApi, action.data);
        if (res.status === 200) {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res?.data);
        }
    } catch (error) {
        yield action.onError(error?.response);
    }
}

export function* paymentModeWiseSalesReportWorker(action) {
    try {
        const res = yield call(paymentModeWiseSalesReportApi, action.data);
        if (res.status === 200) {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res?.data);
        }
    } catch (error) {
        yield action.onError(error?.response);
    }
}

/**Financial & Revenue */

export function* partyWiseProfitReportWorker(action) {
    try {
        const res = yield call(partyWiseProfitReportApi, action.data);
        if (res.status === 200) {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res?.data);
        }
    } catch (error) {
        yield action.onError(error?.response);
    }
}

export function* discountAnalysisReportWorker(action) {
    try {
        const res = yield call(discountAnalysisReportApi, action.data);
        if (res.status === 200) {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res?.data);
        }
    } catch (error) {
        yield action.onError(error?.response);
    }
}


/**Sales Retrun */
export function* salesReturnReportWorker(action) {
    try {
        const res = yield call(salesReturnReportApi, action.data);
        if (res.status === 200) {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res?.data);
        }
    } catch (error) {
        yield action.onError(error?.response);
    }
}

/**Customer Reorts */
export function* customerPurchaseFreqReportWorker(action) {
    try {
        const res = yield call(customerPurchaseFreqReportApi, action.data);
        if (res.status === 200) {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res?.data);
        }
    } catch (error) {
        yield action.onError(error?.response);
    }
}

export function* customerPurchaseBehaviorReportWorker(action) {
    try {
        const res = yield call(customerPurchaseBehaviorReportApi, action.data);
        if (res.status === 200) {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res?.data);
        }
    } catch (error) {
        yield action.onError(error?.response);
    }
}

export function* bounceReportWorker(action) {
    try {
        const res = yield call(bounceReportApi, action.data);
        if (res.status === 200) {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res?.data);
        }
    } catch (error) {
        yield action.onError(error?.response);
    }
}

export function* inventorySalesReportWorker(action) {
    try {
        const res = yield call(inventorySalesReportApi, action.data);
        if (res.status === 200) {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res?.data);
        }
    } catch (error) {
        yield action.onError(error?.response);
    }
}

export function* inventoryReportWorker(action) {
    try {
        const res = yield call(inventoryReportApi, action.data);
        if (res.status === 200) {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res?.data);
        }
    } catch (error) {
        yield action.onError(error?.response);
    }
}

export function* scheduledHDrugRegReportWorker(action) {
    try {
        const res = yield call(scheduledHDrugRegReportApi, action.data);
        if (res.status === 200) {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res?.data);
        }
    } catch (error) {
        yield action.onError(error?.response);
    }
}

export function* expiryLossReportWorker(action) {
    try {
        const res = yield call(expiryLossReportApi, action.data);
        if (res.status === 200) {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res?.data);
        }
    } catch (error) {
        yield action.onError(error?.response);
    }
}

export function* slowAndFastMovingProductsReportWorker(action) {
    try {
        const res = yield call(slowAndFastMovingProductsReportApi, action.data);
        if (res.status === 200) {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res?.data);
        }
    } catch (error) {
        yield action.onError(error?.response);
    }
}
export function* gstR1ReportWorker(action) {
    try {
        const res = yield call(gstR1ReportApi, action.data);
        if (res.status === 200) {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res?.data);
        }
    } catch (error) {
        yield action.onError(error?.response);
    }
}



/**Sales Report */
export function* overallSalesReportWatcher() {
    yield takeLatest(OVERALL_SALES_REPORT, overallSalesReportWorker);
}

export function* dailySalesReportWatcher() {
    yield takeLatest(DAILY_SALES_REPORT, dailySalesReportWorker);
}


export function* branchWiseSalesReportWatcher() {
    yield takeLatest(BRANCH_WISE_SALES_REPORT, branchWiseSalesReportWorker);
}

export function* productWiseSalesReportWatcher() {
    yield takeLatest(PRODUCT_WISE_SALES_REPORT, productWiseSalesReportWorker);
}

export function* doctorWiseSalesReportWatcher() {
    yield takeLatest(DOCTOR_WISE_SALES_REPORT, doctorWiseSalesReportWorker);
}

export function* partyWiseSalesReportWatcher() {
    yield takeLatest(PARTY_WISE_SALES_REPORT, partyWiseSalesReportWorker);
}

export function* paymentModeWiseSalesReportWatcher() {
    yield takeLatest(PAYMENT_MODE_WISE_SALES_REPORT, paymentModeWiseSalesReportWorker);
}

/**Financial & Revenue */
export function* partyWiseProfitReportWatcher() {
    yield takeLatest(PARTY_WISE_PROFIT_REPORT, partyWiseProfitReportWorker);
}

export function* discountAnalysisReportWatcher() {
    yield takeLatest(DISCOUNT_ANALYSIS_REPORT, discountAnalysisReportWorker);
}

export function* salesReturnReportWatcher() {
    yield takeLatest(SALES_RETURN_REPORT, salesReturnReportWorker);
}

export function* customerPurchaseFreqReportWatcher() {
    yield takeLatest(PURCHASE_FREQ_REPORT, customerPurchaseFreqReportWorker);
}

export function* customerPurchaseBehaviorReportWatcher() {
    yield takeLatest(PURCHASE_BEHAVIOR_REPORT, customerPurchaseBehaviorReportWorker);
}

export function* bounceReportWatcher() {
    yield takeLatest(BOUNCE_REPORT, bounceReportWorker);
}

export function* inventorySalesReportWatcher() {
    yield takeLatest(INVENTORY_SALES_REPORT, inventorySalesReportWorker);
}

export function* inventoryReportWatcher() {
    yield takeLatest(INVENTORY_REPORT, inventoryReportWorker);
}

export function* scheduledHDrugRegReportWatcher() {
    yield takeLatest(SCHEDULED_HDRUG_REG_REPORT, scheduledHDrugRegReportWorker);
}

export function* expiryLossReportWatcher() {
    yield takeLatest(EXPIRY_LOSS_REPORT, expiryLossReportWorker);
}

export function* slowAndFastMovingProductsReportWatcher() {
    yield takeLatest(SLOW_FAST_MOVING_PRODUCTS, slowAndFastMovingProductsReportWorker);
}
export function* gstR1ReportWatcher() {
    yield takeLatest(GSTR1_REPORT, gstR1ReportWorker);
}