import React, { useState } from "react";
import InnerLayout from "../layouts/inner-layout";
import FilterIcon from "../../assets/icons/filter-icon";
import Select from "react-select";
import { PlusIcon } from "@heroicons/react/24/outline";
import AddButton from "../buttons/button";
import exportFromJSON from "export-from-json";
import ColoumnsModal from "../modals/reports/coloumn-modal";
import ColoumnsButton from "../../assets/icons/coloumnButton";
import ClearIcon from "../../assets/icons/clear-icon";

const ReportLayout = ({
  children,
  title = "",
  setOpenMoreFilter,
  filters = {},
  setFilters,
  openMoreFilter,
  handleApply = () => {},
  groupByOptions,
  tableData,
  initFilter,
  selectedColumns,
  availableColumns,
  setSelectedColumns,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const exportOptions = [{ label: "CSV", value: "csv" }];
  const fileName = title ? title : "report";
  const exportType = exportFromJSON.types.csv;
  const onClickExportCsv = () => {
    exportFromJSON({ data: tableData, fileName, exportType });
  };
  const filterDateRange = [
    { label: "This Month", value: "this_month" },
    { label: "This Year", value: "this_year" },
    { label: "All", value: "" },
    ...(title === "Daily Sales Report" || title === "Overall Sales Report"
      ? [
          { label: "This Week", value: "this_week" },
          { label: "Today", value: "today" },
        ]
      : []),
    ...(title !== "Daily Sales Report"
      ? [{ label: "Custom Date", value: "custom_date" }]
      : []),
  ];
  return (
    <InnerLayout backBtn={true} list={true} title={title}>
      <div className=" flex justify-between mx-6 flex-wrap report-header">
        <div className="relative flex gap-3 pt-2">
          <button
            style={{
              height: "2.6rem",
            }}
            onClick={() => null}
            className="flex items-center whitespace-nowrap fs_14 rounded-md px-3 gap-2 text-344054 fw_600"
          >
            <FilterIcon />
            {"Filters"}
          </button>
          <div
            style={{ maxWidth: "16rem", height: "2.6rem" }}
            className={`w-full flex items-center rounded-md input_border `}
          >
            <span className="mx-2 whitespace-nowrap fs_14  text-344054 fw_600">
              Date Range:
            </span>
            <Select
              className="border-0"
              menuPortalTarget={document.body}
              styles={customStylesForDateRange}
              value={filterDateRange.find(
                (item) => item.value === filters?.filter_by
              )}
              options={filterDateRange}
              onChange={(selectedOption) => {
                setFilters({
                  ...filters,
                  filter_by: selectedOption.value,
                  from: null,
                  to: null,
                });
              }}
            />
          </div>

          {filters?.filter_by === "custom_date" && (
            <div
              style={{
                width: "fit-content",
                height: "2.6rem",
              }}
              className="input_border rounded-md text-344054 fw_600 flex items-center md:flex-wrap"
            >
              <div className="px-1 flex items-center">
                <span className="fs_14">From:</span>
                <input
                  style={{
                    width: "7rem",
                  }}
                  placeholder="DD-MM-YYYY"
                  className=" table_search_input w-full rounded-md mr-4 ml-1 fs_14 placeholder:text-fs_14"
                  type="date"
                  value={filters?.from}
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      from: e.target.value,
                    });
                  }}
                />
                <span className="fs_14">To:</span>
                <input
                  style={{
                    width: "7rem",
                  }}
                  placeholder="DD-MM-YYYY"
                  className=" table_search_input w-full rounded-md ml-1 fs_14 placeholder:text-fs_14"
                  type="date"
                  value={filters?.to}
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      to: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          )}

          <div
            onClick={() => setOpenMoreFilter(!openMoreFilter)}
            style={{ maxWidth: "8rem", height: "2.6rem" }}
            className={`w-full px-2 cursor-pointer flex align-middle items-center rounded-md input_border`}
          >
            <PlusIcon
              onClick={(e) => {
                e.preventDefault();
              }}
              className={`w-4 text-primary`}
            />
            <span className="mx-2 whitespace-nowrap fs_14  text-344054 fw_600">
              More Filter
            </span>
          </div>
          <div>
            <AddButton
              onclkFn={() => handleApply(filters)}
              prefixIcon={false}
              text="Apply"
              type="submit"
            />
          </div>
        </div>

        <div className="flex gap-3 pt-2">
          <div
            style={{ maxWidth: "16rem", height: "2.6rem" }}
            className={`w-full flex items-center rounded-md input_border`}
          >
            <span className="mx-2 whitespace-nowrap fs_14  text-344054 fw_600">
              Group By:
            </span>
            <Select
              className="border-0"
              menuPortalTarget={document.body}
              styles={customStyles}
              value={groupByOptions.find(
                (item) => item.value === filters?.group_by
              )}
              options={groupByOptions}
              onChange={(sel) => {
                setFilters({ ...filters, group_by: sel?.value });
              }}
            />
          </div>
          <div
            style={{ maxWidth: "10rem", height: "2.6rem" }}
            className={`w-full flex items-center py-3 rounded-md input_border `}
          >
            <span className="mx-2 whitespace-nowrap fs_12  text-344054 fw_600">
              Export
            </span>
            <Select
              className="border-0"
              menuPortalTarget={document.body}
              styles={customStylesForExports}
              options={exportOptions}
              onChange={onClickExportCsv}
              placeholder=""
            />
          </div>
          <div
            style={{ maxWidth: "8rem", height: "2.6rem" }}
            className={`w-full flex items-center py-3 rounded-md cursor-pointer`}
            onClick={() => setIsDrawerOpen(true)}
          >
            <ColoumnsButton />
          </div>
          <button
            style={{
              height: "2.6rem",
            }}
            onClick={() => {
              setFilters(initFilter);
              handleApply(initFilter);
            }}
            className={`px-4 whitespace-nowrap fs_14  fw_600 rounded-md input_border flex items-center gap-2  h-full
                ${
                  JSON.stringify(filters) === JSON.stringify(initFilter) &&
                  "bg-F2F4F5"
                }
                `}
          >
            <ClearIcon />
            Clear All
          </button>
        </div>
      </div>
      {children}
      <ColoumnsModal
        filters={filters}
        setFilters={setFilters}
        isModalOpen={isDrawerOpen}
        setIsModalOpen={() => setIsDrawerOpen(!isDrawerOpen)}
        selectedColumns={selectedColumns}
        availableColumns={availableColumns}
        setSelectedColumns={setSelectedColumns}
      />
    </InnerLayout>
  );
};

export default ReportLayout;

export const customStylesForDateRange = {
  control: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    fontWeight: "600",
    color: "#344054",
    width: "9rem",
    boxShadow: "none", // Remove box shadow
    border: "none",
    borderRadius: "6px",
  }),
  placeholder: (defaultStyles) => ({
    ...defaultStyles,
    color: "gray",
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    fontWeight: "600",
    opacity: "1",
    position: "absolute",
    whiteSpace: "nowrap",
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none", // Hide indicator separator
  }),
  singleValue: (base) => ({
    ...base,
    fontWeight: "600",
    color: "#344054",
    fontSize: "12px",
  }),
  option: (provided, state) => ({
    ...provided,
    ...(state.data.color ? { color: state.data.color } : { color: "black" }),
    backgroundColor: state.isSelected ? "#e5ecffb2" : null,
    borderBottom: "1px solid #F5F5F5",
    fontSize: "12px",
    fontFamily: "Inter, sans-serif",
    "&:hover": {
      backgroundColor: state.isFocused ? "#0000002E" : "#0000002E",
    },
    "&::after": {
      content: state.isSelected ? '"✓"' : '""',
      color: "#007bff", // Blue color
      fontWeight: "normal",
      fontSize: "20px", // Larger size
      position: "absolute",
      right: "0.8rem",
      marginBottom: "22px",
    },
  }),
};

export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    fontWeight: "600",
    color: "#344054",
    width: "10rem",
    boxShadow: "none", // Remove box shadow
    border: "none",
    borderRadius: "6px",
  }),
  placeholder: (defaultStyles) => ({
    ...defaultStyles,
    color: "gray",
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    fontWeight: "600",
    opacity: "1",
    position: "absolute",
    whiteSpace: "nowrap",
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none", // Hide indicator separator
  }),
  singleValue: (base) => ({
    ...base,
    fontWeight: "600",
    color: "#344054",
    fontSize: "12px",
  }),
  option: (provided, state) => ({
    ...provided,
    ...(state.data.color ? { color: state.data.color } : { color: "black" }),
    backgroundColor: state.isSelected ? "#e5ecffb2" : null,
    borderBottom: "1px solid #F5F5F5",
    fontSize: "12px",
    fontFamily: "Inter, sans-serif",
    "&:hover": {
      backgroundColor: state.isFocused ? "#0000002E" : "#0000002E",
    },
    "&::after": {
      content: state.isSelected ? '"✓"' : '""',
      color: "#007bff", // Blue color
      fontWeight: "normal",
      fontSize: "20px", // Larger size
      position: "absolute",
      right: "0.8rem",
      marginBottom: "22px",
    },
  }),
};

export const customStylesForExports = {
  control: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    fontWeight: "600",
    color: "#344054",
    width: "6rem",
    boxShadow: "none", // Remove box shadow
    border: "none",
    borderRadius: "6px",
  }),
  placeholder: (defaultStyles) => ({
    ...defaultStyles,
    color: "black",
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    fontWeight: "600",
    opacity: "1",
    position: "absolute",
    whiteSpace: "nowrap",
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none", // Hide indicator separator
  }),
  singleValue: (base) => ({
    ...base,
    fontWeight: "600",
    color: "#344054",
    fontSize: "12px",
  }),
  option: (provided, state) => ({
    ...provided,
    ...(state.data.color ? { color: state.data.color } : { color: "black" }),
    backgroundColor: state.isSelected ? "#e5ecffb2" : null,
    borderBottom: "1px solid #F5F5F5",
    fontSize: "12px",
    fontFamily: "Inter, sans-serif",
    "&:hover": {
      backgroundColor: state.isFocused ? "#0000002E" : "#0000002E",
    },
  }),
};
