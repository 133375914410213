import React from 'react';

const PDFViewer = () => {
  return (
    <div style={{ width: '100%', height: '800px' }}>
      <embed src="http://staging.storeexecutionapi.davaindiaonline.in/SignedAgreementAndPolicies/NGJVAD2434_Privacy_Policy_24112023141219.pdf" type="application/pdf" width="100%" height="100%" />
      
    </div>
  );
};

export default PDFViewer;
