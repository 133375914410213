import { useFormikContext } from "formik";
import React from "react";
import SelectField from "../../forms/select-field";

const BatchNumberEdit = ({ rowIndex, disabled }) => {
  const { values, setFieldValue } = useFormikContext();
  const selectedBatchNumbers = values.data
    .filter((_, index) => index !== rowIndex)
    .map((row) => row?.item_batch_number);
  const selectedFromBins = values.data
    .filter((_, index) => index !== rowIndex)
    ?.map((row) => row?.to_bin_id);
  return (
    <>
      <SelectField
        dynColor="var(--secondary)"
        handleSelected={(sel) => {
          console.log(sel, "sdfsfsdfsfsdfsdfsf");
          setFieldValue(
            `data[${rowIndex}].item_exp_date`,
            values.data[rowIndex].itemBatchList?.find(
              (item) => item?.created_date === sel?.created_date
            )?.item_exp_date
          );
          setFieldValue(`data[${rowIndex}].to_bin_id`, sel?.to_bin_id);
          setFieldValue(
            `data[${rowIndex}].item_rack_location`,
            sel?.item_rack_location
          );
        }}
        borderclass=""
        defaultSelectClass="border-0"
        selectClass="w-full"
        defaultValue={{
          label: values.data[rowIndex]?.item_batch_number,
        }}
        isDisabled={disabled}
        errMsgContainerClass="hidden"
        id={`data[${rowIndex}].item_batch_number`}
        isContainer={false}
        options={values.data[rowIndex].itemBatchList
          ?.filter(
            (item) =>
              parseFloat(item?.item_quantity) >=
                values.data[rowIndex]?.quantity &&
              !selectedFromBins.includes(item?.to_bin_id)
          )
          .map((item) => {
            return {
              label: (
                <span className="flex flex-col">
                  <span>Avail Qty: {item?.item_quantity}</span>
                  <span>Btch Num: {item?.item_batch_number}</span>
                </span>
              ),
              value: item?.item_batch_number,
              to_bin_id: item?.to_bin_id,
              created_date: item?.created_date,
              item_rack_location: item?.item_rack_location,
            };
          })}
        name={`data[${rowIndex}].item_batch_number`}
        placeholder={"Select Payment Method"}
      />
    </>
  );
};

export default BatchNumberEdit;
