import React, { useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { storeIdAction } from "../../../redux/actions/general-action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  calculateSubtotal,
  calculateTotal,
  calculateTotalTax,
} from "../../invoice-section/invoice-finalBill-table";
import { storeDetailsAction } from "../../../redux/actions/invoices-action";
import successToast from "../../helper-components/success-toast";
import errorToast from "../../helper-components/error-toast";
import {
  salesCreditEditAction,
  salesCreditReceiptCreateAction,
} from "../../../redux/actions/sales-action";
import SalesCreditBillingTable from "./sales-credit-billing-table";
import moment from "moment";
import SalesCreditPdfContent from "./sales-credit-pdf-content";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import SalesPurchaseFinalBillTable from "../sales-purchase-finalBill-table";
import SalesPurchaseHeader from "../sales-purchase-header";
import BatchQuantityExpiryPopoverCredit from "./batch-qty-expiry-popover";
import { useHotkeys } from "react-hotkeys-hook";
const CreateEditViewSalesCredit = ({
  orderDetail = [],
  vendorDetails = [],
  type = "",
  page = "",
  disabled = false,
  data,
}) => {
  const rowData = useSelector((state) => state.invoiceRowReducer);
  const searchRef = useRef(null);
  const [heightOfFirstDiv, setHeightOfFirstDiv] = useState(0);
  useEffect(() => {
    const updateHeight = () => {
      const firstDivHeight = document.querySelector(".first-div").clientHeight;
      setHeightOfFirstDiv(firstDivHeight);
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);
  const [addbatch, setAddbatch] = useState(false);
  const [itemId, setItemId] = useState(null);
  const initialData = {
    sl_no: 1,
    item_code: "",
    item_generic_name: "",
    item_name: "",
    item_to_be_delivered: 0,
    quantity: 0,
    item_uom: "",
    item_batches: [],
    item_unit_price: 0,
    item_discount_amount: 0.0,
    item_discount_percentage: 0,
    item_tax_amount: 0,
    item_total_amount: 0,
    item_id: "",
    instock: true,
    item_gst: 0,
    item_sgst: 0,
    item_cgst: 0,
    item_uom_id: "",
  };

  const initialFormValues =
    rowData?.data?.length >= 1
      ? {
          ...rowData,
          data: [...rowData.data, initialData],
        }
      : {
          cmr_code: vendorDetails?.[0]?.cmr_id
            ? {
                label: vendorDetails?.[0]?.cmr_code ?? "",
                value: vendorDetails?.[0]?.cmr_id ?? "",
              }
            : "",
          cmr_name: vendorDetails?.[0]?.cmr_id
            ? {
                label: vendorDetails?.[0]?.cmr_name,
                value: vendorDetails?.[0]?.cmr_id,
              }
            : "",
          cmr_id: vendorDetails?.[0]?.cmr_id,
          remarks: vendorDetails?.[0]?.remarks ?? "",
          sct_total_discount: 0,
          ...(vendorDetails?.[0]?.sct_id
            ? { sct_id: vendorDetails?.[0]?.sct_id }
            : {}),
          sct_order_date: vendorDetails?.[0]?.sct_order_date
            ? moment(vendorDetails?.[0]?.sct_order_date).format("YYYY-MM-DD")
            : moment(new Date()).format("YYYY-MM-DD"),
          sct_document_date: vendorDetails?.[0]?.sct_document_date
            ? moment(vendorDetails?.[0]?.sct_document_date).format("YYYY-MM-DD")
            : moment(new Date()).format("YYYY-MM-DD"),
          sct_delivery_date: vendorDetails?.[0]?.sct_delivery_date
            ? moment(vendorDetails?.[0]?.sct_delivery_date).format("YYYY-MM-DD")
            : moment(new Date()).format("YYYY-MM-DD"),
          data: [
            ...orderDetail?.map((item, index) => ({
              sl_no: index + 1,
              item_code: item?.item_code,
              item_generic_name: item?.item_generic_name,
              item_name: item?.item_name,
              item_to_be_delivered: item?.item_credit_quanity_remaining,
              quantity: type === "sales" ? 0 : item?.item_quantity,
              item_uom: item?.item_uom ?? "--",
              item_batches:
                type === "sales"
                  ? []
                  : item?.itemBatchData.map((batch) => ({
                      item_batch_number: batch?.item_batch_number,
                      item_batch_quantity: batch?.item_batch_quantity,
                      item_exp_date: moment(batch?.item_exp_date).format(
                        "YYYY-MM-DD"
                      ),
                      item_id: batch?.item_id,
                    })) ?? [],
              item_unit_price: item?.item_unit_price,
              item_discount_amount: item?.item_discount_amount,
              item_discount_percentage: item?.item_discount_percentage,
              item_tax_amount: item?.item_tax_amount ?? 0,
              item_total_amount: item?.item_total_amount,
              item_id: item?.item_id,
              ...(item?.soit_id && type === "invoice"
                ? { soit_id: item?.soit_id }
                : { soit_id: null }),
              ...(item?.srt_id && type === "return"
                ? { srt_id: item?.srt_id }
                : { srt_id: null }),
              instock: true,
              item_gst: item?.item_gst,
              item_cgst: item?.item_cgst,
              item_sgst: item?.item_sgst,
              uom_dropdown: item?.uom_dropdown
                ? item?.uom_dropdown.map((uom) => ({
                    label: uom?.alt_uom,
                    value: uom?.alt_uom,
                  }))
                : [],
              item_uom_id: item?.item_uom_id ?? "",
            })),
            initialData,
          ],
        };
  const [initialValue, setInitialValue] = useState(initialFormValues);
  const [reload, setReload] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [storeId, setStoreId] = useState("");
  const [storeDetails, setStoreDetails] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    cmr_code: Yup.object().required("Customer code number is required"),
    cmr_name: Yup.object().required("Customer name is required"),
  });

  useEffect(() => {
    dispatch(storeIdAction(onSuccessStore, onErrorStore));
    dispatch(storeDetailsAction(onSuccessStoreDetails, onErrorStoreDetails));
  }, []);

  const onSuccessStore = (data) => {
    setStoreId(data?.data?.store_id);
    setReload(!reload);
  };
  const onErrorStore = () => {};
  const onSuccessStoreDetails = (data) => {
    setStoreDetails(data?.data);
  };
  const onErrorStoreDetails = () => {};

  const onErrorOrder = (data) => {
    errorToast(data?.data?.message);
  };
  const handleSubmit = (values) => {
    const missingUOM = values?.data
      ?.slice(0, -1)
      .some((item) => !item.item_uom);
    if (page === "edit") {
      dispatch(
        salesCreditEditAction(
          {
            sct_id: values?.sct_id,
            remarks: values?.remarks,
          },
          onSuccessOrder,
          onErrorOrder
        )
      );
    } else {
      if (
        values?.data
          ?.slice(0, -1)
          .some((item) => parseInt(item.quantity) === 0 || item.quantity === "")
      ) {
        errorToast("Quantity cannot be zero");
      } else if (missingUOM) {
        errorToast("Please add UOM for all items");
      } else {
        if (values?.data?.length > 0) {
          dispatch(
            salesCreditReceiptCreateAction(
              {
                orderData: {
                  store_id: storeId,
                  ...(values?.sct_id ? { sct_id: values?.sct_id } : {}),
                  sct_total_gst: Math.round(
                    values.data
                      ?.slice(0, -1)
                      .reduce(
                        (total, item) =>
                          total + parseFloat(item?.item_tax_amount),
                        0
                      )
                  ),
                  sct_total_discount: values?.sct_total_discount,
                  sct_payment_status: "paid",
                  sct_transaction_id: "",
                  sct_billing_address: "",
                  sct_total_amount: parseFloat(
                    calculateTotal(values?.data) +
                      calculateTotalTax(values?.data) -
                      parseFloat(values?.sct_total_discount)
                  ),
                  sct_sub_total: parseFloat(calculateSubtotal(values?.data)),
                  cmr_code: values?.cmr_code?.label,
                  cmr_id: values?.cmr_id,
                  cmr_name: values?.cmr_name?.label,
                  remarks: values?.remarks,
                  // sct_order_date: values?.sct_order_date,
                  sct_document_date: values?.sct_document_date,
                  sct_delivery_date: values?.sct_delivery_date,
                },
                itemData: values.data?.slice(0, -1).map((item, index) => ({
                  item_code: item?.item_code,
                  item_generic_name: item?.item_generic_name,
                  item_name: item?.item_name,
                  item_quantity: parseInt(item?.quantity),
                  item_batch_number: item?.item_batch_number,
                  item_schedule: item?.item_schedule,
                  item_unit_price: parseFloat(item?.item_unit_price),
                  item_discount_amount: parseFloat(item?.item_discount_amount),
                  item_discount_percentage: parseFloat(
                    item?.item_discount_percentage
                  ),
                  item_tax_amount: parseFloat(item?.item_tax_amount),
                  item_total_amount: parseFloat(
                    parseFloat(item.item_unit_price) * parseInt(item.quantity) -
                      parseFloat(item.item_discount_amount) +
                      parseFloat(item.item_tax_amount)
                  ),
                  item_id: item?.item_id,
                  ...(item?.soit_id ? { soit_id: item?.soit_id } : {}),
                  ...(item?.srt_id ? { srt_id: item?.srt_id } : {}),
                  item_gst: parseFloat(item?.item_gst),
                  item_sgst: parseFloat(item?.item_sgst),
                  item_cgst: parseFloat(item?.item_cgst),
                  item_uom_id: item?.item_uom_id ?? "",
                })),

                itemBatchData: values.data
                  ?.slice(0, -1)
                  .map((item) => item.item_batches)
                  .flat(),
              },
              onSuccessOrder,
              onErrorOrder
            )
          );
        }
      }
    }
  };
  const downloadPdf = async (data) => {
    const fileName = "test.pdf";
    try {
      const blob = await pdf(
        <SalesCreditPdfContent data={data} storeDetails={storeDetails} />
      ).toBlob();
      saveAs(blob, fileName);
    } catch (error) {}
  };
  const onSuccessOrder = (data) => {
    if (formValues?.submittedButton === "print") {
      downloadPdf(data?.data);
    }
    navigate("/sales-credit-note");
    successToast(data?.message);
    setInitialValue({
      cmr_code: "",
      cmr_name: "",
      cmr_id: "",
      remarks: "",
      sct_total_discount: 0,
      sct_order_date: null,
      sct_document_date: null,
      sct_delivery_date: null,
      data: [initialData],
    });
  };
  useHotkeys("escape", () => {
    setAddbatch(false);
  });

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValue}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue, errors }) => {
        setFormValues(values);
        return (
          <Form className="h-full">
            <div className="flex flex-col h-full ">
              <div class="flex flex-wrap justify-between first-div px-3">
                <SalesPurchaseHeader
                  section="sales"
                  name="sct"
                  disabled={disabled}
                  searchRef={searchRef}
                />
              </div>
              {addbatch && (
                <BatchQuantityExpiryPopoverCredit
                  setItemId={setItemId}
                  itemId={itemId}
                  setAddbatch={setAddbatch}
                />
              )}
              <div
                className="flex flex-col "
                style={{
                  maxHeight: `calc(100% - ${heightOfFirstDiv}px)`,
                  height: `calc(100% - ${heightOfFirstDiv}px)`,
                }}
              >
                <SalesCreditBillingTable
                  disabled={disabled}
                  type={type}
                  addBatchFn={(id) => {
                    setAddbatch(true);
                    setItemId(id);
                  }}
                  searchRef={searchRef}
                  initialData={initialData}
                />
                <div className="h-[15rem] mt-auto">
                  <SalesPurchaseFinalBillTable
                    name="sct_total_discount"
                    page={page}
                    disabled={disabled}
                    printFn={() => downloadPdf(data)}
                  />
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CreateEditViewSalesCredit;
