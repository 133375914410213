import React, { useState } from "react";
import StoreAddressTab from "./address-tab/address-tab";
import TabsComponent from "../helper-components/tabs-component";
import StorePersonalTab from "./personal-details/personal-details";
import StoreFirmTab from "./firm-details-tab/firm-tab";
import StoreAgreementTab from "./store-agreement/store-agreement";
import StorePaymentTab from "./payment-tab/payment-tab";
import StoreDrugTab from "./drug-license-tab/drug-license-tab";

const StoreCreateViewEdit = ({
  storeData,
  setStoreData,
  store,
  disabled = false,
}) => {
  const [currentTab, setCurrentTab] = useState("Address");
  const [id, setId] = useState(store?.store_id);

  return (
    <>
      <TabsComponent
        handleClickfn={(item) => {
          setCurrentTab(item);
        }}
        itemTabs={itemTabs}
        setCurrentTab={setCurrentTab}
        currentTab={currentTab}
      />
      {currentTab === "Address" && (
        <StoreAddressTab
          disabled={disabled}
          id={id}
          initialValue={storeData?.address}
          storeData={storeData}
          setId={setId}
          setStoreData={setStoreData}
        />
      )}
      {currentTab === "Personal Details" && (
        <StorePersonalTab
          disabled={disabled}
          id={id}
          initialValue={storeData?.personal}
          storeData={storeData}
          setId={setId}
          setStoreData={setStoreData}
        />
      )}
      {currentTab === "Firm Details" && (
        <StoreFirmTab
          disabled={disabled}
          id={id}
          initialValue={storeData?.firm}
          storeData={storeData}
          setId={setId}
          setStoreData={setStoreData}
        />
      )}
      {currentTab === "Store Agreement" && (
        <StoreAgreementTab
          disabled={disabled}
          id={id}
          initialValue={storeData?.agreement}
          storeData={storeData}
          setId={setId}
          setStoreData={setStoreData}
        />
      )}
      {currentTab === "Payment" && (
        <StorePaymentTab
          disabled={disabled}
          id={id}
          initialValue={storeData?.payment}
          storeData={storeData}
          setId={setId}
          setStoreData={setStoreData}
        />
      )}
      {currentTab === "Drug License" && (
        <StoreDrugTab
          disabled={disabled}
          id={id}
          initialValue={storeData?.drug}
          storeData={storeData}
          setId={setId}
          setStoreData={setStoreData}
        />
      )}
    </>
  );
};

export default StoreCreateViewEdit;

const itemTabs = [
  "Address",
  "Personal Details",
  "Firm Details",
  "Store Agreement",
  "Payment",
  "Drug License",
];
