import React from "react";

const StoreLicensingIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.47 21C5.478 21 3.481 21 2.24 19.828C1 18.657 1 16.771 1 13V9C1 5.229 1 3.343 2.24 2.172C3.481 1 5.478 1 9.47 1H10.53C14.523 1 16.519 1 17.76 2.172C19 3.343 19 5.229 19 9M6 6H14M6 11H12"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 21C14.838 18.252 19.119 18.102 21 21M19 13.929C19 14.994 18.105 15.857 17 15.857C15.895 15.857 15 14.994 15 13.929C15 12.864 15.895 12 17 12C18.105 12 19 12.864 19 13.929Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StoreLicensingIcon;
