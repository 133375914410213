import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import AddButton, { DraftButton } from "../../buttons/button";
import * as Yup from "yup";
import PeriodDisUnCheckbox, {
  PeriodDisCheckbox,
} from "../../../assets/icons/period-dis-checkbox";
import { useDispatch } from "react-redux";
import {
  periodVolumeAddAction,
  periodVolumeGetAction,
} from "../../../redux/actions/discount-action";
import moment from "moment";
import DeleteIcon from "../../../assets/icons/delete-icon";
import LoadingSpinner from "../../helper-components/loading-spinner";
import successToast from "../../helper-components/success-toast";
const PeriodVolumePopover = ({
  setShowPerVolumePopover,
  item,
  periodDiscountItemId,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [perVolumeData, setPerVolumeData] = useState({});
  const initData = {
    period_discount_item_id: perVolumeData?.[0]?.period_discount_item_id,
    from_date: null,
    to_date: null,
    discount_percentage: 0,
    effective_price: "",
    item_selling_price: "",
    itemVolumeData: [],
  };
  const initDataVol = {
    volume_validity_id: "",
    item_quantity: "",
    volume_discount_percentage: "",
    volume_effective_price: "",
  };
  useEffect(() => {
    setLoading(true);
    dispatch(
      periodVolumeGetAction(
        {
          period_discount_item_id: periodDiscountItemId,
        },
        onSuccess,
        onError
      )
    );
  }, []);

  const onSuccess = (data) => {
    setLoading(false);

    setPerVolumeData(data?.data);
  };
  const onError = (data) => {
    setLoading(false);
  };

  // const extraLen = 5 - perVolumeData?.length;
  // const extraitemVolumeData =
  //   5 - perVolumeData?.[currentIndex]?.itemVolumeData?.length;

  const initialValues = {
    itemPeriodData: [
      ...(perVolumeData?.length > 0
        ? perVolumeData.map((pervol) => ({
            period_discount_item_id: pervol?.period_discount_item_id ?? "",
            from_date: pervol?.from_date
              ? moment(pervol?.from_date).format("YYYY-MM-DD")
              : "",
            to_date: pervol?.from_date
              ? moment(pervol?.to_date).format("YYYY-MM-DD")
              : "",
            discount_percentage: pervol?.discount_percentage ?? "",
            effective_price: pervol?.effective_price ?? "",
            item_selling_price: pervol?.item_selling_price ?? "",
            period_validity_id: pervol?.period_validity_id ?? "",
            itemVolumeData: [
              ...(pervol?.itemVolumeData?.length > 0
                ? pervol?.itemVolumeData?.map((vol) => ({
                    volume_validity_id: vol?.volume_validity_id ?? "",
                    item_quantity: vol?.item_quantity ?? "",
                    volume_discount_percentage:
                      vol?.volume_discount_percentage ?? "",
                    volume_effective_price: vol?.volume_effective_price ?? "",
                  }))
                : []),
            ],
          }))
        : []),
    ],
  };

  const validationSchema = Yup.object().shape({});
  const onSubmit = (values) => {
    const filteredItemPeriodData = values.itemPeriodData.filter((period) => {
      return (
        period.from_date !== null &&
        period.to_date !== null &&
        period.discount_percentage !== "" &&
        period.effective_price !== ""
      );
    });
    const filteredItemPeriodDataWithFilteredVolumeData =
      filteredItemPeriodData.map((period) => {
        const filteredItemVolumeData = period.itemVolumeData.filter(
          (volume) => {
            return (
              volume.volume_validity_id !== "" ||
              volume.item_quantity !== "" ||
              volume.volume_discount_percentage !== "" ||
              volume.volume_effective_price !== ""
            );
          }
        );
        return { ...period, itemVolumeData: filteredItemVolumeData };
      });

    const finalData = filteredItemPeriodDataWithFilteredVolumeData?.map(
      (final) => ({
        period_discount_item_id: final.period_discount_item_id,
        ...(final.period_validity_id && {
          period_validity_id: final.period_validity_id,
        }),
        from_date: final.from_date,
        to_date: final.to_date,
        discount_percentage: parseFloat(final.discount_percentage),
        effective_price: parseFloat(final.effective_price),
        itemVolumeData: final.itemVolumeData.map((volume) => ({
          ...(volume.volume_validity_id && {
            volume_validity_id: volume.volume_validity_id,
          }),
          item_quantity: parseFloat(volume.item_quantity),
          volume_discount_percentage: parseFloat(
            volume.volume_discount_percentage
          ),
          volume_effective_price: parseFloat(volume.volume_effective_price),
        })),
      })
    );

    dispatch(
      periodVolumeAddAction(
        {
          itemPeriodData: finalData,
        },
        onPerVolSuccess,
        onPerVolEroor
      )
    );
  };

  const onPerVolSuccess = (data) => {
    successToast(data?.data?.message);
    setShowPerVolumePopover(false);
  };
  const onPerVolEroor = (data) => {};
  return (
    <div className="absolute w-[-webkit-fill-available]  z-10">
      <div className="2xl:h-[70vh] h-[79vh] bg-white overflow-auto">
        {loading ? (
          <LoadingSpinner />
        ) : (
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, values, setFieldValue }) => {
              return (
                <div className="flex flex-col justify-between h-full">
                  <div>
                    <div className="bg-[#F4F6F9] w-full p-4 font-semibold text-xl-mb-5">
                      {item?.item_name} - Period and volume
                    </div>
                    <div className="flex gap-5  mt-4 px-5 2xl:w-[80%] w-[100%] overflow-auto flex-wrap">
                      <div className="xl:w-[53%] w-[100%]">
                        <div className="flex justify-end w-full">
                          <AddButton
                            className="bg-primary text-white px-4 py-1 mb-2 rounded flex justify-center fw_500 fs_16"
                            onclkFn={() => {
                              setFieldValue("itemPeriodData", [
                                ...values?.itemPeriodData,
                                initData,
                              ]);
                            }}
                            prefixIcon={false}
                            text="Add row"
                            type="button"
                          />
                        </div>
                        <table className="w-[100%] border-D0D5DD border border-1 rounded-lg">
                          <thead>
                            <tr className="bg-F2F4F5">
                              <th className="py-2 px-1 border-D0D5DD border border-1  text-secondary fw_400 fs_14"></th>
                              <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-secondary fw_400 fs_14">
                                Validity
                              </th>
                              <th className="py-2 w-[3rem]  px-1 border-D0D5DD border border-1 text-center  text-secondary fw_400 fs_14">
                                Discount (%)
                              </th>
                              <th className="py-2 px-1  border-D0D5DD border border-1 text-center  text-secondary fw_400 fs_14">
                                Discount
                              </th>
                              <th className="py-2 px-1 border-D0D5DD border border-1 text-center  text-secondary fw_400 fs_14">
                                Effective Price
                              </th>
                              <th className="py-2 px-1 border-D0D5DD border border-1 text-center  text-secondary fw_400 fs_14"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {values?.itemPeriodData?.map((period, index) => (
                              <tr key={index}>
                                <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 fs_14">
                                  <input
                                    type="radio"
                                    name="uomSelection"
                                    value={index}
                                    onChange={(e) => setCurrentIndex(index)}
                                    id={`perRadio-${index}`}
                                    className="peer hidden"
                                  />
                                  <label htmlFor={`perRadio-${index}`}>
                                    {index === currentIndex ? (
                                      <PeriodDisCheckbox />
                                    ) : (
                                      <PeriodDisUnCheckbox />
                                    )}
                                  </label>
                                </td>
                                <td className="border-D0D5DD border border-1 text-secondary fw_400 fs_14">
                                  <div className="flex text-344054 fw_600">
                                    <div className="py-2 px-1 border-r-D0D5DD border-r-2 flex gap-1 items-center fs_11">
                                      from:
                                      <Field
                                        name={`itemPeriodData.${index}.from_date`}
                                      >
                                        {({ field }) => (
                                          <input
                                            placeholder="  "
                                            {...field}
                                            type="date"
                                            className={`fs_11  focus_outline__none`}
                                          />
                                        )}
                                      </Field>
                                    </div>
                                    <div className="py-2 px-1 flex gap-2 items-center fs_11">
                                      to:
                                      <Field
                                        name={`itemPeriodData.${index}.to_date`}
                                      >
                                        {({ field }) => (
                                          <input
                                            placeholder="  "
                                            {...field}
                                            type="date"
                                            className={`fs_11 focus_outline__none`}
                                          />
                                        )}
                                      </Field>
                                    </div>
                                  </div>
                                </td>
                                <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 fs_14">
                                  <input
                                    style={{
                                      textAlign: "right",
                                    }}
                                    type="number"
                                    onChange={(event) => {
                                      const discountPercentage = parseFloat(
                                        event.target.value
                                      );
                                      const itemSellingPrice = parseFloat(
                                        perVolumeData?.[0]?.item_selling_price
                                      );
                                      const effectivePrice =
                                        itemSellingPrice -
                                        (itemSellingPrice *
                                          discountPercentage) /
                                          100;
                                      setFieldValue(
                                        `itemPeriodData[${index}].discount_percentage`,
                                        discountPercentage
                                      );
                                      setFieldValue(
                                        `itemPeriodData[${index}].effective_price`,
                                        effectivePrice.toFixed(2)
                                      );
                                    }}
                                    name={`itemPeriodData[${index}].discount_percentage`}
                                    value={
                                      values.itemPeriodData[index]
                                        .discount_percentage
                                    }
                                    className={`w-[3rem]  focus_outline__none`}
                                  />
                                </td>
                                <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 fs_14">
                                  {(perVolumeData?.[0]?.item_selling_price *
                                    values?.itemPeriodData?.[index]
                                      .discount_percentage) /
                                    100}
                                </td>
                                <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 fs_14">
                                  {period?.effective_price}
                                </td>
                                <td className="border-D0D5DD border border-1 py-2 px-2 text-secondary fw_400 fs_14">
                                  {index > 0 && (
                                    <button
                                      onClick={() => {
                                        const updatedData = [
                                          ...values.itemPeriodData,
                                        ];
                                        updatedData.splice(index, 1); // Remove the item at the specified index
                                        setFieldValue(
                                          "itemPeriodData",
                                          updatedData
                                        ); // Update the formik field value
                                      }}
                                      type="button"
                                    >
                                      <DeleteIcon />
                                    </button>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="xl:w-[43%] w-[100%]">
                        <div className="flex justify-end w-full">
                          <AddButton
                            className="bg-primary text-white px-4 py-1 mb-2 rounded flex justify-center fw_500 fs_16"
                            onclkFn={() => {
                              const newPeriodData = values?.itemPeriodData.map(
                                (pervol) => ({
                                  ...pervol,
                                  itemVolumeData: [
                                    ...pervol.itemVolumeData,
                                    initDataVol,
                                  ],
                                })
                              );

                              setFieldValue("itemPeriodData", newPeriodData);
                            }}
                            prefixIcon={false}
                            text="Add row"
                            type="button"
                          />
                        </div>
                        <table className="w-[100%] border-D0D5DD border border-1 rounded-lg">
                          <thead>
                            <tr className="bg-F2F4F5">
                              <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-secondary fw_400 fs_14">
                                Quantity
                              </th>{" "}
                              <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-secondary fw_400 fs_14">
                                Discount (%)
                              </th>
                              <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-secondary fw_400 fs_14">
                                Discount
                              </th>
                              <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-secondary fw_400 fs_14">
                                Effective Price
                              </th>
                              <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-secondary fw_400 fs_14"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {values.itemPeriodData[
                              currentIndex
                            ]?.itemVolumeData.map((volume, volIndex) => (
                              <tr key={volIndex}>
                                <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 fs_14">
                                  <Field
                                    name={`itemPeriodData.${currentIndex}.itemVolumeData.${volIndex}.item_quantity`}
                                  >
                                    {({ field }) => (
                                      <input
                                        placeholder=""
                                        {...field}
                                        type="text"
                                        className={`w-[3rem]  focus_outline__none`}
                                      />
                                    )}
                                  </Field>
                                </td>
                                <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 fs_14">
                                  <input
                                    onChange={(event) => {
                                      const volumeDiscountPercentage =
                                        parseFloat(event.target.value);
                                      const itemSellingPrice = parseFloat(
                                        perVolumeData?.[0]
                                          ?.item_selling_price || 0
                                      );

                                      const effectivePrice =
                                        itemSellingPrice -
                                        (itemSellingPrice *
                                          volumeDiscountPercentage) /
                                          100;

                                      // Set the volume_discount_percentage and volume_effective_price in formik values
                                      setFieldValue(
                                        `itemPeriodData[${currentIndex}].itemVolumeData[${volIndex}].volume_discount_percentage`,
                                        volumeDiscountPercentage
                                      );
                                      setFieldValue(
                                        `itemPeriodData[${currentIndex}].itemVolumeData[${volIndex}].volume_effective_price`,
                                        effectivePrice.toFixed(2)
                                      ); // Adjust to your precision needs
                                    }}
                                    value={
                                      values?.itemPeriodData?.[currentIndex]
                                        .itemVolumeData[volIndex]
                                        .volume_discount_percentage
                                    } // Assuming itemPeriodData is your component state or Redux store
                                    placeholder=""
                                    type="text"
                                    className={`w-[3rem]  focus_outline__none`}
                                  />
                                </td>
                                <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 fs_14">
                                  {(perVolumeData?.[0]?.item_selling_price *
                                    volume?.volume_discount_percentage) /
                                    100}
                                </td>
                                <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 fs_14">
                                  {volume?.volume_effective_price
                                    ? volume?.volume_effective_price
                                    : perVolumeData?.[0]?.item_selling_price -
                                      (perVolumeData?.[0]?.item_selling_price *
                                        volume?.volume_discount_percentage) /
                                        100}

                                  {/* {volume?.volume_effective_price} */}
                                </td>
                                <td className="border-D0D5DD border border-1 py-2 px-2 text-secondary fw_400 fs_14">
                                  <button
                                    className={volIndex > 0 ? "" : "invisible"}
                                    onClick={() => {
                                      const updatedData = [
                                        ...values.itemPeriodData,
                                      ];
                                      const updatedVolumeData = [
                                        ...updatedData[currentIndex]
                                          ?.itemVolumeData,
                                      ];
                                      updatedVolumeData.splice(volIndex, 1); // Remove the item at the specified index
                                      updatedData[currentIndex].itemVolumeData =
                                        updatedVolumeData;
                                      setFieldValue(
                                        "itemPeriodData",
                                        updatedData
                                      ); // Update the formik field value
                                    }}
                                    type="button"
                                  >
                                    <DeleteIcon />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between px-10 sticky bottom-0   bg-white py-2 invoice-bottom-container">
                    <DraftButton
                      onclkFn={() => setShowPerVolumePopover(false)}
                      text="cancel"
                    />
                    <AddButton
                      onclkFn={handleSubmit}
                      prefixIcon={false}
                      text="Save"
                      type="submit"
                    />
                  </div>
                </div>
              );
            }}
          </Formik>
        )}
      </div>
      <div
        className="bg-black 2xl:h-[21.2vh] h-[10vh]  opacity-50"
        onClick={() => setShowPerVolumePopover(false)}
      ></div>
    </div>
  );
};

export default PeriodVolumePopover;
