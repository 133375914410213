import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import AddButton, { DraftButton } from "../../buttons/button";
import * as Yup from "yup";
import PeriodDisUnCheckbox, {
  PeriodDisCheckbox,
} from "../../../assets/icons/period-dis-checkbox";
import { useDispatch } from "react-redux";
import {
  periodVolumeAddAction,
  periodVolumeGetAction,
} from "../../../redux/actions/discount-action";
import moment from "moment";
import DeleteIcon from "../../../assets/icons/delete-icon";
import LoadingSpinner from "../../helper-components/loading-spinner";
import successToast from "../../helper-components/success-toast";
const BulkPeriodVolumePopover = ({ setBulkPopoverOpen, items = [] }) => {
  console.log(items, "dsfsfdsfdsf");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);

  // const extraLen = 5 - perVolumeData?.length;
  // const extraitemVolumeData =
  //   5 - perVolumeData?.[currentIndex]?.itemVolumeData?.length;

  const initialValues = {
    from_date: "",
    to_date: "",
    discount_percentage: "",
    itemsdata: items?.map((item) => ({
      period_discount_item_id: item.period_discount_item_id,
      item_id: item?.item_id,
      item_name: item?.item_name,
      item_selling_price: item?.item_selling_price,
    })),
  };

  const validationSchema = Yup.object().shape({});
  const onSubmit = (values) => {
    dispatch(
      periodVolumeAddAction(
        {
          itemsdata: values?.itemsdata?.map((item) => ({
            period_discount_item_id: item.period_discount_item_id,
            item_id: item?.item_id,
            item_name: item?.item_name,
            from_date: values.from_date,
            to_date: values.to_date,
            discount_percentage: parseFloat(values.discount_percentage),
            effective_price: parseFloat(
              item?.item_selling_price -
                (item?.item_selling_price *
                  (values?.discount_percentage ?? 0)) /
                  100
            ),
          })),
        },
        onPerVolSuccess,
        onPerVolEroor
      )
    );
  };

  const onPerVolSuccess = (data) => {
    successToast(data?.data?.message);
    setBulkPopoverOpen(false);
  };
  const onPerVolEroor = (data) => {};
  return (
    <div className="absolute w-[-webkit-fill-available]  z-10">
      <div className="2xl:h-[70vh] h-[79vh] bg-white overflow-auto">
        {loading ? (
          <LoadingSpinner />
        ) : (
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, values, setFieldValue }) => {
              return (
                <div className="flex flex-col justify-between h-full">
                  <div className="2xl:w-[80%] w-[100%]">
                    <div className="flex text-344054 fw_600 bg-white  border-D0D5DD border border-1  rounded-md mx-5 sticky top-0 mt-1 z-20 w-fit">
                      <div className="py-2 px-1 border-r-D0D5DD border-r-2 flex gap-1 items-center fs_14">
                        From:
                        <Field name={`from_date`}>
                          {({ field }) => (
                            <input
                              placeholder="  "
                              {...field}
                              type="date"
                              className={`fs_14 focus_outline__none`}
                            />
                          )}
                        </Field>
                      </div>
                      <div className="py-2 px-1 flex gap-2 items-center fs_14 border-r-D0D5DD border-r-2">
                        To:
                        <Field name={`to_date`}>
                          {({ field }) => (
                            <input
                              placeholder="  "
                              {...field}
                              type="date"
                              className={`fs_14 focus_outline__none`}
                            />
                          )}
                        </Field>
                      </div>
                      <div className="flex items-center fs_14 py-2 px-1">
                        Discount(%) :
                        <input
                          type="number"
                          onChange={(event) => {
                            const discountPercentage = parseFloat(
                              event.target.value
                            );
                            // const itemSellingPrice = parseFloat(
                            //   perVolumeData?.[0]?.item_selling_price
                            // );
                            // const effectivePrice =
                            //   itemSellingPrice -
                            //   (itemSellingPrice * discountPercentage) / 100;
                            setFieldValue(
                              `discount_percentage`,
                              discountPercentage
                            );
                            // setFieldValue(
                            //   `itemPeriodData[${index}].effective_price`,
                            //   effectivePrice.toFixed(2)
                            // );
                          }}
                          name={`discount_percentage`}
                          value={values.discount_percentage}
                          className={`w-[3rem]  focus_outline__none rounded-md px-2 input-field-no-spin-buttons`}
                        />
                      </div>
                    </div>

                    <div className="flex gap-5  mt-4 px-5  overflow-auto flex-wrap">
                      <div className="xl:w-[53%] w-[100%]">
                        <table className="w-[100%] border-D0D5DD border border-1 rounded-lg">
                          <thead>
                            <tr className="bg-F2F4F5">
                              <th className="py-2 px-1 border-D0D5DD border border-1  text-secondary fw_400 fs_14"></th>
                              <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-secondary fw_400 fs_14">
                                Item Name
                              </th>
                              <th className="py-2 px-1 border-D0D5DD border border-1 text-right  text-secondary fw_400 fs_14">
                                Discount Amt
                              </th>
                              <th className="py-2 px-1 border-D0D5DD border border-1 text-right  text-secondary fw_400 fs_14">
                                Effective Price
                              </th>
                              <th className="py-2 px-1 border-D0D5DD border border-1 text-right  text-secondary fw_400 fs_14"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {values?.itemsdata?.map((item, index) => (
                              <tr key={index}>
                                <td className="border-D0D5DD border border-1 text-secondary fw_400 fs_12"></td>
                                <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 fs_12">
                                  {item?.item_name}
                                </td>
                                <td className="border-D0D5DD border border-1 py-2 px-1 text-right text-secondary fw_400 fs_12">
                                  {" "}
                                  {(
                                    (item?.item_selling_price *
                                      (values?.discount_percentage ?? 0)) /
                                    100
                                  ).toFixed(2) ?? 0}
                                </td>
                                <td className="border-D0D5DD border border-1 py-2 px-1 text-right text-secondary fw_400 fs_12">
                                  {(
                                    item?.item_selling_price -
                                    (item?.item_selling_price *
                                      (values?.discount_percentage ?? 0)) /
                                      100
                                  ).toFixed(2) ?? 0}
                                </td>
                                <td className="border-D0D5DD border border-1 py-2 px-2 text-secondary fw_400 fs_12">
                                  <button
                                    className="h-4"
                                    onClick={() => {
                                      const updatedData = [...values.itemsdata];
                                      updatedData.splice(index, 1); // Remove the item at the specified index
                                      setFieldValue("itemsdata", updatedData); // Update the formik field value
                                    }}
                                    type="button"
                                  >
                                    <DeleteIcon width="12" height="12" />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      {/* <div className="xl:w-[43%] w-[100%]">
                        <div className="flex justify-end w-full">
                          <AddButton
                            className="bg-primary text-white px-4 py-1 mb-2 rounded flex justify-center fw_500 fs_16"
                            onclkFn={() => {
                              const newPeriodData = values?.itemPeriodData.map(
                                (pervol) => ({
                                  ...pervol,
                                  itemVolumeData: [
                                    ...pervol.itemVolumeData,
                                    initDataVol,
                                  ],
                                })
                              );

                              setFieldValue("itemPeriodData", newPeriodData);
                            }}
                            prefixIcon={false}
                            text="Add row"
                            type="button"
                          />
                        </div>
                        <table className="w-[100%] border-D0D5DD border border-1 rounded-lg">
                          <thead>
                            <tr className="bg-F2F4F5">
                              <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-secondary fw_400 fs_14">
                                Quantity
                              </th>{" "}
                              <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-secondary fw_400 fs_14">
                                Discount (%)
                              </th>
                              <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-secondary fw_400 fs_14">
                                Discount
                              </th>
                              <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-secondary fw_400 fs_14">
                                Effective Price
                              </th>
                              <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-secondary fw_400 fs_14"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {values.itemPeriodData[
                              currentIndex
                            ]?.itemVolumeData.map((volume, volIndex) => (
                              <tr key={volIndex}>
                                <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 fs_14">
                                  <Field
                                    name={`itemPeriodData.${currentIndex}.itemVolumeData.${volIndex}.item_quantity`}
                                  >
                                    {({ field }) => (
                                      <input
                                        placeholder=""
                                        {...field}
                                        type="text"
                                        className={`w-[3rem]  focus_outline__none`}
                                      />
                                    )}
                                  </Field>
                                </td>
                                <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 fs_14">
                                  <input
                                    onChange={(event) => {
                                      const volumeDiscountPercentage =
                                        parseFloat(event.target.value);
                                      const itemSellingPrice = parseFloat(
                                        perVolumeData?.[0]
                                          ?.item_selling_price || 0
                                      );

                                      const effectivePrice =
                                        itemSellingPrice -
                                        (itemSellingPrice *
                                          volumeDiscountPercentage) /
                                          100;
                                      setFieldValue(
                                        `itemPeriodData[${currentIndex}].itemVolumeData[${volIndex}].volume_discount_percentage`,
                                        volumeDiscountPercentage
                                      );
                                      setFieldValue(
                                        `itemPeriodData[${currentIndex}].itemVolumeData[${volIndex}].volume_effective_price`,
                                        effectivePrice.toFixed(2)
                                      );
                                    }}
                                    value={
                                      values?.itemPeriodData?.[currentIndex]
                                        .itemVolumeData[volIndex]
                                        .volume_discount_percentage
                                    } 
                                    placeholder=""
                                    type="text"
                                    className={`w-[3rem]  focus_outline__none`}
                                  />
                                </td>
                                <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 fs_14">
                                  {(perVolumeData?.[0]?.item_selling_price *
                                    volume?.volume_discount_percentage) /
                                    100}
                                </td>
                                <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 fs_14">
                                  {volume?.volume_effective_price
                                    ? volume?.volume_effective_price
                                    : perVolumeData?.[0]?.item_selling_price -
                                      (perVolumeData?.[0]?.item_selling_price *
                                        volume?.volume_discount_percentage) /
                                        100}

                                </td>
                                <td className="border-D0D5DD border border-1 py-2 px-2 text-secondary fw_400 fs_14">
                                  <button
                                    className={volIndex > 0 ? "" : "invisible"}
                                    onClick={() => {
                                      const updatedData = [
                                        ...values.itemPeriodData,
                                      ];
                                      const updatedVolumeData = [
                                        ...updatedData[currentIndex]
                                          ?.itemVolumeData,
                                      ];
                                      updatedVolumeData.splice(volIndex, 1); 
                                      updatedData[currentIndex].itemVolumeData =
                                        updatedVolumeData;
                                      setFieldValue(
                                        "itemPeriodData",
                                        updatedData
                                      ); 
                                    }}
                                    type="button"
                                  >
                                    <DeleteIcon />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div> */}
                    </div>
                  </div>
                  <div className="flex justify-between px-10 sticky bottom-0   bg-white py-2 invoice-bottom-container">
                    <DraftButton
                      onclkFn={() => setBulkPopoverOpen(false)}
                      text="cancel"
                    />
                    <AddButton
                      onclkFn={handleSubmit}
                      prefixIcon={false}
                      text="Save"
                      type="submit"
                    />
                  </div>
                </div>
              );
            }}
          </Formik>
        )}
      </div>
      <div
        className="bg-black 2xl:h-[23.3vh] h-[14.3vh]  opacity-50"
        onClick={() => setBulkPopoverOpen(false)}
      ></div>
    </div>
  );
};

export default BulkPeriodVolumePopover;
