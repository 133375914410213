import { Field, useFormikContext } from "formik";
import React from "react";
import SelectField from "../../forms/select-field";
import AddButton from "../../buttons/button";
import DeleteIcon from "../../../assets/icons/delete-icon";
import moment from "moment";

const BatchQuantityExpiryDeliveryPopover = ({
  setAddbatch,
  itemId,
  setItemId,
  handleQtyChangeFn = () => {},
  disabled = true,
}) => {
  const { values, setFieldValue } = useFormikContext();

  const defaultSelectedItem = values?.data?.find(
    (item) => itemId === item?.item_id
  );
  const defaultSelectedItemIndex = values?.data?.findIndex(
    (item) => itemId === item?.item_id
  );

  const selectedBatchNumbers = values.data
    .find((_, index) => index !== defaultSelectedItemIndex)
    .item_batches.map((row) => row?.item_batch_number);

  return (
    <div className="absolute w-[-webkit-fill-available]  z-40">
      <div className="h-[72vh] bg-white flex flex-col justify-between">
        <div>
          <div className="bg-[#F4F6F9] w-full p-3 gap-2 font-semibold text-xl  flex items-center">
            Batch details -
            <SelectField
              handleSelected={(selected) => {
                setItemId(selected?.value);
              }}
              containerClass="w-[20rem]"
              placeholder={"Select product"}
              defaultValue={
                defaultSelectedItem
                  ? {
                      label: defaultSelectedItem.item_name,
                      value: defaultSelectedItem.item_id,
                    }
                  : null
              }
              options={values?.data?.map((item) => ({
                label: item?.item_name,
                value: item?.item_id,
              }))}
              label={""}
              id={"item_id"}
              name="item_id"
              isSearchable={true}
              errMsgContainerClass="hidden"
              labelWidth="10.5rem"
            />
          </div>
          <div className="p-3 overflow-y-auto h-[38vh]">
            <table className="lg:w-[50%] w-full border-D0D5DD border border-1 rounded-lg ">
              <thead>
                <tr className="bg-F2F4F5">
                  <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-secondary fw_400 fs_14">
                    Batch Nos.
                  </th>{" "}
                  <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-secondary fw_400 fs_14">
                    Qty
                  </th>
                  <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-secondary fw_400 fs_14">
                    Exp. Date
                  </th>
                  <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-secondary fw_400 fs_14"></th>
                </tr>
              </thead>
              <tbody>
                {defaultSelectedItem?.item_batches?.map((batch, batchindex) => (
                  <tr key={batchindex}>
                    <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 fs_14">
                      <SelectField
                        autoFocus={true}
                        handleSelected={() => {
                          setFieldValue(
                            `data[${defaultSelectedItemIndex}].item_batches[${batchindex}].item_exp_date`,
                            values.data[
                              defaultSelectedItemIndex
                            ].itemBatchList?.find(
                              (item) =>
                                item?.item_batch_number ===
                                values.data[defaultSelectedItemIndex]
                                  ?.item_batches[batchindex]?.item_batch_number
                            )?.item_exp_date
                          );
                        }}
                        selectClass="w-full"
                        defaultValue={{
                          label:
                            values.data[defaultSelectedItemIndex]?.item_batches[
                              batchindex
                            ]?.item_batch_number,
                        }}
                        errMsgContainerClass="hidden"
                        id={`data[${defaultSelectedItemIndex}].item_batches[${batchindex}].item_batch_number`}
                        isContainer={false}
                        options={values.data[
                          defaultSelectedItemIndex
                        ].itemBatchList
                          ?.filter(
                            (item) =>
                              parseFloat(item?.item_sellable_quantity) >
                                values.data[defaultSelectedItemIndex]
                                  ?.item_batches[batchindex]
                                  .item_batch_quantity &&
                              !selectedBatchNumbers.includes(
                                item?.item_batch_number
                              )
                          )
                          .map((item) => {
                            return {
                              label: (
                                <span className="flex flex-col">
                                  <span>
                                    Avail Qty: {item?.item_sellable_quantity}
                                  </span>
                                  <span>
                                    Btch Num: {item?.item_batch_number}
                                  </span>
                                </span>
                              ),
                              value: item?.item_batch_number,
                            };
                          })}
                        name={`data[${defaultSelectedItemIndex}].item_batches[${batchindex}].item_batch_number`}
                        placeholder={"Select Payment Method"}
                      />
                    </td>
                    <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 fs_14">
                      <Field
                        name={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.item_batch_quantity`}
                      >
                        {({ field }) => (
                          <input
                            min={0}
                            disabled={disabled}
                            placeholder=""
                            {...field}
                            type="number"
                            className={`w-full focus_outline__none`}
                            onChange={(e) => {
                              if (
                                e.key === "-" ||
                                e.key === "e" ||
                                e.key === "."
                              ) {
                                e.preventDefault();
                              }
                              field.onChange(e);
                              if (e.target === document.activeElement) {
                                handleQtyChangeFn(defaultSelectedItemIndex); // Call quantity function
                              }
                            }}
                          />
                        )}
                      </Field>
                    </td>
                    <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 fs_14">
                      {moment(batch?.item_exp_date).format("DD/MM/YYYY")}
                    </td>
                    <td className="border-D0D5DD border border-1 py-2 px-2 text-secondary fw_400 fs_14">
                      {/* {batchindex > 0 && ( */}
                      <button
                        onClick={() => {
                          const updatedBatches = [
                            ...defaultSelectedItem.item_batches,
                          ];
                          updatedBatches.splice(batchindex, 1);
                          setFieldValue(
                            `data.${defaultSelectedItemIndex}.item_batches`,
                            updatedBatches
                          );
                        }}
                        type="button"
                      >
                        <DeleteIcon />
                      </button>
                      {/* )} */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex justify-between px-10 sticky bottom-0   bg-white py-2 invoice-bottom-container">
          <AddButton
            onclkFn={() => setAddbatch(false)}
            prefixIcon={false}
            text="Done"
            type="button"
          />
        </div>
      </div>
      <div
        className="bg-black h-[23vh] opacity-50"
        onClick={() => setAddbatch(false)}
      ></div>
    </div>
  );
};

export default BatchQuantityExpiryDeliveryPopover;
