import { api } from "./api";

export const storeAddressCreateApi = (params) => {
  return api.post(`/store/address/add`, JSON.stringify(params));
};
export const storePersonalCreateApi = (params) => {
  return api.post(`/store/personalDetails/add`, JSON.stringify(params));
};
export const storeFirmCreateApi = (params) => {
  return api.post(`/store/firmDetails/add`, JSON.stringify(params));
};
export const storeAgreementCreateApi = (params) => {
  return api.post(`/store/storeAgreement/add`, JSON.stringify(params));
};
export const storePaymentCreateApi = (params) => {
  return api.post(`/store/paymentDetails/add`, JSON.stringify(params));
};
export const storeDrugCreateApi = (params) => {
  return api.post(`/store/drugLicenseDetails/add`, JSON.stringify(params));
};
export const storeAddressUpdateApi = (params) => {
  return api.post(`/store/address/update`, JSON.stringify(params));
};
export const storeTableApi = (params) => {
  const searchParams = params?.filterData?.inputValue
    ? `&searchColumn=${params?.filterData?.selectColumn}&searchValue=${params?.filterData?.inputValue}`
    : "";

  return api.get(
    `/storelist/get/?pageNumber=${params?.pageNumber}&pageSize=${params?.pageSize}&sortBy=${params?.filterData?.sort_by}&sortOrder=${params?.filterData?.sort_order}${searchParams}`,
    JSON.stringify(params)
  );
};

export const storeViewApi = (params) => {
  return api.get(
    `/store/getById?store_id=${params}`,
    JSON.stringify(params)
  );
};
