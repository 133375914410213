import { Menu, Transition } from "@headlessui/react";
import { Fragment} from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

export default function SelectOption({
  options = [],
  handleSelect = () => {},
}) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center whitespace-nowrap rounded-md bg-primary px-4 py-[10px] text-sm font-medium text-white hover:bg-black/30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
          Create to
          <ChevronDownIcon
            className="-mr-1 ml-2 h-5 w-5 text-white text-opacity-70 hover:text-white-100"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-787878 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none z-50">
          <div className="px-1 py-1 ">
            {options?.map((item) => (
              <Menu.Item key={item.id}>
                {({ active }) => (
                  <button
                    onClick={() => handleSelect(item)}
                    className={`${
                      active ? "bg-primary text-white" : "text-787878"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  >
                    {item?.label}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
