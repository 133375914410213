import React, { useEffect, useState } from "react";
import InnerLayout from "../../../components/layouts/inner-layout";
import CreateEditViewStockTransfer from "../../../components/inventory/stock-transfer/create-edit-view-stock-transfer";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { stockTransferViewAction } from "../../../redux/actions/inventory-action";
import LoadingSpinner from "../../../components/helper-components/loading-spinner";
import Select from "react-select";

const AddStoreTransfer = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const sti = searchParams.get("transferId") || "";
  const [loading, setLoading] = useState(false);
  const [stockTransferDetails, setStoreTransferDetails] = useState({});
  useEffect(() => {
    if (sti) {
      setLoading(true);
      dispatch(stockTransferViewAction(sti, onSuccess, onError));
    }
  }, []);

  const onSuccess = (data) => {
    setLoading(false);
    const modifiedData = {
      ...data?.data,
      itemData: data?.data?.itemData.map((item) => {
        const { itemBatchData, ...rest } = item;
        return rest;
      }),
    };

    setStoreTransferDetails(modifiedData);
  };
  const onError = (data) => {
    setLoading(false);
  };

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const customStyles = {
    control: (base) => ({
      ...base,
      backgroundColor: "#f4f4f4",
    }),
    option: (base) => ({
      ...base,
      color: "#333",
    }),
  };
  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <InnerLayout backBtn={true} title="Stock Management">
          <CreateEditViewStockTransfer
            stockTransferDetails={stockTransferDetails}
          />
          {/* <Select options={options} styles={customStyles} /> */}
        </InnerLayout>
      )}
    </>
  );
};

export default AddStoreTransfer;
