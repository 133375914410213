import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import AddButton from "../../buttons/button";
import InputField from "../../forms/input-field";
import TabsComponent from "../../helper-components/tabs-component";
import MapsAndPhotos from "./maps-photos";
import StoreShopAddressTab from "./shop-address";
import StoreDetailsTab from "./store-details";
import StoreRemarksTab from "./remarks";
import { useDispatch } from "react-redux";
import {
  StoreAddressCreateAction,
  StoreAddressUpdateAction,
} from "../../../redux/actions/store-action";
import successToast from "../../helper-components/success-toast";
import errorToast from "../../helper-components/error-toast";

const StoreAddressTab = ({
  setId,
  setStoreData,
  storeData,
  initialValue,
  id,
  disabled = false,
}) => {
  const [currentTab, setCurrentTab] = useState("Shop Address");
  const dispatch = useDispatch();
  const initialValues = {
    ...(id && { store_id: id }), // Include store_id only if id is present
    store_type: initialValue?.store_type ?? "",
    relationship_manager: initialValue?.relationship_manager ?? "",
    reapproval_remarks: initialValue?.reapproval_remarks ?? "",
    addres_remarks: initialValue?.addres_remarks ?? "",
    shop_address: initialValue?.shop_address ?? "",
    shop_area: initialValue?.shop_area ?? "",
    shop_landmark: initialValue?.shop_landmark ?? "",
    shop_town_or_village: initialValue?.shop_town_or_village ?? "",
    shop_city: initialValue?.shop_city ?? "",
    shop_state: initialValue?.shop_state ?? "",
    shop_pin_code: initialValue?.shop_pin_code ?? "",
    shop_country_region: initialValue?.shop_country_region ?? "india",
    store_rent_per_month: initialValue?.store_rent_per_month ?? null,
    store_duration_concurrently:
      initialValue?.store_duration_concurrently ?? "",
    store_sale_per_day: initialValue?.store_sale_per_day ?? null,
    store_medicine_sale: initialValue?.store_medicine_sale ?? null,
    store_otc_sale: initialValue?.store_otc_sale ?? null,
    store_latitude: initialValue?.store_latitude ?? "",
    store_longitude: initialValue?.store_longitude ?? "",
    shop_images: initialValue?.shop_images ?? [],
    tab_remarks: initialValue?.tab_remarks ?? "",
  };
  const validationSchema = Yup.object().shape({
    store_type: Yup.string().required("Store Type is required"),
    relationship_manager: Yup.string().required(
      "Relationship Manager is required"
    ),
    shop_address: Yup.string().required("Shop Address is required"),
    shop_area: Yup.string().required("Shop Area is required"),
    shop_landmark: Yup.string().required("Shop Landmark is required"),
    shop_town_or_village: Yup.string().required("Town or Village is required"),
    shop_city: Yup.string().required("City is required"),
    shop_state: Yup.string().required("State is required"),
    shop_pin_code: Yup.string()
      .required("Pin Code is required")
      .matches(/^[1-9][0-9]{5}$/, "Invalid Pin Code"),
    shop_country_region: Yup.string().required("Country/Region is required"),
    store_rent_per_month: Yup.number().required("Rent Per Month is required"),
    store_duration_concurrently: Yup.string().required(
      "Store Duration Concurrently is required"
    ),
    store_sale_per_day: Yup.number().required("Store Sale Per Day is required"),
    store_medicine_sale: Yup.number().required("Medicine Sale is required"),
    store_otc_sale: Yup.number().required("OTC Sale is required"),
    store_latitude: Yup.string().required("Store Latitude is required"),
    store_longitude: Yup.string().required("Store Longitude is required"),
    shop_images: Yup.array()
      .min(2, "At least 2 images are required")
      .required("At least 2 images are required"),
  });

  const onSubmit = (values, actions) => {
    let actionType = StoreAddressCreateAction;
    if (id) {
      actionType = StoreAddressUpdateAction;
    }
    dispatch(actionType(values, onSuccess, onError));
  };

  const onSuccess = (data) => {
    setStoreData({
      ...storeData,
      address: data?.data,
    });
    successToast(data?.data?.message);
    setId(data?.data?.store_id);
  };
  const onError = (data) => {
    errorToast(data?.data?.message);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, isSubmitting, errors }) => {
        
        return (
          <Form style={{ height: "calc(100% - 5rem)" }} className="pt-5">
            <div className="flex flex-col justify-between h-full ">
              <div>
                <div className="w-full 2xl:w-[35%] lg:w-[60%] px-5 xl:px-10">
                  <InputField
                    isDisabled={disabled}
                    type="text"
                    name="store_type"
                    placeholder="Store Type"
                    label="Store Type"
                    isRequired={true}
                    labelWidth="10.5rem"
                  />
                  <InputField
                    isDisabled={disabled}

                    type="text"
                    name="relationship_manager"
                    placeholder=""
                    label="Relationship Manager"
                    isRequired={true}
                    labelWidth="10.5rem"
                  />
                  <InputField
                    isDisabled={disabled}

                    type="text"
                    name="reapproval_remarks"
                    placeholder=""
                    label="Re-approval Remarks"
                    labelWidth="10.5rem"
                  />
                  <InputField
                    isDisabled={disabled}

                    type="text"
                    name="addres_remarks"
                    placeholder=""
                    label="Remarks"
                    labelWidth="10.5rem"
                  />
                </div>
                <TabsComponent
                  handleClickfn={(item) => {
                    setCurrentTab(item);
                  }}
                  itemTabs={itemTabs}
                  setCurrentTab={setCurrentTab}
                  currentTab={currentTab}
                />
                {currentTab === "Shop Address" && <StoreShopAddressTab disabled={disabled} />}
                {currentTab === "Store Details" && <StoreDetailsTab disabled={disabled}  />}{" "}
                {currentTab === "Maps & Photos" && <MapsAndPhotos disabled={disabled}  />}
                {currentTab === "Remarks" && <StoreRemarksTab disabled={disabled}  />}
              </div>
              {!disabled && (
                <div
                  style={{
                    bottom: "0",
                  }}
                  className="flex justify-end px-10 mb-5x sticky bg-white py-4 invoice-bottom-container"
                >
                  <AddButton
                    onclkFn={() => {
                      if (Object.keys(errors).length !== 0) {
                        const currentIndex = itemTabs.indexOf(currentTab);
                        let nextTab;

                        if (currentIndex !== -1) {
                          // Go to the next tab based on the current tab index
                          nextTab =
                            currentIndex === itemTabs.length - 1
                              ? itemTabs[0] // Loop back to the first tab if the last tab is reached
                              : itemTabs[currentIndex + 1];

                          setCurrentTab(nextTab);
                        }
                      }
                    }}
                    prefixIcon={false}
                    text="Save"
                    type="submit"
                  />
                </div>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default StoreAddressTab;
const itemTabs = ["Shop Address", "Store Details", "Maps & Photos", "Remarks"];
