import { Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import KibabMenu from "../../../../assets/icons/kibab-menu";
import { useDispatch } from "react-redux";
import {
  addItemTypeAction,
  deleteItemTypeAction,
  itemTypeListAction,
  updateItemTypeAction,
} from "../../../../redux/actions/settings-action";
import errorToast from "../../../helper-components/error-toast";
import DeleteIcon from "../../../../assets/icons/delete-icon";
import successToast from "../../../helper-components/success-toast";
import AddButton from "../../../buttons/button";
import LoadingSpinner from "../../../helper-components/loading-spinner";

const ItemTypeTable = ({ searchValue }) => {
  const tableHeading = ["Type Code", "Type Name", ""];
  const [disableArrayFields, setDisableArrayFields] = useState(false);
  const [disableNewFields, setDisableNewFields] = useState(false);
  const [selectedDOtIndex, setSelectedDOtIndex] = useState(null);
  const [initialData, setItemTypeData] = useState([]);
  // const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleFieldChange = (index, field, value, setFieldValue) => {
    setFieldValue(`${index}.${field}`, value);
  };

  useEffect(() => {
    dispatch(
      itemTypeListAction({}, onFetchItemTypeSuccess, onFetchItemTypeError)
    );
    setLoading(true);
  }, []);

  useEffect(() => {
    dispatch(
      itemTypeListAction(
        { searchValue },
        onFetchItemTypeSuccess,
        onFetchItemTypeError
      )
    );
    setLoading(true);
  }, [searchValue]);

  const reloadData = () => {
    dispatch(
      itemTypeListAction({}, onFetchItemTypeSuccess, onFetchItemTypeError)
    );
    setLoading(true);
  };

  const onFetchItemTypeSuccess = (data) => {
    setItemTypeData(data?.data?.itemTypeList);
    setLoading(false);
  };

  const onFetchItemTypeError = (data) => {
    setLoading(false);
    setItemTypeData([]);
    errorToast(data?.message);
  };

  const initialValues = {
    data: initialData,
    newLine: {
      code: "",
      name: "",
    },
  };

  const onSubmit = (values, { setSubmitting }) => {
    const changedObjects = [];
    let newLineObject = null;

    // Collect changed array data
    initialData.forEach((item, index) => {
      if (JSON.stringify(values.data[index]) !== JSON.stringify(item)) {
        changedObjects.push(values.data[index]);
      }
    });

    // Collect new line data if it is altered
    if (
      JSON.stringify(values.newLine) !== JSON.stringify(initialValues.newLine)
    ) {
      newLineObject = values.newLine;
    }

    if (newLineObject !== null) {
      dispatch(
        addItemTypeAction(newLineObject, onCreateSuccess, onCreateError)
      );
    }

    if (changedObjects.length > 0) {
      dispatch(
        updateItemTypeAction(
          { updatingData: changedObjects },
          onUpdateSuccess,
          onUpdateError
        )
      );
    }
    setSubmitting(false);
  };

  const onCreateSuccess = (data) => {
    reloadData();
    setDisableArrayFields(false);
    setDisableNewFields(false);
    successToast(data?.message);
    setSelectedDOtIndex(null);
  };
  const onCreateError = (data) => {
    setDisableArrayFields(false);
    setDisableNewFields(false);
    errorToast(data?.message);
    setSelectedDOtIndex(null);
  };

  const onUpdateSuccess = (data) => {
    reloadData();
    setDisableArrayFields(false);
    setDisableNewFields(false);
    successToast(data?.message);
    setSelectedDOtIndex(null);
  };
  const onUpdateError = (data) => {
    setDisableArrayFields(false);
    setDisableNewFields(false);
    errorToast(data?.message);
    setSelectedDOtIndex(null);
  };

  const componentRef = useRef(null);
  const handleClickOutside = (event) => {
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      setSelectedDOtIndex(null);
    }
  };

  const onClickDelete = (id) => {
    dispatch(deleteItemTypeAction({ id }, onDeletSuccess, onError));
  };
  const onDeletSuccess = (data) => {
    reloadData();
    successToast(data?.message);
    selectedDOtIndex(null);
  };

  const onError = (data) => {
    errorToast(data?.message);
    selectedDOtIndex(null);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, dirty, handleSubmit }) => (
        <Form className="pt-1">
          <div
            onClick={handleClickOutside}
            className="flex flex-col justify-between"
            style={{ height: "80vh" }}
          >
            {loading ? (
              <LoadingSpinner />
            ) : (
              <div className="overflow-auto">
                <table className="mt-1 w-full">
                  <thead className="sticky top-0 z-10 bg-F2F4F5">
                    <tr>
                      {tableHeading.map((heading, index) => (
                        <th
                          className={`settings-table-header whitespace-nowrap fs_14 fw_500 text-475467 bg-F2F4F5 text-start`}
                          key={heading}
                        >
                          {heading}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody style={{ position: "relative" }}>
                    {values.data.map((item, index) => (
                      <tr key={index}>
                        <td
                          className={`setting-table-cell fs_14 fw_400 text-secondary w-3/12`}
                        >
                          <Field
                            name={`data[${index}].code`}
                            placeholder="Code"
                            disabled={disableArrayFields}
                            onChange={(e) =>
                              handleFieldChange(
                                `data[${index}]`,
                                "code",
                                e.target.value,
                                setFieldValue
                              )
                            }
                            className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded px-1 py-2"
                          />
                        </td>
                        <td
                          className={`setting-table-cell fs_14 fw_400 text-secondary`}
                        >
                          <Field
                            name={`data[${index}].name`}
                            placeholder="Name"
                            disabled={disableArrayFields}
                            onChange={(e) =>
                              handleFieldChange(
                                `data[${index}]`,
                                "name",
                                e.target.value,
                                setFieldValue
                              )
                            }
                            className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded py-2"
                          />
                        </td>
                        <td className="setting-table-cell fs_14 fw_400 text-secondary p-8">
                          <button onClick={() => setSelectedDOtIndex(index)}>
                            <KibabMenu />
                          </button>
                          {selectedDOtIndex === index && (
                            <div
                              ref={componentRef}
                              style={{
                                position: "absolute",
                                right: "2rem",
                                boxShadow:
                                  "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                              }}
                              className="bg-white rounded w-38"
                            >
                              <div className="w-full p-3">
                                <button
                                  className="w-full flex align-middle gap-2 px-2 py-1"
                                  onClick={() => onClickDelete(item?.id)}
                                >
                                  <div className="mt-1">
                                    <DeleteIcon />
                                  </div>
                                  <span className="fs_16">Delete</span>
                                </button>
                              </div>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td
                        className={`setting-table-cell fs_14 fw_400 text-secondary`}
                      >
                        <Field
                          name="newLine.code"
                          placeholder="Code"
                          disabled={disableNewFields}
                          onChange={(e) =>
                            handleFieldChange(
                              "newLine",
                              "code",
                              e.target.value,
                              setFieldValue
                            )
                          }
                          className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded py-2"
                          autoFocus={searchValue ? false : true}
                        />
                      </td>
                      <td
                        className={`setting-table-cell fs_14 fw_400 text-secondary`}
                      >
                        <Field
                          name="newLine.name"
                          placeholder="Name"
                          disabled={disableNewFields}
                          onChange={(e) =>
                            handleFieldChange(
                              "newLine",
                              "name",
                              e.target.value,
                              setFieldValue
                            )
                          }
                          className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded py-2"
                        />
                      </td>
                      <td
                        className={`setting-table-cell fs_14 fw_400 text-secondary`}
                      ></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}

            <div className="flex justify-end w-full bg-white py-4 px-6 invoice-bottom-container mt-6">
              <AddButton
                onclkFn={handleSubmit}
                prefixIcon={false}
                text="Save"
                type="submit"
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ItemTypeTable;
