import React from "react";

const PeriodDisUnCheckbox = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="white" />
      <rect x="0.5" y="0.5" width="17" height="17" rx="3.5" stroke="#D0D5DD" />
    </svg>
  );
};

export default PeriodDisUnCheckbox;

export const PeriodDisCheckbox = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#F9F5FF" />
      <rect x="0.5" y="0.5" width="17" height="17" rx="3.5" stroke="#0788F5" />
      <path
        d="M13.5 5.625L7.3125 11.8125L4.5 9"
        stroke="#0788F5"
        strokeWidth="1.6666"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
