import {
  PURCHASE_CREATE,
  PURCHASE_PRODUCT_LIST,
  PURCHASE_TABLE,
  PURCHASE_VIEW,
  GOODS_ITEMS_GET,
  GOODS_RECEIPT_CREATE,
  GOODS_TABLE,
  GOODS_RECEIPT_VIEW,
  PURCHASE_CREDIT_ITEMS_GET,
  PURCHASE_CREDIT_VIEW,
  PURCHASE_CREDIT_TABLE,
  PURCHASE_CREDIT_RECEIPT_CREATE,
  PURCHASE_RETURN_ITEMS_GET,
  PURCHASE_RETURN_VIEW,
  PURCHASE_RETURN_TABLE,
  PURCHASE_RETURN_RECEIPT_CREATE,
  PURCHASE_INVOICE_ITEMS_GET,
  PURCHASE_INVOICE_VIEW,
  PURCHASE_INVOICE_TABLE,
  PURCHASE_INVOICE_RECEIPT_CREATE,
  PURCHASE_EDIT,
  PURCHASE_ITEMS_GET,
  PURCHASE_VOID,
  GOODS_RECEIPT_EDIT,
  GOODS_RECEIPT_VOID,
  PURCHASE_CREDIT_EDIT,
  PURCHASE_CREDIT_VOID,
  PURCHASE_RETURN_VOID,
  PURCHASE_RETURN_EDIT,
  PURCHASE_INVOICE_EDIT,
  PURCHASE_INVOICE_VOID,
  PAYMENT_OUT_TABLE,
  PAYMENT_OUT_CREATE,
  PAYMENT_OUT_VIEW,
  PURCHASE_PAYMENT_OUT_CUS,
  LOAD_MIN_QTY_ITEMS,
  BULK_PURCHASE_CREATE,
  ADD_MIN_QTY_ITEMS,
  UPLOAD_PO_CSV,
} from "../types";

export const purchaseCreateAction = (data, onSuccess, onError) => {
  return {
    type: PURCHASE_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const purchaseTableAction = (data, onSuccess, onError) => {
  return {
    type: PURCHASE_TABLE,
    data,
    onSuccess,
    onError,
  };
};
export const purchaseProductListAction = (data, onSuccess, onError) => {
  return {
    type: PURCHASE_PRODUCT_LIST,
    data,
    onSuccess,
    onError,
  };
};
export const purchaseViewAction = (data, onSuccess, onError) => {
  return {
    type: PURCHASE_VIEW,
    data,
    onSuccess,
    onError,
  };
};
export const purchaseEditAction = (data, onSuccess, onError) => {
  return {
    type: PURCHASE_EDIT,
    data,
    onSuccess,
    onError,
  };
};

export const purchaseVoidAction = (data, onSuccess, onError) => {
  return {
    type: PURCHASE_VOID,
    data,
    onSuccess,
    onError,
  };
};

export const purchaseItemsGetAction = (data, onSuccess, onError) => {
  return {
    type: PURCHASE_ITEMS_GET,
    data,
    onSuccess,
    onError,
  };
};

export const goodsReceiptCreateAction = (data, onSuccess, onError) => {
  return {
    type: GOODS_RECEIPT_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const goodsTableAction = (data, onSuccess, onError) => {
  return {
    type: GOODS_TABLE,
    data,
    onSuccess,
    onError,
  };
};
export const goodsItemsGetAction = (data, onSuccess, onError) => {
  return {
    type: GOODS_ITEMS_GET,
    data,
    onSuccess,
    onError,
  };
};

export const goodsViewAction = (data, onSuccess, onError) => {
  return {
    type: GOODS_RECEIPT_VIEW,
    data,
    onSuccess,
    onError,
  };
};
export const goodsEditAction = (data, onSuccess, onError) => {
  return {
    type: GOODS_RECEIPT_EDIT,
    data,
    onSuccess,
    onError,
  };
};
export const goodsVoidAction = (data, onSuccess, onError) => {
  return {
    type: GOODS_RECEIPT_VOID,
    data,
    onSuccess,
    onError,
  };
};

export const purchaseInvoiceReceiptCreateAction = (
  data,
  onSuccess,
  onError
) => {
  return {
    type: PURCHASE_INVOICE_RECEIPT_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const purchaseInvoiceTableAction = (data, onSuccess, onError) => {
  return {
    type: PURCHASE_INVOICE_TABLE,
    data,
    onSuccess,
    onError,
  };
};

export const purchaseInvoiceViewAction = (data, onSuccess, onError) => {
  return {
    type: PURCHASE_INVOICE_VIEW,
    data,
    onSuccess,
    onError,
  };
};
export const purchaseInvoiceVoidAction = (data, onSuccess, onError) => {
  return {
    type: PURCHASE_INVOICE_VOID,
    data,
    onSuccess,
    onError,
  };
};
export const purchaseInvoiceEditAction = (data, onSuccess, onError) => {
  return {
    type: PURCHASE_INVOICE_EDIT,
    data,
    onSuccess,
    onError,
  };
};
export const purchaseInvoiceItemsGetAction = (data, onSuccess, onError) => {
  return {
    type: PURCHASE_INVOICE_ITEMS_GET,
    data,
    onSuccess,
    onError,
  };
};

export const purchaseReturnReceiptCreateAction = (data, onSuccess, onError) => {
  return {
    type: PURCHASE_RETURN_RECEIPT_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const purchaseReturnTableAction = (data, onSuccess, onError) => {
  return {
    type: PURCHASE_RETURN_TABLE,
    data,
    onSuccess,
    onError,
  };
};

export const purchaseReturnViewAction = (data, onSuccess, onError) => {
  return {
    type: PURCHASE_RETURN_VIEW,
    data,
    onSuccess,
    onError,
  };
};
export const purchaseReturnEditAction = (data, onSuccess, onError) => {
  return {
    type: PURCHASE_RETURN_EDIT,
    data,
    onSuccess,
    onError,
  };
};
export const purchaseReturnVoidAction = (data, onSuccess, onError) => {
  return {
    type: PURCHASE_RETURN_VOID,
    data,
    onSuccess,
    onError,
  };
};
export const purchaseReturnItemsGetAction = (data, onSuccess, onError) => {
  return {
    type: PURCHASE_RETURN_ITEMS_GET,
    data,
    onSuccess,
    onError,
  };
};

export const purchaseCreditReceiptCreateAction = (data, onSuccess, onError) => {
  return {
    type: PURCHASE_CREDIT_RECEIPT_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const purchaseCreditTableAction = (data, onSuccess, onError) => {
  return {
    type: PURCHASE_CREDIT_TABLE,
    data,
    onSuccess,
    onError,
  };
};

export const purchaseCreditViewAction = (data, onSuccess, onError) => {
  return {
    type: PURCHASE_CREDIT_VIEW,
    data,
    onSuccess,
    onError,
  };
};
export const purchaseCreditEditAction = (data, onSuccess, onError) => {
  return {
    type: PURCHASE_CREDIT_EDIT,
    data,
    onSuccess,
    onError,
  };
};
export const purchaseCreditVoidAction = (data, onSuccess, onError) => {
  return {
    type: PURCHASE_CREDIT_VOID,
    data,
    onSuccess,
    onError,
  };
};
export const purchaseCreditItemsGetAction = (data, onSuccess, onError) => {
  return {
    type: PURCHASE_CREDIT_ITEMS_GET,
    data,
    onSuccess,
    onError,
  };
};

export const purchasePaymentOutCusAction = (data, onSuccess, onError) => {
  return {
    type: PURCHASE_PAYMENT_OUT_CUS,
    data,
    onSuccess,
    onError,
  };
};

export const paymentOutTableAction = (data, onSuccess, onError) => {
  return {
    type: PAYMENT_OUT_TABLE,
    data,
    onSuccess,
    onError,
  };
};

export const paymentOutCreateAction = (data, onSuccess, onError) => {
  return {
    type: PAYMENT_OUT_CREATE,
    data,
    onSuccess,
    onError,
  };
};

export const paymentOutViewAction = (data, onSuccess, onError) => {
  return {
    type: PAYMENT_OUT_VIEW,
    data,
    onSuccess,
    onError,
  };
};

//BULK PURCHASE
export const loadMinQtyItemsAction = (data, onSuccess, onError) => {
  return {
    type: LOAD_MIN_QTY_ITEMS,
    data,
    onSuccess,
    onError,
  };
};
export const bulkPurchaseCreateAction = (data, onSuccess, onError) => {
  return {
    type: BULK_PURCHASE_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const uploadPoCsvAction = (data, onSuccess, onError) => {
  return {
    type: UPLOAD_PO_CSV,
    data,
    onSuccess,
    onError,
  };
};
