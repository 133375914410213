import {
  GL_ACC_LIST,
  PAYMENT_IN_CREATE,
  PAYMENT_IN_TABLE,
  PAYMENT_IN_VIEW,
  SALES_CREATE,
  SALES_CREDIT_EDIT,
  SALES_CREDIT_RECEIPT_CREATE,
  SALES_CREDIT_TABLE,
  SALES_CREDIT_VIEW,
  SALES_CREDIT_VOID,
  SALES_DELIVERY_EDIT,
  SALES_DELIVERY_ITEMS_GET,
  SALES_DELIVERY_RECEIPT_CREATE,
  SALES_DELIVERY_TABLE,
  SALES_DELIVERY_VIEW,
  SALES_DELIVERY_VOID,
  SALES_EDIT,
  SALES_INVOICE_EDIT,
  SALES_INVOICE_ITEMS_GET,
  SALES_INVOICE_RECEIPT_CREATE,
  SALES_INVOICE_TABLE,
  SALES_INVOICE_VIEW,
  SALES_INVOICE_VOID,
  SALES_ORDER_ITEMS_GET,
  SALES_PAYMENT_IN_CUS,
  SALES_PRODUCT_LIST,
  SALES_RETURN_EDIT,
  SALES_RETURN_ITEMS_GET,
  SALES_RETURN_RECEIPT_CREATE,
  SALES_RETURN_TABLE,
  SALES_RETURN_VIEW,
  SALES_RETURN_VOID,
  SALES_TABLE,
  SALES_VIEW,
  SALES_VOID,
} from "../types";

export const salesCreateAction = (data, onSuccess, onError) => {
  return {
    type: SALES_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const salesEditAction = (data, onSuccess, onError) => {
  return {
    type: SALES_EDIT,
    data,
    onSuccess,
    onError,
  };
};
export const salesTableAction = (data, onSuccess, onError) => {
  return {
    type: SALES_TABLE,
    data,
    onSuccess,
    onError,
  };
};
export const salesProductListAction = (data, onSuccess, onError) => {
  return {
    type: SALES_PRODUCT_LIST,
    data,
    onSuccess,
    onError,
  };
};
export const salesOrderItemsGetAction = (data, onSuccess, onError) => {
  return {
    type: SALES_ORDER_ITEMS_GET,
    data,
    onSuccess,
    onError,
  };
};
export const salesViewAction = (data, onSuccess, onError) => {
  return {
    type: SALES_VIEW,
    data,
    onSuccess,
    onError,
  };
};
export const salesVoidAction = (data, onSuccess, onError) => {
  return {
    type: SALES_VOID,
    data,
    onSuccess,
    onError,
  };
};

export const salesDeliveryReceiptCreateAction = (data, onSuccess, onError) => {
  return {
    type: SALES_DELIVERY_RECEIPT_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const salesDeliveryTableAction = (data, onSuccess, onError) => {
  return {
    type: SALES_DELIVERY_TABLE,
    data,
    onSuccess,
    onError,
  };
};

export const salesDeliveryViewAction = (data, onSuccess, onError) => {
  return {
    type: SALES_DELIVERY_VIEW,
    data,
    onSuccess,
    onError,
  };
};
export const salesDeliveryEditAction = (data, onSuccess, onError) => {
  return {
    type: SALES_DELIVERY_EDIT,
    data,
    onSuccess,
    onError,
  };
};
export const salesDeliveryVoidAction = (data, onSuccess, onError) => {
  return {
    type: SALES_DELIVERY_VOID,
    data,
    onSuccess,
    onError,
  };
};
export const salesDeliveryItemsGetAction = (data, onSuccess, onError) => {
  return {
    type: SALES_DELIVERY_ITEMS_GET,
    data,
    onSuccess,
    onError,
  };
};

export const salesInvoiceReceiptCreateAction = (data, onSuccess, onError) => {
  return {
    type: SALES_INVOICE_RECEIPT_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const salesInvoiceTableAction = (data, onSuccess, onError) => {
  return {
    type: SALES_INVOICE_TABLE,
    data,
    onSuccess,
    onError,
  };
};

export const salesInvoiceViewAction = (data, onSuccess, onError) => {
  return {
    type: SALES_INVOICE_VIEW,
    data,
    onSuccess,
    onError,
  };
};
export const salesInvoiceVoidAction = (data, onSuccess, onError) => {
  return {
    type: SALES_INVOICE_VOID,
    data,
    onSuccess,
    onError,
  };
};
export const salesInvoiceEditAction = (data, onSuccess, onError) => {
  return {
    type: SALES_INVOICE_EDIT,
    data,
    onSuccess,
    onError,
  };
};
export const salesInvoiceItemsGetAction = (data, onSuccess, onError) => {
  return {
    type: SALES_INVOICE_ITEMS_GET,
    data,
    onSuccess,
    onError,
  };
};

export const salesReturnReceiptCreateAction = (data, onSuccess, onError) => {
  return {
    type: SALES_RETURN_RECEIPT_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const salesReturnTableAction = (data, onSuccess, onError) => {
  return {
    type: SALES_RETURN_TABLE,
    data,
    onSuccess,
    onError,
  };
};

export const salesReturnViewAction = (data, onSuccess, onError) => {
  return {
    type: SALES_RETURN_VIEW,
    data,
    onSuccess,
    onError,
  };
};
export const salesReturnEditAction = (data, onSuccess, onError) => {
  return {
    type: SALES_RETURN_EDIT,
    data,
    onSuccess,
    onError,
  };
};
export const salesReturnVoidAction = (data, onSuccess, onError) => {
  return {
    type: SALES_RETURN_VOID,
    data,
    onSuccess,
    onError,
  };
};
export const salesReturnItemsGetAction = (data, onSuccess, onError) => {
  return {
    type: SALES_RETURN_ITEMS_GET,
    data,
    onSuccess,
    onError,
  };
};

export const salesCreditReceiptCreateAction = (data, onSuccess, onError) => {
  return {
    type: SALES_CREDIT_RECEIPT_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const salesCreditTableAction = (data, onSuccess, onError) => {
  return {
    type: SALES_CREDIT_TABLE,
    data,
    onSuccess,
    onError,
  };
};

export const salesCreditViewAction = (data, onSuccess, onError) => {
  return {
    type: SALES_CREDIT_VIEW,
    data,
    onSuccess,
    onError,
  };
};
export const salesCreditEditAction = (data, onSuccess, onError) => {
  return {
    type: SALES_CREDIT_EDIT,
    data,
    onSuccess,
    onError,
  };
};
export const salesCreditVoidAction = (data, onSuccess, onError) => {
  return {
    type: SALES_CREDIT_VOID,
    data,
    onSuccess,
    onError,
  };
};
export const salesPaymentInCusAction = (data, onSuccess, onError) => {
  return {
    type: SALES_PAYMENT_IN_CUS,
    data,
    onSuccess,
    onError,
  };
};

export const paymentInTableAction = (data, onSuccess, onError) => {
  return {
    type: PAYMENT_IN_TABLE,
    data,
    onSuccess,
    onError,
  };
};

export const glAccListAction = (onSuccess, onError) => {
  return {
    type: GL_ACC_LIST,
    onSuccess,
    onError,
  };
};

export const paymentInCreateAction = (data, onSuccess, onError) => {
  return {
    type: PAYMENT_IN_CREATE,
    data,
    onSuccess,
    onError,
  };
};

export const paymentInViewAction = (data, onSuccess, onError) => {
  return {
    type: PAYMENT_IN_VIEW,
    data,
    onSuccess,
    onError,
  };
};
