import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import SelectOptions from "../forms/select-options";
import FilterRadioButtons from "../forms/filter-radio-buttons";
import { formatYAxisTick } from "../helper-components/helper-function";
const BarGraph = ({
  setSelectedOption,
  selectedOption,
  options,
  barGraphData,
  yCoordinate = "No. of product sold",
}) => {
  const handleChange = (option) => {
    setSelectedOption({ ...selectedOption, barGraph: option?.value });
  };

  const handlecustomerStatusChange = (e) => {
    setSelectedOption({
      ...selectedOption,
      barRadio: e.target.value,
    });
  };
  const primaryColor = "var(--color-primary)";
  return (
    <div className="border border-EAECF0 text-000000">
      <div className="flex justify-between items-center px-8 flex-wrap">
        <span className="text-000000 fw_600 fs_16 text-nowrap ">
          Top Selling Products
        </span>
        <div className="flex items-center gap-3 flex-wrap">
          <FilterRadioButtons
            className="flex gap-4 "
            name="customer_status"
            label={""}
            options={itemsSoldOptions}
            handleChange={handlecustomerStatusChange}
            value={selectedOption?.barRadio}
          />
          <div className=" flex items-center gap-2">
            <div className="w-[8rem]">
              <SelectOptions
                value={selectedOption?.value}
                handleChange={handleChange}
                options={options}
              />
            </div>
            {selectedOption?.barGraph === "custom_date" && (
              <div className="flex items-center border-D0D5DD border-2 py-1 px-1 rounded-md gap-1">
                <input
                  className="fs_14"
                  value={selectedOption?.barGraphDate?.from}
                  onChange={(e) => {
                    const fromDate = e.target.value;
                    setSelectedOption({
                      ...selectedOption,
                      barGraphDate: {
                        ...selectedOption.barGraphDate,
                        from: fromDate,
                      },
                    });
                  }}
                  type="date"
                />
                -
                <input
                  className="fs_14"
                  value={selectedOption?.barGraphDate?.to}
                  onChange={(e) => {
                    const toDate = e.target.value;
                    setSelectedOption({
                      ...selectedOption,
                      barGraphDate: {
                        ...selectedOption.barGraphDate,
                        to: toDate,
                      },
                    });
                  }}
                  type="date"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <ResponsiveContainer width="100%" height={410}>
        <BarChart
          width={600}
          height={300}
          data={barGraphData}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid stroke="#EAECF0" strokeDasharray="0 0" />{" "}
          <XAxis tick={{ fontSize: 11 }} dataKey="name" />
          <YAxis tick={{ fontSize: 11 }} tickFormatter={formatYAxisTick} />
          <Tooltip />
          <Bar barSize={35} barWidth={2} dataKey="Sales" fill={primaryColor} />
        </BarChart>
      </ResponsiveContainer>
      <div className="flex justify-center items-center p-5 text-000000 fw_400 fs_14">
        <span>X-Product Name, Y-{yCoordinate}</span>
      </div>
    </div>
  );
};

export default BarGraph;

const itemsSoldOptions = [
  { label: "Quantity", value: "quantity" },
  { label: "Revenue", value: "revenue" },
];
