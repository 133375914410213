import { Field, useFormikContext } from "formik";
import React from "react";
import SelectField from "../../../components/forms/select-field";
import AddButton from "../../../components/buttons/button";
import DeleteIcon from "../../../assets/icons/delete-icon";
import { api } from "../../../redux/apis/api";
import { url } from "../../../redux/config";

const BatchQuantityExpiryPopover = ({
  setAddbatch,
  itemId,
  setItemId,
  binLocationList = [],
}) => {
  const { values, setFieldValue } = useFormikContext();
  const defaultSelectedItem = values?.data?.find(
    (item, index) => itemId === item?.item_id
  );
  const defaultSelectedItemIndex = values?.data?.findIndex(
    (item, index) => itemId === item?.item_id
  );
  const initData = {
    item_id: defaultSelectedItem?.item_id,
    item_batch_number: "",
    item_sellable_quantity: null,
    item_non_sellable_quantity: 0,
    item_exp_date: new Date(),
    item_mfg_date: "2023/12/02",
    from_bin_location: "",
    to_bin_location: "receiving-bin",
    to_bin_id: binLocationList?.find(
      (bin) => bin?.actualLabel === "receiving-bin"
    )?.value,
    from_bin_id: "",
  };

  const handleQuantityChange = async (rowIndex, batchIndex, qty, del) => {
    let updatedData = [...values.data];

    if (del) {
      const updatedBatches = [...updatedData[rowIndex].item_batches];
      updatedBatches.splice(batchIndex, 1);
      updatedData[rowIndex].item_batches = updatedBatches;
    } else {
      updatedData = updatedData.map((row, index) => {
        if (index === rowIndex) {
          const updatedItemBatches = row.item_batches.map((batch, idx) => {
            if (idx === batchIndex) {
              return {
                ...batch,
                item_sellable_quantity: parseFloat(qty),
              };
            } else {
              return batch;
            }
          });
          return { ...row, item_batches: updatedItemBatches };
        } else {
          return row;
        }
      });
    }

    setFieldValue("data", updatedData);

    const itemBatches = updatedData[rowIndex].item_batches;
    let sumQuantity = 0;

    for (let i = 0; i < itemBatches.length; i++) {
      sumQuantity += parseFloat(itemBatches[i].item_sellable_quantity) || 0;
    }

    try {
      const response = await api.get(
        `${url}/discount/get?totalAmountOfItem=${
          sumQuantity * parseFloat(values?.data?.[rowIndex]?.item_unit_price)
        }&item_quantity=${sumQuantity}&item_id=${
          values?.data?.[rowIndex]?.item_id
        }&cmr_phone_number=${values?.cmr_phone_number}`
      );

      const discount =
        response.data?.code === 200
          ? response.data.data.item_discount_amount
          : 0;
      const tax =
        response.data?.code === 200 ? response.data.data.item_tax_amount : 0;
      const item_discount_percentage =
        response.data?.code === 200
          ? response.data.data.item_discount_percentage
          : 0;

      const updatedDataRow = {
        ...updatedData[rowIndex],
        quantity: sumQuantity,
        item_discount_amount: discount,
        item_tax_amount: tax,
        item_discount_percentage: item_discount_percentage,
        // item_price_wiithout_tax: (
        //   parseFloat(values?.data?.[rowIndex]?.item_unit_price) *
        //     parseFloat(sumQuantity) -
        //     parseFloat(discount) -
        //     parseFloat(tax) || 0
        // ).toFixed(2),
        item_gst:
          response.data?.code === 200
            ? response.data.data.item_total_tax_percentage
            : 0,
        item_sgst:
          response.data?.code === 200 ? (parseFloat(tax) / 2).toFixed(2) : 0,
        item_cgst:
          response.data?.code === 200 ? (parseFloat(tax) / 2).toFixed(2) : 0,
        item_igst:
          response.data?.code === 200
            ? (
                (sumQuantity *
                  parseFloat(values?.data?.[rowIndex]?.item_unit_price) -
                  parseFloat(discount)) *
                (response.data.data.igstRate / 100)
              ).toFixed(2)
            : 0,
        item_total_amount: (
          parseFloat(values?.data?.[rowIndex]?.item_unit_price) *
            parseFloat(sumQuantity) -
          parseFloat(discount)
          //  +
          // parseFloat(tax)
        ).toFixed(2),
        instock: true,
      };

      updatedData[rowIndex] = updatedDataRow;

      setFieldValue("data", [...updatedData]);
    } catch (error) {
      onErrorDis(error);
    }
  };

  const onErrorDis = (data) => {
    console.error("API Error:", data);
  };

  return (
    <div className="absolute w-[-webkit-fill-available]  z-40">
      <div className="  h-[72vh] bg-white flex flex-col justify-between">
        <div>
          <div className="bg-[#F4F6F9] w-full p-3 gap-2 font-semibold text-xl  flex items-center">
            Batch details -
            <SelectField
              handleSelected={(selected) => {
                setItemId(selected?.value);
              }}
              containerClass="w-[20rem]"
              placeholder={"Select product"}
              defaultValue={
                defaultSelectedItem
                  ? {
                      label: defaultSelectedItem.item_name,
                      value: defaultSelectedItem.item_id,
                    }
                  : null
              }
              options={values?.data
                ?.map((item) => ({
                  label: item?.item_name,
                  value: item?.item_id,
                }))
                ?.filter((item) => item?.value)}
              label={""}
              id={"item_id"}
              name="item_id"
              isSearchable={true}
              errMsgContainerClass="hidden"
              labelWidth="10.5rem"
            />
          </div>
          <div className="p-3 overflow-y-auto h-[38vh]">
            <table className="lg:w-[60%] w-full border-D0D5DD border border-1 rounded-lg ">
              <thead>
                <tr className="bg-F2F4F5">
                  <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-secondary fw_400 fs_14">
                    Batch No.
                  </th>{" "}
                  <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-secondary fw_400 fs_14">
                    Qty
                  </th>
                  <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-secondary fw_400 fs_14">
                    Exp. Date
                  </th>
                  {/* <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-secondary fw_400 fs_14">
                    From Bin
                  </th> */}
                  <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-secondary fw_400 fs_14">
                    To Bin{" "}
                  </th>
                  <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-secondary fw_400 fs_14"></th>
                </tr>
              </thead>
              <tbody>
                {defaultSelectedItem?.item_batches.map((batch, batchindex) => (
                  <tr key={batchindex}>
                    <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 fs_14 w-[30%]">
                      <Field
                        name={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.item_batch_number`}
                      >
                        {({ field }) => (
                          <input
                            autoFocus={true}
                            onKeyDown={(e) => {
                              if (e.key === " ") {
                                e.preventDefault(); // Prevent space from being typed
                              }
                            }}
                            placeholder=""
                            {...field}
                            type="text"
                            className={`w-full focus_outline__none focus:border-primary focus:border rounded-[10px] py-2 px-2`}
                          />
                        )}
                      </Field>
                    </td>
                    <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 fs_14 w-[30%]">
                      <Field
                        name={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.item_sellable_quantity`}
                      >
                        {({ field, form }) => (
                          <input
                            min={0}
                            placeholder=""
                            {...field}
                            type="number"
                            className={`w-full focus_outline__none focus:border-primary focus:border rounded-[10px] py-2 px-2`}
                            onChange={(e) => {
                              field.onChange(e); // Ensure field.onChang

                              handleQuantityChange(
                                defaultSelectedItemIndex,
                                batchindex,
                                e?.target?.value,
                                false
                              );
                            }}
                            onInput={(e) => {
                              const value = e.target.value;
                              if (value < 0) {
                                form.setFieldValue(field.name, 0); // Prevent negative value
                              }
                            }}
                            onKeyDown={(e) => {
                              if (["-", "e", " "].includes(e.key)) {
                                e.preventDefault(); // Prevent negative sign, scientific notation, and space
                              }
                            }}
                          />
                        )}
                      </Field>
                    </td>
                    <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 fs_14 w-[20%]">
                      <Field
                        name={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.item_exp_date`}
                      >
                        {({ field }) => (
                          <input
                            placeholder=""
                            {...field}
                            type="date"
                            className={`w-full focus_outline__none focus:border-primary focus:border rounded-[10px] py-2`}
                          />
                        )}
                      </Field>
                    </td>

                    {/* <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 fs_14">
                      <SelectField
                        defaultValue={{
                          label:
                            binLocationList?.find(
                              (bin) =>
                                bin?.value ===
                                values.data[defaultSelectedItemIndex]
                                  ?.item_batches[batchindex]?.from_bin_id
                            )?.label ?? "",
                          value: "",
                        }}
                        handleSelected={(selectedoption) => {
                          setFieldValue(
                            `data.${defaultSelectedItemIndex}.item_batches.${batchindex}.from_bin_location`,
                            selectedoption?.actualLabel
                          );
                        }}
                        labelClass="whitespace-nowrap flex fs_14 fw_600 text-secondary"
                        containerClass="w-full"
                        isDisabled={true}
                        placeholder={"select Bin"}
                        options={binLocationList}
                        label={""}
                        id={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.from_bin_id`}
                        name={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.from_bin_id`}
                        isSearchable={true}
                        errMsgContainerClass="ps-2 hidden"
                        labelWidth="7rem"
                      />
                    </td> */}
                    <td className="border-D0D5DD border border-1 w-[20%] py-2 px-1 text-secondary fw_400 fs_14 ">
                      <SelectField
                        autoFocus={
                          values.data[defaultSelectedItemIndex]?.item_batches[
                            batchindex
                          ]?.item_batch_number
                        }
                        defaultValue={{
                          label:
                            binLocationList?.find(
                              (bin) =>
                                bin?.value ===
                                values.data[defaultSelectedItemIndex]
                                  ?.item_batches[batchindex]?.to_bin_id
                            )?.label ?? "",
                          value: "",
                        }}
                        handleSelected={(selectedoption) => {
                          setFieldValue(
                            `data.${defaultSelectedItemIndex}.item_batches.${batchindex}.to_bin_location`,
                            selectedoption?.actualLabel
                          );
                        }}
                        labelClass="whitespace-nowrap flex fs_14 fw_600 text-secondary"
                        containerClass="w-full"
                        isDisabled={false}
                        placeholder={"select Bin"}
                        options={binLocationList}
                        label={""}
                        id={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.to_bin_id`}
                        name={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.to_bin_id`}
                        isSearchable={true}
                        errMsgContainerClass="ps-2 hidden"
                        labelWidth="7rem"
                      />
                    </td>

                    <td className="border-D0D5DD border border-1 py-2 px-2 text-secondary fw_400 fs_14">
                      <button
                        onClick={() => {
                          const updatedBatches = [
                            ...defaultSelectedItem.item_batches,
                          ];
                          updatedBatches.splice(batchindex, 1);
                          setFieldValue(
                            `data.${defaultSelectedItemIndex}.item_batches`,
                            updatedBatches
                          );
                          handleQuantityChange(
                            defaultSelectedItemIndex,
                            null,
                            0,
                            true
                          );
                        }}
                        type="button"
                      >
                        <DeleteIcon />
                      </button>
                      {/* )} */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>{" "}
            <div className="flex justify-end lg:w-[60%] w-full">
              <AddButton
                className="bg-primary text-white px-8 py-1 mt-2 rounded flex justify-center fw_500 fs_16"
                onclkFn={() => {
                  setFieldValue(
                    `data.${defaultSelectedItemIndex}.item_batches`,
                    [
                      ...values?.data?.[defaultSelectedItemIndex]?.item_batches,
                      initData,
                    ]
                  );
                }}
                prefixIcon={true}
                text=""
                type="button"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between px-10 sticky bottom-0   bg-white py-2 invoice-bottom-container">
          <AddButton
            onclkFn={() => setAddbatch(false)}
            prefixIcon={false}
            text="Done"
            type="button"
          />
        </div>
      </div>
      <div
        className="bg-black h-[23vh] opacity-50"
        onClick={() => setAddbatch(false)}
      ></div>
    </div>
  );
};

export default BatchQuantityExpiryPopover;
