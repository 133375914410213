import React from "react";
import Select, { components } from "react-select";

const Menu = (props) => {
  return (
    <components.Menu
      {...props}
      className="bg-787878 text-secondary text-sm rounded-lg block w-full  dark:placeholder-787878 dark:text-secondary dark:focus:ring-blue focus:outline-none"
    >
      {props.children}
    </components.Menu>
  );
};

const SelectOptions = ({ options = [], handleChange = () => {}, value }) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "none",
      boxShadow: "none",
    }),

    indicatorSeparator: () => ({ display: "none" }),
    menu: (provided) => ({
      ...provided,
      border: "none",
      boxShadow: "none",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: 0,
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      padding: 0,
    }),
    singleValue: (provided) => ({
      ...provided,
      padding: 0,
      fontWeight: "600",
      fontSize: "14px",
    }),
    option: (provided) => ({
      ...provided,
      whiteSpace: "nowrap",  // Prevent text from wrapping
    }),
  };

  return (
    <Select
      value={value}
      onChange={handleChange}
      styles={customStyles}
      id="countries"
      options={options}
      className="bg-787878 text-787878 text-sm rounded-lg block w-full dark:bg-787878 dark:placeholder-787878 dark:text-white dark:focus:ring-blue-500 focus:outline-none"
      classNamePrefix="select"
      isSearchable={false}
      isClearable={false}
      isMulti={false}
      defaultValue={options[0]}
      components={{
        Menu,
      }}
    />
  );
};

export default SelectOptions;
