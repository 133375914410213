import { Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ShippingTypeTableListAction, addShippingTypeAction, deleteShippingTypeAction, updateShippingTypeAction } from '../../../../redux/actions/settings-action';
import KibabMenu from '../../../../assets/icons/kibab-menu';
import DeleteIcon from '../../../../assets/icons/delete-icon';
import successToast from '../../../helper-components/success-toast';
import errorToast from '../../../helper-components/error-toast';
import AddButton from '../../../buttons/button';
import search from "../../../../assets/icons/searchIcon.svg";
import LoadingSpinner from '../../../helper-components/loading-spinner';
import InnerLayout from '../../../layouts/inner-layout';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

const ShippingSetup = () => {
    const tableHeading = ["Shiiping Type Name", "Carrier", "Field1", "Field2", "Field3", "Active", ""];

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [disableArrayFields, setDisableArrayFields] = useState(false);
    const [disableNewFields, setDisableNewFields] = useState(false);
    const [initialData, setShippingListData] = useState([])
    const [selectedDOtIndex, setSelectedDotIndex] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(false)


    const handleFieldChange = (index, field, value, setFieldValue) => {
        setFieldValue(`${index}.${field}`, value);
    };

    useEffect(() => {
        dispatch(ShippingTypeTableListAction({}, onFetchSuccess, onFetchError));
        setLoading(true)
    }, []);

    useEffect(() => {
        dispatch(ShippingTypeTableListAction({ searchValue }, onFetchSuccess, onFetchError));
        setLoading(true)
    }, [searchValue]);


    const reloadData = () => {
        dispatch(ShippingTypeTableListAction({}, onFetchSuccess, onFetchError));
        setLoading(true)
    }

    const onFetchSuccess = (data) => {
        setShippingListData(data?.data?.shippingTypeList);
        setLoading(false)
    }

    const onFetchError = (data) => {
        
        setLoading(false)
        setShippingListData([])
        errorToast(data?.message)
    }

    const initialValues = {
        data: initialData,
        newLine: { name: "", carrier: "", field_1: "", field_2: "", field_3: "", status: false }
    };

    const onSubmit = (values, { setSubmitting }) => {
        const changedObjects = [];
        let newLineObject = null

        // Collect changed array data
        initialData.forEach((item, index) => {
            if (JSON.stringify(values.data[index]) !== JSON.stringify(item)) {
                changedObjects.push(values.data[index]);
            }
        });

        // Collect new line data if it is altered
        if (JSON.stringify(values.newLine) !== JSON.stringify(initialValues.newLine)) {
            newLineObject = values.newLine
        }

        if (newLineObject !== null) {
            dispatch(addShippingTypeAction(newLineObject, onCreateSuccess, onCreateError))
        }

        if (changedObjects.length > 0) {
            dispatch(updateShippingTypeAction({ updatingData: changedObjects }, onUpdateSuccess, onUpdateError))
        }

        setSubmitting(false);
    }

    const onCreateSuccess = (data) => {
        reloadData();
        setDisableArrayFields(false);
        setDisableNewFields(false);
        successToast(data?.message);
    }
    const onCreateError = (data) => {
        
        setDisableArrayFields(false);
        setDisableNewFields(false);
        errorToast(data?.message);
    }

    const onUpdateSuccess = (data) => {
        reloadData();
        setDisableArrayFields(false);
        setDisableNewFields(false);
        successToast(data?.message);
    }
    const onUpdateError = (data) => {
        
        setDisableArrayFields(false);
        setDisableNewFields(false);
        errorToast(data?.message);
    }

    const onClickDelete = (id) => {
        dispatch(deleteShippingTypeAction({ id }, onDeletSuccess, onError))
    }
    const onDeletSuccess = (data) => {
        reloadData()
        successToast(data?.message);
        selectedDOtIndex(null)
    }

    const onError = (data) => {
        errorToast(data?.message);
        selectedDOtIndex(null)
    }


    const componentRef = useRef(null);

    const handleClickOutside = (event) => {
        if (componentRef.current && !componentRef.current.contains(event.target)) {
            setSelectedDotIndex(null);
        }
    };

    return (
        <InnerLayout
            list={true}
            title="Settings"
            module="shipping-setup"
        // permissionFn={permissionFn}
        >
            <div className='pl-4'>
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                >
                    {({ values, setFieldValue, dirty, handleSubmit }) => (
                        <Form className="pt-1">
                            <div className="flex flex-col" style={{ height: "85vh" }} onClick={handleClickOutside}>
                                <div className='flex justify-between pt-3'>
                                    <div className="pl-2 pb-4 flex items-center gap-2">
                                        <button
                                            className="flex items-center gap-2"
                                            onClick={() => {
                                                navigate('/settings')
                                            }}
                                        >
                                            <ChevronLeftIcon className="h-5 w-5 inline-block" />
                                            Settings /
                                        </button>
                                        <span className="cursor-pointer" >{" " + "Shipping Setup"}</span>
                                    </div>
                                    <div
                                        style={{ minWidth: "18rem", height: "2.5rem" }}
                                        className={`input_border flex items-center rounded-md pb-1 w-10 mx-6`}
                                    >
                                        <span className="px-2">
                                            {" "}
                                            <img width={"16px"} src={search} alt="search icon" />
                                        </span>
                                        <input
                                            placeholder={`Search here`}
                                            className=" table_search_input w-full rounded-md fs_12 placeholder:text-fs_12"
                                            type="text"
                                            value={searchValue}
                                            onChange={(e) => {
                                                setSearchValue(e?.target?.value);
                                            }}
                                        />
                                        <span className='fs_14 mx-3 cursor-pointer text-primary' onClick={() => setSearchValue('')} >Clear</span>
                                    </div>
                                </div>
                                {loading ? (
                                    <LoadingSpinner />
                                ) : (
                                    <div className="overflow-auto mt-1">
                                        <table className="mt-2 w-full">
                                            <thead className='sticky top-0 z-10 bg-F2F4F5'>
                                                <tr>
                                                    {tableHeading.map((heading, index) => (
                                                        <th className={`settings-table-header whitespace-nowrap fs_14 fw_500 text-475467 bg-F2F4F5 text-start`} key={heading} >{heading}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {values.data.map((item, index) => (
                                                    <tr key={index}>
                                                        <td className={`setting-table-cell fs_14 fw_400 text-secondary `}>
                                                            <Field
                                                                name={`data[${index}].name`}
                                                                placeholder="Name"
                                                                disabled={disableArrayFields}
                                                                onChange={(e) => handleFieldChange(`data[${index}]`, "name", e.target.value, setFieldValue)}
                                                                className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded py-2 w-36"
                                                            />
                                                        </td>
                                                        <td className={`setting-table-cell fs_14 fw_400 text-secondary `}>
                                                            <Field
                                                                name={`data[${index}].carrier`}
                                                                placeholder="Carrier"
                                                                disabled={disableArrayFields}
                                                                onChange={(e) => handleFieldChange(`data[${index}]`, "carrier", e.target.value, setFieldValue)}
                                                                className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded px-1 py-2 w-24"
                                                            />
                                                        </td>
                                                        <td className={`setting-table-cell fs_14 fw_400 text-secondary `}>
                                                            <Field
                                                                name={`data[${index}].field_1`}
                                                                placeholder="Field 1"
                                                                disabled={disableArrayFields}
                                                                onChange={(e) => handleFieldChange(`data[${index}]`, "field_1", e.target.value, setFieldValue)}
                                                                className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded px-1 py-2 w-24"
                                                            />
                                                        </td>
                                                        <td className={`setting-table-cell fs_14 fw_400 text-secondary `}>
                                                            <Field
                                                                name={`data[${index}].field_2`}
                                                                placeholder="Field 2"
                                                                disabled={disableArrayFields}
                                                                onChange={(e) => handleFieldChange(`data[${index}]`, "field_2", e.target.value, setFieldValue)}
                                                                className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded px-1 py-2 w-24"
                                                            />
                                                        </td>
                                                        <td className={`setting-table-cell fs_14 fw_400 text-secondary `}>
                                                            <Field
                                                                name={`data[${index}].field_3`}
                                                                placeholder="Field 3"
                                                                disabled={disableArrayFields}
                                                                onChange={(e) => handleFieldChange(`data[${index}]`, "field_3", e.target.value, setFieldValue)}
                                                                className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded px-1 py-2 w-24"
                                                            />
                                                        </td>
                                                        <td className={`setting-table-cell fs_14 fw_400 text-secondary`}>
                                                            <Field
                                                                name={`data[${index}].status`}
                                                                disabled={disableArrayFields}
                                                                type={'checkbox'}
                                                                onChange={(e) => handleFieldChange(`data[${index}]`, "status", e.target.checked, setFieldValue)}
                                                                className="border-[1px] focus:outline-none h-[18px] w-[18px] rounded-[6px] px-1 py-2"
                                                            />
                                                        </td>
                                                        <td className="setting-table-cell fs_14 fw_400 text-secondary p-8">
                                                            <button onClick={() => setSelectedDotIndex(index)}>
                                                                <KibabMenu />
                                                            </button>
                                                            {selectedDOtIndex === index && (
                                                                <div
                                                                    ref={componentRef}
                                                                    style={{ position: "absolute", right: '2rem', boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                                                                    className='bg-white rounded w-38'
                                                                >
                                                                    <div className='w-full p-3'>
                                                                        <button className='w-full flex align-middle gap-2 px-2 py-1' onClick={() => onClickDelete(item?.id)}>
                                                                            <div className="mt-1">
                                                                                <DeleteIcon />
                                                                            </div>
                                                                            <span className='fs_16'>Delete</span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                                <tr>
                                                    <td className={`setting-table-cell fs_14 fw_400 text-secondary `}>
                                                        <Field
                                                            name="newLine.name"
                                                            placeholder="Name"
                                                            disabled={disableNewFields}
                                                            onChange={(e) => handleFieldChange('newLine', "name", e.target.value, setFieldValue)}
                                                            className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded py-2 w-36"
                                                            autoFocus={searchValue ? false : true}
                                                        />
                                                    </td>
                                                    <td className={`setting-table-cell fs_14 fw_400 text-secondary `}>
                                                        <Field
                                                            name="newLine.carrier"
                                                            placeholder="Carrier"
                                                            disabled={disableNewFields}
                                                            onChange={(e) => handleFieldChange('newLine', "carrier", e.target.value, setFieldValue)}
                                                            className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded py-2 w-24"
                                                        />
                                                    </td>
                                                    <td className={`setting-table-cell fs_14 fw_400 text-secondary `}>
                                                        <Field
                                                            name="newLine.field_1"
                                                            placeholder="Field 1"
                                                            disabled={disableNewFields}
                                                            onChange={(e) => handleFieldChange("newLine", "field_1", e.target.value, setFieldValue)}
                                                            className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded px-1 py-2 w-24"
                                                        />
                                                    </td>
                                                    <td className={`setting-table-cell fs_14 fw_400 text-secondary `}>
                                                        <Field
                                                            name={`newLine.field_2`}
                                                            placeholder="Field 2"
                                                            disabled={disableNewFields}
                                                            onChange={(e) => handleFieldChange("newLine", "field_2", e.target.value, setFieldValue)}
                                                            className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded px-1 py-2 w-24"
                                                        />
                                                    </td>
                                                    <td className={`setting-table-cell fs_14 fw_400 text-secondary `}>
                                                        <Field
                                                            name={`newLine.field_3`}
                                                            placeholder="Field 3"
                                                            disabled={disableNewFields}
                                                            onChange={(e) => handleFieldChange("newLine", "field_3", e.target.value, setFieldValue)}
                                                            className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded px-1 py-2 w-24"
                                                        />
                                                    </td>
                                                    <td className={`setting-table-cell fs_14 fw_400 text-secondary `}>
                                                        <Field
                                                            name={`newLine.status`}
                                                            disabled={disableNewFields}
                                                            type={'checkbox'}
                                                            onChange={(e) => handleFieldChange("newLine", "status", e.target.checked, setFieldValue)}
                                                            className="border-[1px] focus:outline-none h-[18px] w-[18px] rounded-[6px] px-1 py-2"
                                                        />
                                                    </td>
                                                    <td className="setting-table-cell fs_14 fw_400 text-secondary p-8">
                                                        <button >
                                                            <KibabMenu />
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>
                            <div className="flex justify-end w-full bg-white py-4 px-6 invoice-bottom-container">
                                <AddButton
                                    onclkFn={handleSubmit}
                                    prefixIcon={false}
                                    text="Save"
                                    type="submit"
                                />
                            </div>
                        </Form>
                    )}
                </Formik >
            </div>

        </InnerLayout>
    );
};

export default ShippingSetup;
