import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { valListAction } from "../../redux/actions/general-action";
import { productInventoryAction } from "../../redux/actions/product-action";
import AddButton from "../buttons/button";
import CheckBox from "../forms/check-box-field";
import InputField from "../forms/input-field";
import SelectField from "../forms/select-field";
import errorToast from "../helper-components/error-toast";
import successToast from "../helper-components/success-toast";

const InventoryTab = ({
  productId,
  uomList,
  setFormValues,
  formValues,
  setCurrentTab,
  product,
  disabled = false,
  itemGeneralData = {},
}) => {
  const dispatch = useDispatch();
  const [valList, setvalList] = useState([]);

  useEffect(() => {
    dispatch(valListAction(onvalSuccess, onvalError));
  }, []);

  const onvalSuccess = (data) => {
    setvalList(
      data?.data?.scheduleList?.map((item) => {
        return { label: item?.valuationMethod, value: item?.valuationMethod };
      })
    );
  };
  const onvalError = (data) => {
    errorToast(data?.message);
  };
  const initialValues = {
    item_id: product?.item_id ?? productId,
    item_set_gl_account: product?.item_set_gl_account ?? "",
    item_uom_name: product?.item_uom_name ?? "",
    item_weight: product?.item_weight ?? null,
    item_valuation_method: product?.item_valuation_method ?? "",
    item_cost: product?.item_cost ?? null,
    item_purchasing_uom: product?.item_purchasing_uom ?? "",
    item_minimun_purchasing: product?.item_minimun_purchasing ?? null,
    item_maximum_purchasing: product?.item_maximum_purchasing ?? null,
    manage_inventory_by_warehouse:
      product?.manage_inventory_by_warehouse ?? false,
  };

  const validationSchema = Yup.object().shape({
    item_purchasing_uom: Yup.string().required("required"),
  });
  const innerOnSubmit = (values) => {
    setFormValues({
      ...formValues,
      inventory: values,
    });
    dispatch(
      productInventoryAction(
        {
          itemGeneralData: itemGeneralData,
          itemInventoryData: values,
        },
        onSuccess,
        onError
      )
    );
  };

  const onSuccess = (data) => {
    successToast(data?.message);
    setCurrentTab("Planning");
  };
  const onError = (data) => {};

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={formValues?.inventory ?? initialValues}
        validationSchema={validationSchema}
        onSubmit={innerOnSubmit}
      >
        {({ values, handleSubmit, errors }) => {
          return (
            // <Form>
            <>
              <div className="flex flex-wrap">
                <div className="w-full 2xl:w-[35%] lg:w-1/2 px-5 xl:px-10">
                  <SelectField
                    isDisabled={disabled}
                    placeholder="Select"
                    options={serialBatchesOptns}
                    label="Set G/L Account by"
                    id="item_set_gl_account"
                    name="item_set_gl_account"
                    isSearchable={true}
                    errMsgContainerClass="ps-2"
                    isRequired={false}
                    labelWidth="9rem"
                  />
                  <SelectField
                    isDisabled={disabled}
                    placeholder="Select uom"
                    options={uomList}
                    label="UoM Name"
                    id="item_uom_name"
                    name="item_uom_name"
                    isSearchable={true}
                    errMsgContainerClass="ps-2"
                    isRequired={false}
                    labelWidth="9rem"
                  />
                  <InputField
                    isDisabled={disabled}
                    type="number"
                    name="item_weight"
                    placeholder="xxxxxxx"
                    label="Weight"
                    isRequired={false}
                    labelWidth="9rem"
                  />
                </div>
                <div className="w-full 2xl:w-[35%] lg:w-1/2 px-5 xl:px-10 mt-10 lg:mt-0">
                  <SelectField
                    isDisabled={disabled}
                    placeholder=""
                    options={valList}
                    label="Valuation Method"
                    id="item_valuation_method"
                    name="item_valuation_method"
                    isSearchable={true}
                    errMsgContainerClass="ps-2"
                    isRequired={false}
                    labelWidth="9rem"
                  />

                  <InputField
                    isDisabled={disabled}
                    type="number"
                    name="item_cost"
                    placeholder="xxxxxxx"
                    label="Item Cost"
                    isRequired={false}
                    labelWidth="9rem"
                  />
                </div>
              </div>
              <hr />
              <div className="flex flex-wrap mt-10">
                <div className="w-full 2xl:w-[35%] lg:w-1/2 px-5 xl:px-10">
                  <SelectField
                    isDisabled={disabled}
                    placeholder=""
                    options={uomList}
                    label="Purchasing UoM"
                    id="item_purchasing_uom"
                    name="item_purchasing_uom"
                    isSearchable={true}
                    errMsgContainerClass="ps-2"
                    isRequired={true}
                    labelWidth="9rem"
                  />

                  <InputField
                    isDisabled={disabled}
                    type="number"
                    name="item_minimun_purchasing"
                    placeholder=""
                    label="Minimum"
                    isRequired={false}
                    labelWidth="9rem"
                  />

                  <InputField
                    isDisabled={disabled}
                    type="number"
                    name="item_maximum_purchasing"
                    placeholder=""
                    label="Maximum"
                    isRequired={false}
                    labelWidth="9rem"
                  />
                </div>
                <div className="w-full 2xl:w-[35%] lg:w-1/2 px-5 xl:px-10 mt-10 lg:mt-0">
                  <CheckBox
                    isDisabled={disabled}
                    conClass="flex flex-col  gap-10"
                    items={itemoptions}
                  />
                </div>
              </div>
              {!disabled && (
                <div
                  style={{
                    bottom: "0",
                  }}
                  className="flex justify-end px-10 mb-5x sticky bg-white py-4 invoice-bottom-container"
                >
                  <AddButton
                    onclkFn={handleSubmit}
                    prefixIcon={false}
                    text="Save"
                    type="submit"
                  />
                </div>
              )}
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default InventoryTab;

export const serialBatchesOptns = [
  { label: "Item Group", value: "item_group" },
];
const itemoptions = [
  {
    label: "Manage inventory by Warehouse",
    name: "manage_inventory_by_warehouse",
    isRequired: false,
  },
];
