import React, { useState } from "react";
import { components } from "react-select";
import AsyncCreatableSelect from "react-select/async-creatable";
import { useNavigate } from "react-router-dom";
import DownArrowIcon from "../../assets/icons/down-arrow-icon";
import { useDispatch } from "react-redux";
import "./form.css";

const SearchSelectFieldNonFormik = ({
  field = "",
  action = () => {},
  value = {},
  handleChange = () => {},
  isMulti = false,
}) => {
  const dispatch = useDispatch();
  const DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
      <DownArrowIcon />
    </components.DropdownIndicator>
  );
  const loadOptions = (inputValue) => {
    return new Promise((resolve, reject) => {
      const onSuccess = (data) => {
        const options = data?.data.map((item) => ({
          value: `${item?.[field]}`,
          label: `${item?.[field]}`,
        }));
        resolve(options);
      };

      const onError = (error) => {
        console.error("Error loading options:", error);
        resolve([]); // Resolve empty array in case of error
      };

      if (inputValue?.length > 0) {
        dispatch(
          action(
            {
              field: field,
              value: inputValue,
              isVendor: false, // Set the vendor flag as per your requirements
            },
            onSuccess,
            onError
          )
        );
      } else {
        resolve([]); // Return empty options if no input value
      }
    });
  };

  return (
    <div
      style={{ maxWidth: "15rem" }}
      className={`w-full flex items-center rounded-md input_border h-fit`}
    >
      <AsyncCreatableSelect
        isMulti={isMulti}
        value={isMulti ? (value || []) : (value ? [value] : null)} // Ensure proper value handling
        onCreateOption={() => {}}
        className="w-full"
        styles={customStylesForFilter}
        components={{
          ...components,
          DropdownIndicator,
        }}
        placeholder="Select..."
        menuPlacement="auto"
        onChange={handleChange}
        loadOptions={(e) => loadOptions(e)}
      />
    </div>
  );
};

export default SearchSelectFieldNonFormik;

export const customStylesForFilter = {
  control: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    fontWeight: "600",
    color: "#344054",
    width: "100%",
    boxShadow: "none", // Remove box shadow
    border: "none",
    borderRadius: "6px",
  }),
  placeholder: (defaultStyles) => ({
    ...defaultStyles,
    color: "gray",
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    fontWeight: "600",
    opacity: "1",
    position: "absolute",
    whiteSpace: "nowrap",
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none", // Hide indicator separator
  }),
  singleValue: (base) => ({
    ...base,
    fontWeight: "600",
    color: "#344054",
    fontSize: "12px",
  }),
  option: (provided, state) => ({
    ...provided,
    ...(state.data.color ? { color: state.data.color } : { color: "black" }),
    backgroundColor: state.isSelected ? "#e5ecffb2" : null,
    borderBottom: "1px solid #F5F5F5",
    display: "flex",
    fontSize: "12px",
    fontFamily: "Inter, sans-serif",
    position: "relative",
    paddingRight: "1.5rem",
    "&:hover": {
      backgroundColor: state.isFocused ? "#0000002E" : "#0000002E",
    },
    "&::after": {
      content: state.isSelected ? '"✓"' : '""',
      color: "#007bff", // Blue color
      fontWeight: "normal",
      fontSize: "20px", // Larger size
      position: "absolute",
      right: "0.8rem",
      marginBottom: "22px",
    },
  }),
};
