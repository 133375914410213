import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import AddButton from "../../buttons/button";
import InputField from "../../forms/input-field";
import TabsComponent from "../../helper-components/tabs-component";
import { useDispatch } from "react-redux";
import { StoreDrugCreateAction } from "../../../redux/actions/store-action";
import successToast from "../../helper-components/success-toast";
import errorToast from "../../helper-components/error-toast";
import StoreDrugAddressTab from "./drug-shop-address";
import StoreDrugDlDetailsTab from "./drug-dl-details";
import ImageField from "../../forms/images-field";

const StoreDrugTab = ({
  id,
  setStoreData,
  storeData,
  initialValue,
  disabled = false,
}) => {
  
  const [currentTab, setCurrentTab] = useState("Shop Address");
  const dispatch = useDispatch();
  const initialValues = {
    store_id: id ?? null,
    dl_apply_date: initialValue?.dl_apply_date ?? null,
    dl_chalan_receipt: initialValue?.dl_chalan_receipt ?? "",
    dl_is_approve: initialValue?.dl_is_approve ?? false,
    dl_address: initialValue?.dl_address ?? "",
    dl_area: initialValue?.dl_area ?? "",
    dl_landmark: initialValue?.dl_landmark ?? "",
    dl_town_or_village: initialValue?.dl_town_or_village ?? "",
    dl_city: initialValue?.dl_city ?? "",
    dl_state: initialValue?.dl_state ?? "",
    dl_pin_code: initialValue?.dl_pin_code ?? "",
    dl_country_region: initialValue?.dl_country_region ?? "",
    dl_valid_upto: initialValue?.dl_valid_upto ?? "",
    dl_no_20_20B: initialValue?.dl_no_20_20B ?? "",
    dl_no_21_21B: initialValue?.dl_no_21_21B ?? "",
    dl_no_21C: initialValue?.dl_no_21C ?? "",
    dl_no_21F: initialValue?.dl_no_21F ?? "",
    dl_no_name: initialValue?.dl_no_name ?? "",
    dl_store_sq_ft: initialValue?.dl_store_sq_ft ?? null,
  };

  const validationSchema = Yup.object().shape({
    dl_apply_date: Yup.date().nullable().required("DL Apply Date is required"),
    dl_address: Yup.string().required("DL Address is required"),
    dl_area: Yup.string().required("DL Area is required"),
    dl_landmark: Yup.string().required("DL Landmark is required"),
    dl_town_or_village: Yup.string().required("DL Town/Village is required"),
    dl_city: Yup.string().required("DL City is required"),
    dl_state: Yup.string().required("DL State is required"),
    dl_pin_code: Yup.string()
      .required("DL Pin Code is required")
      .matches(/^[1-9][0-9]{5}$/, "Invalid Pin Code"),
    dl_country_region: Yup.string().required("DL Country/Region is required"),
    dl_valid_upto: Yup.string().required("DL Valid Upto is required"),
    dl_no_20_20B: Yup.string().required("DL No. 20/20B is required"),
    dl_no_21_21B: Yup.string().required("DL No. 21/21B is required"),
    dl_no_21C: Yup.string().required("DL No. 21C is required"),
    dl_no_21F: Yup.string().required("DL No. 21F is required"),
    dl_no_name: Yup.string().required("DL No. Name is required"),
    dl_store_sq_ft: Yup.number()
      .nullable()
      .required("DL Store Sq. Ft. is required"),
  });

  const onSubmit = (values, actions) => {
    dispatch(StoreDrugCreateAction(values, onSuccess, onError));
  };

  const onSuccess = (data) => {
    setStoreData({
      ...storeData,
      drug: data?.data,
    });
    successToast(data?.data?.message);
  };
  const onError = (data) => {
    errorToast(data?.data?.message);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, isSubmitting, errors }) => {
        
        
        return (
          <Form style={{ height: "calc(100% - 5rem)" }} className="pt-5">
            <div className="flex flex-col justify-between h-full ">
              <div>
                <div className="w-full 2xl:w-[30%] lg:w-[60%] px-5 xl:px-10">
                  <InputField
                    type="date"
                    name="dl_apply_date"
                    placeholder=""
                    label="DL Apply Date"
                    isRequired={true}
                    labelWidth="9rem"
                  />
                  <ImageField
                    label="Chalan Receipt"
                    isRequired={false}
                    labelWidth="9rem"
                    name="dl_chalan_receipt"
                    mainLabel="DL Chalan Receipt"
                  />
                </div>
                <TabsComponent
                  handleClickfn={(item) => {
                    setCurrentTab(item);
                  }}
                  itemTabs={itemTabs}
                  setCurrentTab={setCurrentTab}
                  currentTab={currentTab}
                />
                {currentTab === "Shop Address" && <StoreDrugAddressTab />}
                {currentTab === "DL Details" && <StoreDrugDlDetailsTab />}
              </div>
              {!disabled && (
                <div
                  style={{
                    bottom: "0",
                  }}
                  className="flex justify-end px-10 mb-5x sticky bg-white py-4 invoice-bottom-container"
                >
                  <AddButton
                    onclkFn={() => {
                      if (Object.keys(errors).length !== 0) {
                        const currentIndex = itemTabs.indexOf(currentTab);
                        let nextTab;

                        if (currentIndex !== -1) {
                          // Go to the next tab based on the current tab index
                          nextTab =
                            currentIndex === itemTabs.length - 1
                              ? itemTabs[0] // Loop back to the first tab if the last tab is reached
                              : itemTabs[currentIndex + 1];

                          setCurrentTab(nextTab);
                        }
                      }
                    }}
                    prefixIcon={false}
                    text="Save"
                    type="submit"
                  />
                </div>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default StoreDrugTab;
const itemTabs = ["Shop Address", "DL Details"];
