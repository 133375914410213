import React from "react";

const DownloadIcon = ({ fill = "#21A366" }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 12.754V13.75C1.5 14.5456 1.81607 15.3087 2.37868 15.8713C2.94129 16.4339 3.70435 16.75 4.5 16.75H14.5C15.2956 16.75 16.0587 16.4339 16.6213 15.8713C17.1839 15.3087 17.5 14.5456 17.5 13.75V12.75M9.5 1.25V12.25M9.5 12.25L13 8.75M9.5 12.25L6 8.75"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DownloadIcon;
