import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { Field, Formik } from "formik";
import AddButton, { DraftButton } from "../../buttons/button";
import successToast from "../../helper-components/success-toast";
import errorToast from "../../helper-components/error-toast";
import { useDispatch } from "react-redux";
import { documentNumberingSeriesAddAction, documentNumberingSeriesListAction } from "../../../redux/actions/settings-action";
import { formatString } from "../../helper-components/helper-function";

const DocumentNumberingEditModal = ({ isModalOpen, setIsModalOpen, docId, reloadData, title = "Invoice" }) => {

    const tableHeading = ["Name", "Prefix", "Suffix", "First No.", "Last No.", "Increment", "Include Year", "Include Month", "Include Date", "Separotor", "Leading Spaces", "Series FYI", "Default"];

    const dispatch = useDispatch();
    const [initialData, setDocumentSeriesData] = useState([])

    useEffect(() => {
        dispatch(documentNumberingSeriesListAction({ transaction_doc_id: docId }, onFetchSuccess, onFetchError));
    }, [docId]);


    const onFetchSuccess = (data) => {
        setDocumentSeriesData(data?.data)
    }

    const onFetchError = (data) => {
        
        setDocumentSeriesData([])
    }


    const handleFieldChange = (index, field, value, setFieldValue) => {
        setFieldValue(`${index}.${field}`, value);
    };

    const initialValues = {
        data: initialData,
        newLine: {
            "series_name": "",
            "series_prefix": "",
            "series_suffix": "",
            "series_first_digit": null,
            "series_last_digit": null,
            "series_separator": "",
            "series_default": false,
            "series_increment": null,
            "series_include_year": false,
            "series_include_month": false,
            "series_include_date": false,
            "series_leading_spaces": null,
            "transaction_doc_id": null,
            "series_fyi_year": null,
        }
    };

    const onSubmit = (values, { setSubmitting }) => {
        let newLineObject = null
        if (JSON.stringify(values.newLine) !== JSON.stringify(initialValues.newLine)) {
            newLineObject = values.newLine
        }
        if (newLineObject !== null) {
            const emptyFields = [];
            if (!newLineObject.series_name) {
                emptyFields.push("Series Name");
            }
            if (!newLineObject.series_prefix) {
                emptyFields.push("Series Prefix");
            }
            if (!newLineObject.series_suffix) {
                emptyFields.push("Series Suffix");
            }
            if (!newLineObject.series_first_digit) {
                emptyFields.push("Series First Num");
            }
            if (!newLineObject.series_last_digit) {
                emptyFields.push("Series Last Num");
            }

            if (emptyFields.length > 0) {
                const errorMessage = `${emptyFields.join(', ')} Field is required`;
                errorToast(errorMessage);
            } else {
                newLineObject.transaction_doc_id = docId
                dispatch(documentNumberingSeriesAddAction(newLineObject, onCreateSuccess, onCreateError))
            }
        }
        setSubmitting(false);
    }

    const onCreateSuccess = (data) => {
        reloadData();
        successToast(data?.data?.message);
        setIsModalOpen(false)
    }
    const onCreateError = (data) => {
        
        errorToast(data?.message);
    }

    
    return (
        <Transition show={isModalOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-50 overflow-y-auto"
                onClose={() => setIsModalOpen(false)}
            >
                <div className="flex min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-100"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
                    </Transition.Child>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4"
                        enterTo="opacity-100 translate-y-0"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-4"
                    >
                        <div className="mx-auto  w-11/12 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
                            <Formik
                                enableReinitialize={true}
                                initialValues={initialValues}
                                onSubmit={onSubmit}
                            >
                                {({ setFieldValue, handleSubmit, values, resetForm }) => {
                                    return (
                                        <>
                                            <div className="w-full h-full flex flex-col">
                                                <div className="flex flex-col items-start w-full px-6 pt-8">
                                                    <label className="mx-3 fs_20 fw_600">{formatString(title)}</label>
                                                    <div className="overflow-auto" style={{ height: '43rem' }}>
                                                        <table className="mt-2 w-full">
                                                            <thead>
                                                                <tr>
                                                                    {tableHeading.map((heading, index) => (
                                                                        <th className={`settings-table-header whitespace-nowrap fs_14 fw_500 text-475467 bg-F2F4F5 text-start`} key={heading} >{heading}</th>
                                                                    ))}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {values.data.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td className={`setting-table-cell fs_14 fw_400 text-secondary`}>
                                                                            {formatString(item?.series_name)}
                                                                        </td>
                                                                        <td className={`setting-table-cell fs_14 fw_400 text-secondary`}>
                                                                            {item?.series_prefix}
                                                                        </td>
                                                                        <td className={`setting-table-cell fs_14 fw_400 text-secondary`}>
                                                                            {item?.series_suffix}
                                                                        </td>
                                                                        <td className={`setting-table-cell fs_14 fw_400 text-secondary`}>
                                                                            {item?.series_first_digit}
                                                                        </td>
                                                                        <td className={`setting-table-cell fs_14 fw_400 text-secondary`}>
                                                                            {item?.series_last_digit}
                                                                        </td>
                                                                        <td className={`setting-table-cell fs_14 fw_400 text-secondary`}>
                                                                            {item?.series_increment}
                                                                        </td>
                                                                        <td className={`setting-table-cell fs_14 fw_400 text-secondary text-center`}>
                                                                            <input type="checkbox" className="mx-5 border-[1px] focus:outline-none h-[18px] w-[18px] rounded-[6px] px-1 py-2" checked={item?.series_include_year} readOnly />
                                                                        </td>
                                                                        <td className={`setting-table-cell fs_14 fw_400 text-secondary text-center`}>
                                                                            <input type="checkbox" className="mx-5 border-[1px] focus:outline-none h-[18px] w-[18px] rounded-[6px] px-1 py-2" checked={item?.series_include_month} readOnly />
                                                                        </td>
                                                                        <td className={`setting-table-cell fs_14 fw_400 text-secondary text-center`}>
                                                                            <input type="checkbox" className="mx-5 border-[1px] focus:outline-none h-[18px] w-[18px] rounded-[6px] px-1 py-2" checked={item?.series_include_date} readOnly />
                                                                        </td>
                                                                        <td className={`setting-table-cell fs_14 fw_400 text-secondary`}>
                                                                            {item?.series_separator}
                                                                        </td>
                                                                        <td className={`setting-table-cell fs_14 fw_400 text-secondary `}>
                                                                            {item?.series_leading_spaces}
                                                                        </td>
                                                                        <td className={`setting-table-cell fs_14 fw_400 text-secondary`}>
                                                                            {item?.series_fyi_year}
                                                                        </td>
                                                                        <td className={`setting-table-cell fs_14 fw_400 text-secondary`}>
                                                                            <input type="checkbox" className="mx-5 border-[1px] focus:outline-none h-[18px] w-[18px] rounded-[6px] px-1 py-2" checked={item?.series_default} readOnly />
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                                <tr>
                                                                    <td className={`setting-table-cell fs_14 fw_400 text-secondary text-center`}>
                                                                        <Field
                                                                            name="newLine.series_name"
                                                                            placeholder="Name"
                                                                            onChange={(e) => handleFieldChange('newLine', "series_name", e.target.value, setFieldValue)}
                                                                            className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded py-2 w-full"
                                                                            autoFocus
                                                                        />
                                                                    </td>
                                                                    <td className={`setting-table-cell fs_14 fw_400 text-secondary text-center`}>
                                                                        <Field
                                                                            name="newLine.series_prefix"
                                                                            placeholder="Prefix"
                                                                            onChange={(e) => handleFieldChange('newLine', "series_prefix", e.target.value, setFieldValue)}
                                                                            className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded py-2 w-full"
                                                                        />
                                                                    </td>
                                                                    <td className={`setting-table-cell fs_14 fw_400 text-secondary text-center`}>
                                                                        <Field
                                                                            name="newLine.series_suffix"
                                                                            placeholder="Suffix"
                                                                            onChange={(e) => handleFieldChange("newLine", "series_suffix", e.target.value, setFieldValue)}
                                                                            className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded px-1 py-2 w-full"
                                                                        />
                                                                    </td>
                                                                    <td className={`setting-table-cell fs_14 fw_400 text-secondary text-center`}>
                                                                        <Field
                                                                            name={`newLine.series_first_digit`}
                                                                            placeholder="First num"
                                                                            type="number"
                                                                            onChange={(e) => handleFieldChange("newLine", "series_first_digit", e.target.value, setFieldValue)}
                                                                            className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded px-1 py-2 w-full"
                                                                        />
                                                                    </td>
                                                                    <td className={`setting-table-cell fs_14 fw_400 text-secondary text-center`}>
                                                                        <Field
                                                                            name={`newLine.series_last_digit`}
                                                                            placeholder="Last num"
                                                                            type="number"
                                                                            onChange={(e) => handleFieldChange("newLine", "series_last_digit", e.target.value, setFieldValue)}
                                                                            className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded px-1 py-2 w-full"
                                                                        />
                                                                    </td>
                                                                    <td className={`setting-table-cell fs_14 fw_400 text-secondary text-center`}>
                                                                        <Field
                                                                            name={`newLine.series_increment`}
                                                                            placeholder="Series increment"
                                                                            onChange={(e) => handleFieldChange("newLine", "series_increment", e.target.value, setFieldValue)}
                                                                            className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded px-1 py-2 w-full"
                                                                        />
                                                                    </td>
                                                                    <td className={`setting-table-cell fs_14 fw_400 text-secondary text-center`}>
                                                                        <Field
                                                                            name={`newLine.series_include_year`}
                                                                            type={'checkbox'}
                                                                            onChange={(e) => handleFieldChange("newLine", "series_include_year", e.target.checked, setFieldValue)}
                                                                            className="border-[1px] focus:outline-none h-[18px] w-[18px] rounded-[6px] px-1 py-2"
                                                                        />
                                                                    </td>
                                                                    <td className={`setting-table-cell fs_14 fw_400 text-secondary text-center`}>
                                                                        <Field
                                                                            name={`newLine.series_include_month`}
                                                                            type={'checkbox'}
                                                                            onChange={(e) => handleFieldChange("newLine", "series_include_month", e.target.checked, setFieldValue)}
                                                                            className="border-[1px] focus:outline-none h-[18px] w-[18px] rounded-[6px] px-1 py-2"
                                                                        />
                                                                    </td>
                                                                    <td className={`setting-table-cell fs_14 fw_400 text-secondary text-center`}>
                                                                        <Field
                                                                            name={`newLine.series_include_date`}
                                                                            type={'checkbox'}
                                                                            onChange={(e) => handleFieldChange("newLine", "series_include_date", e.target.checked, setFieldValue)}
                                                                            className="border-[1px] focus:outline-none h-[18px] w-[18px] rounded-[6px] px-1 py-2"
                                                                        />
                                                                    </td>
                                                                    <td className={`setting-table-cell fs_14 fw_400 text-secondary text-center`}>
                                                                        <Field
                                                                            name={`newLine.series_separator`}
                                                                            placeholder="Series separator"
                                                                            onChange={(e) => handleFieldChange("newLine", "series_separator", e.target.value, setFieldValue)}
                                                                            className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded px-1 py-2 w-full"
                                                                        />
                                                                    </td>
                                                                    <td className={`setting-table-cell fs_14 fw_400 text-secondary text-center`}>
                                                                        <Field
                                                                            name={`newLine.series_leading_spaces`}
                                                                            placeholder="Series space"
                                                                            type="number"
                                                                            onChange={(e) => handleFieldChange("newLine", "series_leading_spaces", e.target.value, setFieldValue)}
                                                                            className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded px-1 py-2 w-full"
                                                                        />
                                                                    </td>
                                                                    <td className={`setting-table-cell fs_14 fw_400 text-secondary text-center`}>
                                                                        <Field
                                                                            name={`newLine.series_fyi_year`}
                                                                            placeholder="Series Fyi Year"
                                                                            type=""
                                                                            onChange={(e) => handleFieldChange("newLine", "series_fyi_year", e.target.value, setFieldValue)}
                                                                            className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded px-1 py-2 w-full"
                                                                        />
                                                                    </td>
                                                                    <td className={`setting-table-cell fs_14 fw_400 text-secondary text-center`}>
                                                                        <Field
                                                                            name={`newLine.series_default`}
                                                                            type={'checkbox'}
                                                                            // checked={true}
                                                                            // onChange={(e) => handleFieldChange("newLine", "series_default", e.target.checked, setFieldValue)}
                                                                            className="border-[1px] focus:outline-none h-[18px] w-[18px] rounded-[6px] px-1 py-2"
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="px-6 flex mt-8 justify-between py-5 invoice-bottom-container">
                                                    <DraftButton
                                                        onclkFn={() => { setIsModalOpen(false); resetForm() }}
                                                        text="Cancel"
                                                    />
                                                    <AddButton
                                                        isDisabled={false}
                                                        onclkFn={handleSubmit}
                                                        type="submit"
                                                        className="bg-primary text-white px-8 py-2 rounded flex justify-center fw_500 fs_16"
                                                        text="Save"
                                                        prefixIcon={false}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    );
                                }}
                            </Formik>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
};

export default DocumentNumberingEditModal;
