import { Field } from "formik";
import React from "react";
import AsyncSelect from "react-select/async";
import StarIcon from "../../assets/icons/req-star";
import ErrorMsg from "./error-msg";
import { useDispatch } from "react-redux";

const SearchAddFeild = ({
  placeholder = "Type to search...",
  fetchData,
  name,
  defaultSelectClass = "selectField_border",
  label = "Phone Number",
  isLabelReq = true,
  field = "",
  value = { label: "" },
  onChange = () => {},
  isRequired = true,
  errorClass,
  isDisabled = false,
  searchRef,
  values,
  minWidth = "10rem",
  onFocus = () => {},
  dropdown = false,
}) => {
  const dispatch = useDispatch() 
  const customStyles = {
    indicatorSeparator: () => ({ display: "none" }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: "9999", // Set a higher value than the parent z-index
    }),
    control: (base, state) => ({
      ...base,
      margin: 0,

      color: "var(--secondary)",
      fontFamily: "Poppins,sans-serif",
      fontWeight: "400",
      fontSize: "15px",
      borderRadius: "10px",
      width: "100%",
      backgroundColor: state.isDisabled ? "inherit" : base.backgroundColor,
      // ...(border
      //   ? {borderColor: state.isFocused ? "transparent" : "F51D00" }
      //   : { borderColor: state.isFocused ? "transparent" : "transparent" }),
      borderColor: state.isFocused ? "transparent" : "transparent",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "#1D72DB" : "#F4F8FB",
      },
    }),
    option: (provided, state) => {
      return {
        ...provided,
        ...(state.data.color
          ? { color: state.data.color }
          : { color: "black" }),
        backgroundColor: state.isSelected
          ? "#e5ecffb2"
          : state.isFocused
          ? "#0000002E"
          : null,
        borderBottom: "1px solid #F5F5F5",
        fontFamily: "Inter, sans-serif",
        "&:hover": {
          backgroundColor: state.isFocused ? "#0000002E" : "#0000002E",
        },
        zIndex: "999",
      };
    },

    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#667085",
        fontFamily: "Inter,sans-serif",
        fontSize: "12px",
        fontWeight: "400",
        opacity: "1",
        position: "absolute",
        whiteSpace: "wrap",
      };
    },
    multiValue: (base) => ({
      ...base,
      backgroundColor: "#E5ECFF",
      // gap: "5px",
      // padding: "0px 6px",
    }),
    singleValue: (base, state) => ({
      ...base,
      fontWeight: "500",
      overflow: "visible",
      width: "100%",
      color: state.isDisabled ? "inherit" : "#464646",
    }),
    dropdownIndicator: () => ({ display: dropdown ? "block" : "none" }),
    loadingIndicator: (base) => ({
      display: "none", // Hide the loading indicator
    }),
  };

  const handleKeyDown = (event) => {
    const validKey = /^[a-zA-Z0-9\s]+$/;
    if (!validKey.test(event.key) && event.key !== "Backspace") {
      event.preventDefault();
    }
  };

  return (
    <>
      <Field name={name}>
        {({ meta }) => {
          return (
            <>
              <div className="flex items-center w-full ">
                {isLabelReq && (
                  <label
                    style={{
                      minWidth: minWidth,
                    }}
                    className="whitespace-no-wrap flex fs_14"
                  >
                    {label}
                    <StarIcon />
                  </label>
                )}
                <AsyncSelect
                  // onKeyDown={(event) => {
                  //   if (event.key === "Enter") {
                  //     event.preventDefault();
                  //     event.stopPropagation();
                  //   }
                  // }}
                  onFocus={onFocus}
                  menuPlacement="auto"
                  ref={searchRef}
                  isDisabled={isDisabled}
                  menuPortalTarget={document.body}
                  // {...(value?.label ? { value: value } : {})}
                  value={value}
                  className={`${defaultSelectClass}  selectField w-full h-full focus-within:ring-2 rounded focus-within:outline-none flex items-center focus-within:shadow-[0px_0px_0px_4px_#EBF2FF`}
                  styles={customStyles}
                  cacheOptions={false}
                  defaultOptions={false}
                  loadOptions={(inputValue, callback) => {
                    fetchData(inputValue, callback, field, values, dispatch);
                  }}
                  placeholder={placeholder}
                  loadingIndicator={false}
                  onChange={onChange}
                  onKeyDown={handleKeyDown} // Restrict special characters on keydown
                />
              </div>
              <div
                style={{
                  height: "1.5rem",
                }}
                className={`text-right w-full fs_12 ${errorClass}`}
              >
                {isRequired && <ErrorMsg name={name} />}
              </div>
            </>
          );
        }}
      </Field>
    </>
  );
};

export default SearchAddFeild;

export const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: "9999", // Set a higher value than the parent z-index
  }),
  control: (base, state) => ({
    ...base,
    margin: 0,

    color: "#0D111A",
    fontFamily: "Poppins,sans-serif",
    fontWeight: "400",
    fontSize: "15px",
    borderRadius: "10px",
    width: "100%",
    backgroundColor: state.isDisabled ? "inherit" : base.backgroundColor,
    borderColor: state.isFocused ? "transparent" : "transparent",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "#1D72DB" : "#F4F8FB",
    },
  }),
  option: (provided, state) => {
    return {
      ...provided,
      ...(state.data.color ? { color: state.data.color } : { color: "black" }),
      backgroundColor: state.isSelected
        ? "#e5ecffb2"
        : state.isFocused
        ? "#0000002E"
        : null,
      borderBottom: "1px solid #F5F5F5",
      fontFamily: "Inter, sans-serif",
      "&:hover": {
        backgroundColor: state.isFocused ? "#0000002E" : "#0000002E",
      },
      zIndex: "999",
    };
  },

  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#667085",
      fontFamily: "Inter,sans-serif",
      fontSize: "12px",
      fontWeight: "400",
      opacity: "1",
      position: "absolute",
      whiteSpace: "wrap",
    };
  },
  multiValue: (base) => ({
    ...base,
    backgroundColor: "#E5ECFF",
    // gap: "5px",
    // padding: "0px 6px",
  }),
  singleValue: (base, state) => ({
    ...base,
    fontWeight: "500",
    overflow: "visible",
    width: "100%",
    color: state.isDisabled ? "inherit" : "#464646",
  }),
  // dropdownIndicator: () => ({ display: "none" }),
  loadingIndicator: (base) => ({
    display: "none", // Hide the loading indicator
  }),
};
