import { Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import KibabMenu from "../../../../../assets/icons/kibab-menu";
import { useDispatch } from "react-redux";
import { addTaxTypeAction, deleteTaxTypeAction, taxTypeListAction, updateTaxTypeAction } from "../../../../../redux/actions/settings-action";
import DeleteIcon from "../../../../../assets/icons/delete-icon";
import successToast from "../../../../helper-components/success-toast";
import errorToast from "../../../../helper-components/error-toast";
import AddButton from "../../../../buttons/button";
import LoadingSpinner from "../../../../helper-components/loading-spinner";

const TaxTypeTable = ({ searchValue }) => {
    const tableHeading = ["Type Name", "Type Description", ""];
    const [disableArrayFields, setDisableArrayFields] = useState(false);
    const [disableNewFields, setDisableNewFields] = useState(false);
    const [selectedDOtIndex, setSelectedDOtIndex] = useState(null);
    const [initialData, setItemTypeData] = useState([]);
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch();

    const handleFieldChange = (index, field, value, setFieldValue) => {
        setFieldValue(`${index}.${field}`, value);
    };

    useEffect(() => {
        dispatch(taxTypeListAction({}, onFetchTaxTypeSuccess, onFetchTaxTypeError));
        setLoading(true)
    }, []);

    useEffect(() => {
        dispatch(taxTypeListAction({ searchValue }, onFetchTaxTypeSuccess, onFetchTaxTypeError));
        setLoading(true)
    }, [searchValue]);

    const reloadData = () => {
        dispatch(taxTypeListAction({}, onFetchTaxTypeSuccess, onFetchTaxTypeError));
        setLoading(true)
    }

    const onFetchTaxTypeSuccess = (data) => {
        setItemTypeData(data?.data?.taxTypeList);
        setLoading(false)
    }

    const onFetchTaxTypeError = (data) => {
        
        errorToast(data?.message);
        setItemTypeData([])
        setLoading(false)
    }

    const initialValues = {
        data: initialData,
        newLine: {
            name: "",
            description: "",
        },
    };

    const onSubmit = (values, { setSubmitting }) => {
        const changedObjects = [];
        let newLineObject = null

        // Collect changed array data
        initialData.forEach((item, index) => {
            if (JSON.stringify(values.data[index]) !== JSON.stringify(item)) {
                changedObjects.push(values.data[index]);
            }
        });

        // Collect new line data if it is altered
        if (JSON.stringify(values.newLine) !== JSON.stringify(initialValues.newLine)) {
            newLineObject = values.newLine
        }

        if (newLineObject !== null) {
            dispatch(addTaxTypeAction(newLineObject, onCreateSuccess, onCreateError))
        }

        if (changedObjects.length > 0) {
            dispatch(updateTaxTypeAction({ updatingData: changedObjects }, onUpdateSuccess, onUpdateError))
        }
        setSubmitting(false);
    }

    const onCreateSuccess = (data) => {
        reloadData();
        setDisableArrayFields(false);
        setSelectedDOtIndex(null)
        setDisableNewFields(false);
        successToast(data?.message);
    }
    const onCreateError = (data) => {
        
        setDisableArrayFields(false);
        setSelectedDOtIndex(null)
        setDisableNewFields(false);
        errorToast(data?.message);
    }

    const onUpdateSuccess = (data) => {
        setSelectedDOtIndex(null)
        reloadData();
        setDisableArrayFields(false);
        setDisableNewFields(false);
        successToast(data?.message);
    }
    const onUpdateError = (data) => {
        
        setSelectedDOtIndex(null)
        setDisableArrayFields(false);
        setDisableNewFields(false);
        errorToast(data?.message)
    }


    const onClickDelete = (id) => {
        dispatch(deleteTaxTypeAction({ id }, onDeletSuccess, onError))
    }
    const onDeletSuccess = (data) => {
        setSelectedDOtIndex(null)
        reloadData()
        successToast(data?.message);
    }

    const onError = (data) => {
        setSelectedDOtIndex(null)
        
        errorToast(data?.message)
    }

    const componentRef = useRef(null);

    const handleClickOutside = (event) => {
        if (componentRef.current && !componentRef.current.contains(event.target)) {
            setSelectedDOtIndex(null);
        }
    };

    return (
        <div>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={onSubmit}
            >
                {({ values, setFieldValue, dirty, handleSubmit }) => (
                    <Form>
                        <div
                            onClick={handleClickOutside}
                            className="flex flex-col justify-between"
                            style={{ height: "71vh" }}
                        >
                            {loading ? (
                                <LoadingSpinner />
                            ) : (
                                <div className="overflow-auto h-full">
                                    <table className="mt-1 w-full">
                                        <thead className="sticky top-0 z-10 bg-F2F4F5">
                                            <tr>
                                                {tableHeading.map((heading, index) => (
                                                    <th
                                                        className={`settings-table-header whitespace-nowrap fs_14 fw_500 text-475467 bg-F2F4F5 text-start`}
                                                        key={heading}
                                                    >
                                                        {heading}
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody style={{ position: "relative" }}>
                                            {values.data.map((item, index) => (
                                                <tr key={index}>
                                                    <td
                                                        className={`setting-table-cell fs_14 fw_400 text-secondary w-20`}
                                                    >
                                                        <Field
                                                            name={`data[${index}].name`}
                                                            placeholder="Name"
                                                            disabled={disableArrayFields}
                                                            onChange={(e) =>
                                                                handleFieldChange(
                                                                    `data[${index}]`,
                                                                    "name",
                                                                    e.target.value,
                                                                    setFieldValue
                                                                )
                                                            }
                                                            className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded py-2"
                                                        />
                                                    </td>
                                                    <td
                                                        className={`setting-table-cell fs_14 fw_400 text-secondary`}
                                                    >
                                                        <Field
                                                            name={`data[${index}].description`}
                                                            placeholder="Description"
                                                            disabled={disableArrayFields}
                                                            onChange={(e) =>
                                                                handleFieldChange(
                                                                    `data[${index}]`,
                                                                    "description",
                                                                    e.target.value,
                                                                    setFieldValue
                                                                )
                                                            }
                                                            className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded px-1 py-2"
                                                        />
                                                    </td>

                                                    <td className="setting-table-cell fs_14 fw_400 text-secondary p-8">
                                                        <button onClick={() => setSelectedDOtIndex(index)}>
                                                            <KibabMenu />
                                                        </button>
                                                        {selectedDOtIndex === index && (
                                                            <div
                                                                ref={componentRef}
                                                                style={{ position: "absolute", right: '4rem', boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                                                                className='bg-white rounded w-38'
                                                            >
                                                                <div className='w-full p-3'>
                                                                    <button className='w-full flex align-middle gap-2 px-2 py-1' onClick={() => onClickDelete(item?.id)}>
                                                                        <div className="mt-1">
                                                                            <DeleteIcon />
                                                                        </div>
                                                                        <span className='fs_16'>Delete</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td
                                                    className={`setting-table-cell fs_14 fw_400 text-secondary`}
                                                >
                                                    <Field
                                                        name="newLine.name"
                                                        placeholder="Name"
                                                        disabled={disableNewFields}
                                                        onChange={(e) =>
                                                            handleFieldChange(
                                                                "newLine",
                                                                "name",
                                                                e.target.value,
                                                                setFieldValue
                                                            )
                                                        }
                                                        className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded py-2"
                                                        autoFocus={searchValue ? false : true}
                                                    />
                                                </td>
                                                <td
                                                    className={`setting-table-cell fs_14 fw_400 text-secondary`}
                                                >
                                                    <Field
                                                        name="newLine.description"
                                                        placeholder="Description"
                                                        disabled={disableNewFields}
                                                        onChange={(e) =>
                                                            handleFieldChange(
                                                                "newLine",
                                                                "description",
                                                                e.target.value,
                                                                setFieldValue
                                                            )
                                                        }
                                                        className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded py-2"
                                                    />
                                                </td>

                                                <td
                                                    className={`setting-table-cell fs_14 fw_400 text-secondary`}
                                                ></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>)}
                        </div>
                        <div className="flex justify-end w-full bg-white py-4 px-6 invoice-bottom-container my-3">
                            <AddButton
                                onclkFn={handleSubmit}
                                prefixIcon={false}
                                text="Save"
                                type="submit"
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default TaxTypeTable;
