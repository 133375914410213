import moment from "moment";

export const conditionsOptions = [
  { label: "Less Than", value: "less_than" },
  { label: "Greater Than", value: "greater_than" },
  { label: "Equal To", value: "equal_to" },
  { label: "Not Equal To", value: "not_equal_to" },
  { label: "Like", value: "LIKE" },
  { label: "Range", value: "range" },
  { label: "IN", value: "IN" },
];

export const handleFilterRowChange = (idx, key, value, setFilters, filters) => {
  setFilters({
    ...filters,
    filters: filters?.filters.map((row, index) =>
      index === idx
        ? {
            ...row,
            ...(key === "column"
              ? {
                  condition: null,
                  value: "",
                  from: null,
                  to: null,
                }
              : key === "condition"
              ? {
                  value: value === "IN" ? [] : "", // Set to [] if IN, else ""
                  from: null,
                  to: null,
                }
              : {}),
            [key]: value,
          }
        : row
    ),
  });
};

export const transformData = (data, tableColoumsn = {}) => {
  const formatDate = (value) => {
    return moment(value).format("DD/MM/YYYY");
  };
  const isIso8601Date = (dateString) => {
    const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
    return iso8601Regex.test(dateString);
  };
  return data.map((item) => {
    let transformedItem = {};
    for (let [key, value] of Object.entries(tableColoumsn)) {
      const rawValue = item[value.split(".").pop()];
      transformedItem[key] = isIso8601Date(rawValue)
        ? formatDate(rawValue)
        : rawValue;
    }
    return transformedItem;
  });
};

export const handleAddFilterRow = (setFilters, filters) => {
  setFilters({
    ...filters,
    filters: [
      ...filters?.filters,
      { column: null, condition: null, value: "", from: null, to: null },
    ],
  });
};

export const handleRemoveFilterRow = (index, setFilters, filters) => {
  const updatedRows = filters.filters.filter((row, idx) => idx !== index);
  setFilters({
    ...filters,
    filters: updatedRows,
  });
};
