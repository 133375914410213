import axios from "axios";
import { url } from "../config";

export const api = axios.create({
  baseURL: url,
  timeout: 60000,
  headers: { "Content-Type": "application/json" },
});

api.interceptors.request.use(
  async (config) => {
    
    if (localStorage.getItem("accessToken")) {
      if (!excludeAccessToken(config)) {
        config.headers["Authorization"] = localStorage.getItem("accessToken");
      }
    }
    return config;
  },
  (error) => {
    
    return Promise.reject(error);
  }
);
api.interceptors.response.use(
  async (config) => {
    
    return config;
  },
  async (error) => {
    
    if (error.message === "Network Error") {
      
      return Promise.reject(error);
    } else if (
      error.response.data.message === "unable to find access-token" ||
      error.response.data.message === "invalid token" ||
      error.response.data.message ===
        "unable to find user with this access-token" ||
      error.response.data.message.includes("authentication failed")
    ) {
      localStorage.clear();
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

function excludeAccessToken(config) {
  return (
    config.url.includes("/api/user/login") ||
    config.url.includes("/core/auth/forgot-password") ||
    config.url.includes("/core/auth/reset-password") ||
    config.url.includes("/configuration/site-settings/logo-title")
  );
}
