import React, { Fragment } from "react";
import { NavigateButton } from "../buttons/button";
import LoginArrowLeft from "../../assets/icons/login-arrow-left";

export default function LoginNavigate({
  ContainerClass = "flex gap-4 mt-4 items-center justify-center",
  text = "Back to log in",
  onclkFn = () => {},
  isContainer = true,
  isExpanded = true,
}) {
  const Container = isContainer ? "div" : Fragment;
  return (
    <Container className={ContainerClass ?? {}}>
      <LoginArrowLeft isExpanded={isExpanded} />
      <NavigateButton text={text} onclkFn={onclkFn} />
    </Container>
  );
}
