import { Field } from "formik";
import React from "react";
import StarIcon from "../../assets/icons/req-star";
import ErrorMsg from "./error-msg";

export default function TextAreaField({
  isContainer = true,
  placeholder = "",
  defaultInputClass = "textAreaField focus_outline__none",
  id,
  name = "",
  label = "",
  containerClass = "mb-0 position-relative",
  errMsgContainerClass = "",
  labelClass = "flex fs_14 fw_400 mb-1 text-secondary",
  resize = "none",
  rows = "2",
  labelWidth = "10rem",
  isRequired = false,
  feildHeight="5rem",
  isDisabled=false,
  autoFocus=false
}) {
  const Container = isContainer ? "div" : React.Fragment;

  return (
    <Container
      {...(isContainer && containerClass ? { className: containerClass } : {})}
    >
      {" "}
      <Field name={name}>
        {({ field, meta }) => (
          <>
            <div className="flex m-0 p-0 w-full">
              {label && (
                <label
                  style={{
                    minWidth: labelWidth,
                  }}
                  {...(id ? { htmlFor: id } : {})}
                  className={`${labelClass} flex`}
                >
                  {label}
                  {isRequired && (
                    <span className="ml-2">
                      <StarIcon />
                    </span>
                  )}
                </label>
              )}{" "}
              <textarea
              autoFocus={autoFocus}
              disabled={isDisabled}
                rows={rows}
                style={{
                  resize: resize,
                  height: feildHeight
                }}
                {...(id ? { id: id } : {})}
                {...field}
                placeholder={placeholder}
                className={`${
                  meta.touched && meta.error
                    ? `${defaultInputClass} inputField_border__error w-full`
                    : `${defaultInputClass} inputField_border w-full`
                }`}
              ></textarea>
            </div>
          </>
        )}
      </Field>{" "}
      <ErrorMsg containerClass={errMsgContainerClass} name={name} />
    </Container>
  );
}
